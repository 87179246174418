import React from 'react';
import formatValue from '../../../../utils/formatNumber';
import classes from './styles.module.scss';

type PropsType = {
  balance: any;
  onMax?: any;
};

const BalanceWithButton = ({ balance, onMax }: PropsType) => {
  return (
    <div className={classes.balanceWrapper}>
      {onMax && (
        <button type="button" className={classes.button} onClick={onMax}>
          Max
        </button>
      )}
      {formatValue(balance?.split(' ')[1]) && (
        <div className={classes.balanceText}>Balance: {formatValue(balance?.split(' ')[1])}</div>
      )}
    </div>
  );
};

export default BalanceWithButton;
