import React from 'react';
import ContentHeading from 'components/contentHeading';
import classes from './styles.module.scss';

const TextBlock = () => {
  return (
    <div className={classes.wrapper}>
      <ContentHeading text="Mint your unique token on QI here" />
      <p className={classes.text}>
        Tokens are fully QIE20 compliant Non-Mintable Tokens. Use the QI token factory to mint your very own token at
        the click of a button.{' '}
      </p>
    </div>
  );
};

export default TextBlock;
