import React from 'react';
import YouTube from 'react-youtube';
import ModalWrapper from 'components/modalWrapper';
import classes from './styles.module.scss';

const ModalVideo = ({ state, setState }) => {
  const opts = {
    width: '390',
    height: '640',
    playerVars: {
      autoplay: 0,
    },
  };

  const onPlayerReady = (event) => {
    event.target.pauseVideo();
  };

  return (
    <ModalWrapper state={state} onClose={() => setState(false)} heading="Explainer Video">
      <div className={classes.videoWrapper}>
        <YouTube videoId="8BgK2CNyYsI" opts={opts} onReady={onPlayerReady} />
      </div>
    </ModalWrapper>
  );
};

export default ModalVideo;
