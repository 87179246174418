// @ts-nocheck

import { ContractFactory } from '@ethersproject/contracts';
import { ReactComponent as CoinIcon } from 'assets/images/coin-horizontal-icon.svg';
import BtnGradient from 'components/btnGradient';
import InputHeadingWrapper from 'components/inputHeadingWrapper';
import InputSubHeadingWrapper from 'components/inputSubHeadingWrapper';
import InputWithControls from 'components/inputWithControls';
import ModalWrapper from 'components/modalWrapper';
import FixedSupplyTokenABI from 'constants/abis/FixedSupplyToken/FixedSupplyToken.json';
import FixedSupplyTokenData from 'constants/abis/FixedSupplyToken/FixedSupplyTokenData';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import convertToInternationalCurrencySystem from 'utils/convertToInternationalCurrencySystem';
import toWeiDecimals from 'utils/toWeiDecimals';
import {
  decimalsValidatorCreator,
  nameValidatorCreator,
  supplyValidatorCreator,
  symbolValidatorCreator,
} from 'utils/validator';
import { useAccount, useChainId, useSwitchNetwork, useWalletClient } from 'wagmi';
import Web3 from 'web3';
import { ACTIVE_NETWORK, AdminOwner } from '../../../../../../constants';
import { useEthersSigner, useProvider } from '../../../../../../hooks/v2/ethers-adapters';
import InputTextWithHeading from '../../inputTextWithHeading';
import TotalNum from '../../totalNum';
import classes from './styles.module.scss';

const FixedContent = () => {
  // const tockenCreatorFee = user.feesList.tokenCreaterFee;
  const tockenCreatorFee = 0;
  const { address: account } = useAccount();
  const chainId = useChainId();
  const { switchNetwork } = useSwitchNetwork();

  const provider = useProvider();

  const [isSubmit, setIsSubmit] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [formData, setFormData] = useState({
    TokenType: 'QIE20',
    types: 'Fixed Supply',
    name: '',
    symbol: '',
    totalSupply: '',
    decimals: '',
  });
  const [modalState, setModalState] = useState(false);
  const [contractAddress, setContractAddress] = useState('');
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    trigger,
  } = useForm({
    mode: 'onChange',
  });

  const _onInputChange1 = (e) => {
    const cursor = e.target?.selectionStart;
    const parsedValue = e.target.value.replace(/,/g, '');

    if (/^\d*\.?\d*$/.test(parsedValue)) {
      const temp = { ...formData, [e.target.name]: parsedValue };
      setFormData(temp);
    }

    e.target.selectionStart = cursor;
  };

  const _onInputChange = (e) => {
    const temp = { ...formData, [e.target.name]: e.target.value };
    setFormData(temp);
  };

  const handleInputControlClick = (action, dataName, step) => {
    const temp = {
      ...formData,
      [dataName]: action === 'increase' ? Number(formData[dataName]) + step : Number(formData[dataName]) - step,
    };
    setValue(dataName, action === 'increase' ? Number(formData[dataName]) + step : Number(formData[dataName]) - step);
    trigger(dataName);
    setFormData(temp);
  };

  const closeModal = () => {
    setFormData({
      TokenType: 'QIE20',
      types: 'Fixed Supply',
      name: '',
      symbol: '',
      totalSupply: '',
      decimals: '',
    });
    setModalState(false);
  };

  const cb = (result) => {
    if (result.status && result.address) {
      setContractAddress(result.address);
      setModalState(true);
      setIsLoader(false);
      toast.success('Token created successfully');
    }
  };

  const uploadContractHandler = async () => {
    try {
      const web3 = (window.web3 = new Web3(provider));
      const nfttokenContract = new ContractFactory(
        FixedSupplyTokenABI,
        FixedSupplyTokenData,
        provider.getSigner(account)
      );
      const obj = {
        name: formData.name,
        symbol: formData.symbol,
        // decimals: formData.decimals.toString(),
        initialSupply: web3.utils.toWei(formData.totalSupply.toString()),
      };
      const totalSupplyWE = toWeiDecimals(formData.totalSupply.toString(), formData.decimals);
      console.log(totalSupplyWE, '-----totalSupply--------', formData.decimals, 'FGFG', formData.totalSupply);
      const tockenCreatorFeesS = web3.utils.toWei(tockenCreatorFee.toString());

      const contract = await nfttokenContract.deploy(
        formData.name,
        formData.symbol,
        formData.decimals.toString(),

        totalSupplyWE,
        AdminOwner,
        tockenCreatorFeesS,
        { gasLimit: web3.utils.toHex('4700000'), value: web3.utils.toHex(tockenCreatorFeesS) }
      );

      await contract.deployTransaction.wait();

      if (contract.address) {
        cb({
          status: true,
          address: contract.address,
        });
      }
    } catch (error) {
      setIsLoader(false);
    }
  };

  const submitHandler = async () => {
    if (ACTIVE_NETWORK === chainId) {
      setIsSubmit(true);
      try {
        if (
          formData.name !== '' &&
          formData.symbol !== '' &&
          formData.name.length <= 32 &&
          formData.symbol.length <= 32 &&
          formData.totalSupply !== '' &&
          Number(formData.totalSupply) > 0 &&
          formData.decimals !== '' &&
          Number(formData.decimals) > 1 &&
          Number(formData.decimals) < 19
        ) {
          setIsLoader(true);
          console.log('DATA', formData);
          setIsUpdating(true);
          await uploadContractHandler();
          setIsLoader(false);
        } else {
          setIsLoader(false);
        }
        setIsUpdating(false);
        setIsLoader(false);
      } catch (error) {
        setIsLoader(false);
        setIsUpdating(false);
        console.log('ERROR', error);
      }
    } else {
      switchNetwork?.(ACTIVE_NETWORK);
    }
  };

  console.log({ isValid });

  return (
    <div className={classes.wrapper}>
      <div className={classes.gridWrapper}>
        <InputTextWithHeading
          heading="Name"
          placeholder="enter a name"
          value={formData.name}
          errors={errors?.name?.message}
          register={{
            ...register('name', {
              validate: nameValidatorCreator,
              onChange: (e) => {
                setValue('name', e.target.value);
                _onInputChange(e);
              },
            }),
          }}
        />
        <InputTextWithHeading
          heading="Symbol"
          placeholder="enter a Symbol"
          value={formData.symbol}
          errors={errors?.symbol?.message}
          register={{
            ...register('symbol', {
              validate: symbolValidatorCreator,
              onChange: (e) => {
                setValue('symbol', e.target.value);
                _onInputChange(e);
              },
            }),
          }}
        />
        <InputHeadingWrapper name="Supply (tokens quantity)">
          <InputWithControls
            value={formData.totalSupply}
            controlHandler={handleInputControlClick}
            minValue={0}
            step={1000}
            errors={errors?.totalSupply?.message}
            name="totalSupply"
            register={{
              ...register('totalSupply', {
                validate: supplyValidatorCreator,
                onChange: (e) => {
                  setValue('totalSupply', e.target.value);
                  _onInputChange1(e);
                },
              }),
            }}
          />
        </InputHeadingWrapper>
        <InputHeadingWrapper name="Decimals">
          <InputWithControls
            value={formData.decimals}
            name="decimals"
            controlHandler={handleInputControlClick}
            minValue={0}
            step={1}
            errors={errors?.decimals?.message}
            register={{
              ...register('decimals', {
                validate: decimalsValidatorCreator,
                onChange: (e) => {
                  setValue('decimals', e.target.value);
                  _onInputChange1(e);
                },
              }),
            }}
          />
        </InputHeadingWrapper>
        <InputSubHeadingWrapper name="Created tokens will be sent to your address.">
          <InputTextWithHeading heading="Send to address" placeholder="enter a Address" value={account} isReadOnly />
        </InputSubHeadingWrapper>
      </div>
      <div className={classes.checkoutBlock}>
        <div className={classes.totalWrapper}>
          <TotalNum label="Token creation cost" value={tockenCreatorFee} measure="QIE" />
        </div>
        <div className={classes.btnWrapper}>
          <BtnGradient
            text={isUpdating ? 'Creating...' : 'Create Token'}
            Icon={CoinIcon}
            disabled={
              formData.totalSupply.toString().length > 21 ||
              isUpdating ||
              Number(formData.decimals) > 18 ||
              Number(formData.totalSupply) >= 1000000000000000000000 ||
              formData.symbol.length > 32 ||
              formData.name.length > 32 ||
              !isValid
            }
            func={submitHandler}
          />
        </div>
      </div>
      <ModalWrapper state={modalState} onClose={() => closeModal()} heading={`Token ${formData?.name}`}>
        <div className={classes.modalWrapper}>
          <div>
            <h3 className={classes.subheading}>
              {formData?.types} {formData?.TokenType}
            </h3>
          </div>
          <div className={classes.valueGrid}>
            <div className={classes.valueWrapper}>
              <h4 className={classes.valueHeading}>Name</h4>
              <p className={classes.value}>{formData?.name}</p>
            </div>
            <div className={classes.valueWrapper}>
              <h4 className={classes.valueHeading}>Symbol</h4>
              <p className={classes.value}>{formData?.symbol}</p>
            </div>
            <div className={classes.valueWrapper}>
              <h4 className={classes.valueHeading}>Decimals</h4>
              <p className={classes.value}>{formData?.decimals}</p>
            </div>
            <div className={classes.valueWrapper}>
              <h4 className={classes.valueHeading}>Total Supply</h4>
              <p className={classes.value}>{convertToInternationalCurrencySystem(formData?.totalSupply)}</p>
            </div>
          </div>
          <div className={classes.valueContractWrapper}>
            <h4 className={classes.valueHeading}>Contract Address</h4>
            <p className={classes.valueContract}>{contractAddress}</p>
          </div>
        </div>
      </ModalWrapper>
    </div>
  );
};

export default FixedContent;
