import React from 'react';
import InputHeadingWrapper from 'components/inputHeadingWrapper';
import InputSubHeadingWrapper from 'components/inputSubHeadingWrapper';
import InputWithControls from 'components/inputWithControls';
import InputNumWithHeading from 'components/ui/input/inputNumWithHeading';
import InputTextWithHeading from '../../../inputTextWithHeading';
import Heading from '../heading';
import BlockWrapper from '../blockWrapper';
import classes from './styles.module.scss';

const DetailsInput = ({
  inputNameValue,
  setInputNameValue,
  inputSymbolValue,
  setInputSymbolValue,
  inputDecimalsValue,
  handlerInputControlClick,
  inputNumberHandler,
  inputSupplyValue,
  inputInitialValue,
  inputNameRegister,
  inputNameErrors,
  inputSymbolRegister,
  inputSymbolErrors,
  inputDecimalsErrors,
  inputDecimalsRegister,
  inputSupplyErrors,
  inputSupplyRegister,
}) => {
  return (
    <BlockWrapper>
      <Heading text="Token Details" />
      <div className={classes.gridWrapper}>
        <InputSubHeadingWrapper name={inputNameErrors ? '' : 'Created tokens will be sent with this name.'}>
          <InputTextWithHeading
            heading="Name"
            placeholder="enter a name"
            value={inputNameValue}
            errors={inputNameErrors}
            register={inputNameRegister}
          />
        </InputSubHeadingWrapper>
        <InputSubHeadingWrapper name={inputSymbolErrors ? '' : 'Choose a symbol for your token (usually 3-5 chars).'}>
          <InputTextWithHeading
            heading="Symbol"
            value={inputSymbolValue}
            placeholder="enter a Symbol"
            errors={inputSymbolErrors}
            register={inputSymbolRegister}
          />
        </InputSubHeadingWrapper>
        <InputHeadingWrapper name="Decimals">
          <InputWithControls
            value={inputDecimalsValue}
            name="decimals"
            controlHandler={handlerInputControlClick}
            minValue={0}
            step={1}
            errors={inputDecimalsErrors}
            register={inputDecimalsRegister}
          />
        </InputHeadingWrapper>
        <InputHeadingWrapper name="Total Supply">
          <InputWithControls
            value={inputSupplyValue}
            name="totalSupply"
            controlHandler={handlerInputControlClick}
            minValue={0}
            step={1000}
            errors={inputSupplyErrors}
            register={inputSupplyRegister}
          />
        </InputHeadingWrapper>
        <div className={classes.inputValueWrapper}>
          <InputSubHeadingWrapper name="Initial supply will fixed as total supply (). Will be put in your account.">
            <InputNumWithHeading
              heading="Initial Supply"
              disabled
              value={inputInitialValue}
              name="initialSupply"
              setValue={(e) => inputNumberHandler(e)}
            />
          </InputSubHeadingWrapper>
        </div>
      </div>
    </BlockWrapper>
  );
};

export default DetailsInput;
