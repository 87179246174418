import React from 'react';
import BigNumber from 'bignumber.js';
import formatValue from 'utils/formatNumber';
import { ReactComponent as QiIcon } from 'assets/images/logo-swap-info.svg';
import classes from './styles.module.scss';

const TokenCard = ({
  tokenName,
  tokenDescription,
  releaseTime,
  issuePrice,
  circulationValue,
  supplyValue,
  website,
  whitepaper,
  text,
  decimal,
  status,
}) => {
  return (
    <div className={classes.wrapper}>
      <div className={classes.headingBlock}>
        <div className={classes.headingWrapper}>
          <h4 className={classes.heading}>{tokenName}</h4>
          <h5 className={classes.description}>{tokenDescription}</h5>
        </div>
        {Number(status) === 3 && <div className={classes.badge}>SCAM</div>}
      </div>
      {releaseTime && (
        <div className={classes.valueWrapper}>
          <h6 className={classes.valueHeading}>Release Time</h6>
          <p className={classes.value}>{releaseTime}</p>
        </div>
      )}
      {issuePrice && (
        <div className={classes.valueWrapper}>
          <h6 className={classes.valueHeading}>Issue Price</h6>
          <p className={classes.value}>{issuePrice}</p>
        </div>
      )}
      {circulationValue && (
        <div className={classes.valueWrapper}>
          <h6 className={classes.valueHeading}>Circulation</h6>
          <p className={classes.value}>{circulationValue}</p>
        </div>
      )}
      {supplyValue && (
        <div className={classes.valueWrapper}>
          <h6 className={classes.valueHeading}>Total Supply</h6>
          <p className={classes.value}>{formatValue(BigNumber(supplyValue).shiftedBy(-decimal))}</p>
        </div>
      )}
      {website && (
        <div className={classes.valueWrapper}>
          <h6 className={classes.valueHeading}>Website</h6>
          <a href={website} target="_blank" rel="noreferrer" className={classes.value}>
            {website}
          </a>
        </div>
      )}
      {whitepaper && (
        <div className={classes.valueWrapper}>
          <h6 className={classes.valueHeading}>Whitepaper</h6>
          <a href={website} target="_blank" rel="noreferrer" className={classes.value}>
            {whitepaper}
          </a>
        </div>
      )}
      {text && <p className={classes.text}>{text}</p>}
    </div>
  );
};

export default TokenCard;
