import React from 'react';
import { useHistory } from 'react-router-dom';
import ButtonBorderGradient from 'components/ui/button/buttonBorderGradient';
import BtnGradient from 'components/btnGradient';
import rocks from 'assets/images/home-rocks.png';
import classes from './styles.module.scss';
import ROUTES from '../../../constants/routes';

const MainBlock = ({ setModalState }) => {
  const history = useHistory();

  const handleClick = () => history.push(ROUTES.TRADE);

  return (
    <div className={classes.wrapper}>
      <div className={classes.headingBlock}>
        <div className={classes.textWrapper}>
          <h2 className={classes.heading}>Stake. Trade. Create.</h2>
          <p className={classes.text}>
            First decentralized exchange on{' '}
            <a href="https://qiblockchain.online" target="_blank" rel="nofollow noreferrer">
              QI Blockchain
            </a>{' '}
            network.{' '}
          </p>
        </div>
        <img className={classes.img} src={rocks} alt="floating rocks" />
      </div>
      <div className={classes.btnBlock}>
        <div className={classes.btnWrapper}>
          <ButtonBorderGradient text="Explainer Video" setState={() => setModalState(true)} />
        </div>
        <div className={classes.btnWrapper}>
          <BtnGradient text="Launch App" func={handleClick} />
        </div>
      </div>
    </div>
  );
};

export default MainBlock;
