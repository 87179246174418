import Modal from 'react-modal';
import PageNav from 'components/pageNav';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { setActiveIndex } from 'state/tokenCreator/slice';
import ROUTES from '../../../../constants/routes';
import BaseContent from './baseContent';
import ReflectionContent from './reflectionContent';
import classes from './styles.module.scss';

type StateType = {
  tokenCreator: {
    activeIndex: number;
  };
};

const TokenCreation = () => {
  const activeIndex = useSelector((state: StateType) => state.tokenCreator.activeIndex);

  return (
    <div className={classes.wrapper}>
      <PageNav
        tabs={['base qie 20', 'reflection']}
        activeIndex={activeIndex}
        setState={setActiveIndex}
        route={ROUTES.TOKEN_CREATOR}
      />
      <div className={classes.inputWrapper}>
        {!activeIndex ? <BaseContent /> : <></>}
        {activeIndex ? <ReflectionContent /> : <></>}
        {/* <div className={classes.btnWrapper}> */}
        {/*     <BtnGradient text='Create token' Icon={CoinIcon} /> */}
        {/* </div> */}
      </div>
    </div>
  );
};

export default TokenCreation;
