import React, { useState } from 'react';
import classNames from 'classnames';
import { ReactComponent as CaretRight } from 'assets/images/caret-right-icon.svg';
import { ReactComponent as CaretDown } from 'assets/images/caret-down-icon.svg';
import classes from '../styles.module.scss';

const AccordionPlatformGenerator = () => {
  const [isActive, setIsActive] = useState(false);

  const handleToggleVisibility = () => setIsActive(!isActive);

  const buttonClasses = () =>
    classNames({
      [classes.button]: true,
      [classes.buttonActive]: isActive,
    });

  const textClasses = () =>
    classNames({
      [classes.content]: true,
      [classes.contentActive]: isActive,
    });

  const breakLineClasses = () =>
    classNames({
      [classes.breakLine]: true,
      [classes.breakLineActive]: isActive,
    });

  return (
    <div>
      <button type="button" className={buttonClasses()} onClick={handleToggleVisibility}>
        <div className={classes.headingWrapper}>Benefits of Token Generator Platforms</div>
        <div>{isActive ? <CaretDown /> : <CaretRight />}</div>
      </button>
      <p className={textClasses()}>The general advantages of using the Token Generator Platform is mention below:</p>
      <br className={breakLineClasses()} />
      <p className={textClasses()}>- Instant Token Creation</p>
      <br className={breakLineClasses()} />
      <p className={textClasses()}>- No need of Coders</p>
      <br className={breakLineClasses()} />
      <p className={textClasses()}>- Doesn&apos;t not demand well-depth cryptocurrency knowledge</p>
      <br className={breakLineClasses()} />
      <p className={textClasses()}>- After creation, token can be used almost immediately</p>
      <br className={breakLineClasses()} />
      <p className={textClasses()}>- Affordable – (FREE)</p>
      <br className={breakLineClasses()} />
      <p className={textClasses()}>- Time Effective</p>
    </div>
  );
};

export default AccordionPlatformGenerator;
