import React from 'react';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import useGetScreenWidth from 'hooks/useGetScreenWidth';
import { ReactComponent as CloseIcon } from 'assets/images/closeIcon.svg';
import classes from './styles.module.scss';

const ModalSlideWrapper = ({ state, setState, heading, children }) => {
  const screenWidth = useGetScreenWidth();

  const setDirection = () => (screenWidth > 768 ? 'right' : 'bottom');

  return (
    <SlidingPane
      from={setDirection()}
      className="modal-wrapper"
      overlayClassName="overlay-side"
      isOpen={state}
      onRequestClose={setState}
      hideHeader
    >
      <div className={classes.wrapper}>
        <div className={classes.headingWrapper}>
          <h3 className={classes.heading}>{heading}</h3>
          <button type="button" onClick={setState}>
            <CloseIcon />
          </button>
        </div>
        {children}
      </div>
    </SlidingPane>
  );
};

export default ModalSlideWrapper;
