// @ts-nocheck

import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ETHER, TokenAmount } from '@pancakeswap-libs/sdk';
import { TransactionResponse } from '@ethersproject/providers';
import { BigNumber } from '@ethersproject/bignumber';
import BtnGradient from 'components/btnGradient';
import HeadingWithTooltip from 'components/headingWithTooltip';
import { useCurrency } from 'hooks/Tokens';
import { useStakingContract } from 'hooks/useContract';
import { useIsExpertMode, useUserDeadline, useUserSlippageTolerance } from 'state/user/hooks';
import { useDerivedMintInfo, useMintActionHandlers, useMintState } from 'state/mint/hooks';
import { Field } from 'state/mint/actions';
import { useTransactionAdder } from 'state/transactions/hooks';
import { maxAmountSpend } from 'utils/maxAmountSpend';
import { wrappedCurrency } from 'utils/wrappedCurrency';
import { ApprovalState, useApproveCallback } from 'hooks/useApproveCallback';
import { useAccount, useChainId } from 'wagmi';
import { useEthersSigner } from '../../../../../hooks/v2/ethers-adapters';
import { getRouterContractV2 } from '../../../../../utils/v2/getContracts';
import ContentCenterWrapper from '../../contentCenterWrapper';
import InputBlock from '../../inputBlock';
import { useActiveWeb3React } from '../../../../../hooks';
import { ROUTER_ADDRESS } from '../../../../../constants';
import { calculateGasMargin, calculateSlippageAmount, getEtherscanLink, getRouterContract } from '../../../../../utils';
import Heading from './heading';
import PoolBlock from './poolBlock';
import classes from './styles.module.scss';
import { PairState } from '../../../../../data/Reserves';
import ModalWrapper from '../../../../../components/modalWrapper';
import { useCurrencyBalance } from '../../../../../state/wallet/hooks';
import formatValue from '../../../../../utils/formatNumber';

type StateType = {
  exchange: {
    isCurrencyChosen: boolean;
  };
};

const ContentLoggedIn = ({ setIsActive }) => {
  const [errorInput, setErrorInput] = useState('');
  const [errorOutput, setErrorOutput] = useState('');
  const isCurrencyChosen = useSelector((state: StateType) => state.exchange.isCurrencyChosen);
  const [currencyIdA, setCurrencyIdA] = useState();
  const [currencyIdB, setCurrencyIdB] = useState();
  const [dealOrder, setDealOrder] = useState([]);
  const { address: account } = useAccount();
  const chainId = useChainId();
  const signer = useEthersSigner();
  const currencyA = useCurrency(
    currencyIdA?.address ? currencyIdA.address : currencyIdA?.name === 'QIE' ? 'ETH' : undefined
  );
  const currencyB = useCurrency(
    currencyIdB?.address ? currencyIdB.address : currencyIdB?.name === 'QIE' ? 'ETH' : undefined
  );
  const stakingContract = useStakingContract(ROUTER_ADDRESS, false);
  const [stakingAmount, setStakingAmount] = useState();
  const [modalState, setModalState] = useState(false);

  useEffect(() => {
    const getData = async () => {
      if (account && stakingContract) {
        const stakeAmount = await stakingContract.canAddLiquidity(account);
        // const typedValueParsed:any = stakeAmount;
        setStakingAmount(stakeAmount);
        console.log('stakingContract', stakeAmount);
      }
    };
    getData();
  }, [account, stakingContract]);

  const expertMode = useIsExpertMode();

  // mint state
  const { independentField, typedValue, otherTypedValue } = useMintState();
  const {
    dependentField,
    currencies,
    pair,
    pairState,
    currencyBalances,
    parsedAmounts,
    price,
    noLiquidity,
    liquidityMinted,
    poolTokenPercentage,
    error,
  } = useDerivedMintInfo(currencyA ?? undefined, currencyB ?? undefined);
  const { onFieldAInput, onFieldBInput } = useMintActionHandlers(noLiquidity);

  const isValid = !error;

  // modal and loading
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false); // clicked confirm

  // txn values
  const [deadline] = useUserDeadline(); // custom from users settings
  const [allowedSlippage] = useUserSlippageTolerance(); // custom from users
  const [txHash, setTxHash] = useState<string>('');

  // get formatted amounts
  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: noLiquidity ? otherTypedValue : parsedAmounts[dependentField]?.toSignificant(6) ?? '',
  };

  // get the max amounts user can add
  const maxAmounts: { [field in Field]?: TokenAmount } = [Field.CURRENCY_A, Field.CURRENCY_B].reduce(
    (accumulator, field) => {
      return {
        ...accumulator,
        [field]: maxAmountSpend(currencyBalances[field]),
      };
    },
    {}
  );

  const atMaxAmounts: { [field in Field]?: TokenAmount } = [Field.CURRENCY_A, Field.CURRENCY_B].reduce(
    (accumulator, field) => {
      return {
        ...accumulator,
        [field]: maxAmounts[field]?.equalTo(parsedAmounts[field] ?? '0'),
      };
    },
    {}
  );

  // check whether the user has approved the router on the tokens
  const [approvalA, approveACallback] = useApproveCallback(parsedAmounts[Field.CURRENCY_A], ROUTER_ADDRESS);
  const [approvalB, approveBCallback] = useApproveCallback(parsedAmounts[Field.CURRENCY_B], ROUTER_ADDRESS);

  const addTransaction = useTransactionAdder();

  async function onAdd() {
    if (!chainId || !signer || !account) return;
    const router = getRouterContractV2(chainId, signer);

    const { [Field.CURRENCY_A]: parsedAmountA, [Field.CURRENCY_B]: parsedAmountB } = parsedAmounts;
    if (!parsedAmountA || !parsedAmountB || !currencyA || !currencyB) {
      return;
    }

    const amountsMin = {
      [Field.CURRENCY_A]: calculateSlippageAmount(parsedAmountA, noLiquidity ? 0 : allowedSlippage)[0],
      [Field.CURRENCY_B]: calculateSlippageAmount(parsedAmountB, noLiquidity ? 0 : allowedSlippage)[0],
    };

    const deadlineFromNow = Math.ceil(Date.now() / 1000) + deadline;

    let estimate;
    let method: (...args: any) => Promise<TransactionResponse>;
    let args: Array<string | string[] | number>;
    let value: BigNumber | null;
    if (currencyA === ETHER || currencyB === ETHER) {
      const tokenBIsETH = currencyB === ETHER;
      estimate = router.estimateGas.addLiquidityETH;
      method = router.addLiquidityETH;
      args = [
        wrappedCurrency(tokenBIsETH ? currencyA : currencyB, chainId)?.address ?? '', // token
        (tokenBIsETH ? parsedAmountA : parsedAmountB).raw.toString(), // token desired
        amountsMin[tokenBIsETH ? Field.CURRENCY_A : Field.CURRENCY_B].toString(), // token min
        amountsMin[tokenBIsETH ? Field.CURRENCY_B : Field.CURRENCY_A].toString(), // eth min
        account,
        deadlineFromNow,
      ];
      value = BigNumber.from((tokenBIsETH ? parsedAmountB : parsedAmountA).raw.toString());
    } else {
      estimate = router.estimateGas.addLiquidity;
      method = router.addLiquidity;
      args = [
        wrappedCurrency(currencyA, chainId)?.address ?? '',
        wrappedCurrency(currencyB, chainId)?.address ?? '',
        parsedAmountA.raw.toString(),
        parsedAmountB.raw.toString(),
        amountsMin[Field.CURRENCY_A].toString(),
        amountsMin[Field.CURRENCY_B].toString(),
        account,
        deadlineFromNow,
      ];
      value = null;
    }

    setAttemptingTxn(true);
    // const aa = await estimate(...args, value ? { value } : {})
    await estimate(...args, value ? { value } : {})
      .then((estimatedGasLimit) =>
        method(...args, {
          ...(value ? { value } : {}),
          gasLimit: calculateGasMargin(estimatedGasLimit),
        }).then((response) => {
          setAttemptingTxn(false);

          addTransaction(response, {
            summary: `Add ${parsedAmounts[Field.CURRENCY_A]?.toSignificant(3)} ${
              currencies[Field.CURRENCY_A]?.symbol
            } and ${parsedAmounts[Field.CURRENCY_B]?.toSignificant(3)} ${currencies[Field.CURRENCY_B]?.symbol}`,
          });

          setTxHash(response.hash);
          setModalState(true);
        })
      )
      .catch((e) => {
        setAttemptingTxn(false);
        // we only care if the error is something _other_ than the user rejected the tx
        if (e?.code !== 4001) {
          console.error(e);
        }
      });
  }

  const pendingText = `Supplying ${parsedAmounts[Field.CURRENCY_A]?.toSignificant(6)} ${
    currencies[Field.CURRENCY_A]?.symbol
  } and ${parsedAmounts[Field.CURRENCY_B]?.toSignificant(6)} ${currencies[Field.CURRENCY_B]?.symbol}`;

  const handleCurrencyASelect = useCallback(
    (curr) => {
      if (curr === currencyIdB) {
        setCurrencyIdB('');
      }
      setCurrencyIdA(curr);
    },
    [currencyIdB]
  );

  const handleCurrencyBSelect = useCallback(
    (curr) => {
      if (curr === currencyIdA) {
        setCurrencyIdA('');
      }
      setCurrencyIdB(curr);
    },
    [currencyIdA]
  );

  const handleDismissConfirmation = useCallback(() => {
    setShowConfirm(false);
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onFieldAInput('');
    }
    setTxHash('');
  }, [onFieldAInput, txHash]);

  const closeModal = () => {
    handleDismissConfirmation();
    setModalState(false);
  };

  const selectedCurrencyBalanceInput = useCurrencyBalance(
    account ?? undefined,
    currencies[Field.CURRENCY_A] ?? undefined
  );
  const selectedCurrencyBalanceOutput = useCurrencyBalance(
    account ?? undefined,
    currencies[Field.CURRENCY_B] ?? undefined
  );

  useEffect(() => {
    if (Number(formattedAmounts[Field.CURRENCY_A]) > Number(selectedCurrencyBalanceInput?.toSignificant(6))) {
      setErrorOutput('Enter correct amount');
    } else {
      setErrorOutput('');
    }
  }, [formattedAmounts[Field.CURRENCY_A]]); // eslint-disable-line

  return (
    <ContentCenterWrapper>
      <Heading setIsActive={setIsActive} />
      <div className={classes.wrapper}>
        <InputBlock
          inputValue={formattedAmounts[Field.CURRENCY_A]}
          inputHandler={(e) => {
            if (e?.target) {
              const cursor = e.target?.selectionStart;
              const parsedValue = e.target.value.replace(/,/g, '');

              if (/^\d*\.?\d*$/.test(parsedValue)) {
                onFieldAInput(parsedValue);
              }

              if (selectedCurrencyBalanceInput) {
                if (Number(parsedValue) === 0) {
                  setErrorInput('Enter correct amount');
                } else if (parsedValue > selectedCurrencyBalanceInput?.toSignificant(6)) {
                  setErrorInput('Enter correct amount');
                } else {
                  setErrorInput('');
                }
              }

              e.target.selectionStart = cursor;
            } else {
              onFieldAInput(e);
            }
          }}
          inputSelectHandler={handleCurrencyASelect}
          outputValue={formattedAmounts[Field.CURRENCY_B]}
          outputHandler={(e) => {
            if (e?.target) {
              const cursor = e.target?.selectionStart;
              const parsedValue = e.target.value.replace(/,/g, '');

              if (/^\d*\.?\d*$/.test(parsedValue)) {
                onFieldBInput(parsedValue);
              }

              if (selectedCurrencyBalanceInput) {
                if (parsedValue === 0) {
                  setErrorOutput('Enter correct amount');
                } else if (
                  Number(formattedAmounts[Field.CURRENCY_A]) > Number(selectedCurrencyBalanceInput?.toSignificant(6))
                ) {
                  setErrorOutput('Enter correct amount');
                } else {
                  setErrorOutput('');
                }
              }

              e.target.selectionStart = cursor;
            } else {
              onFieldBInput(e);
            }
          }}
          outputSelectHandler={handleCurrencyBSelect}
          selectInputValue={currencyIdA}
          selectOutputValue={currencyIdB}
          balanceInput={
            !!currencies[Field.CURRENCY_A] && selectedCurrencyBalanceInput
              ? `Balance: ${selectedCurrencyBalanceInput?.toSignificant(6)}`
              : ' -'
          }
          balanceOutput={
            !!currencies[Field.CURRENCY_B] && selectedCurrencyBalanceOutput
              ? `Balance: ${selectedCurrencyBalanceOutput?.toSignificant(6)}`
              : ' -'
          }
          handleMaxInput={() => {
            onFieldAInput(maxAmounts[Field.CURRENCY_A]?.toExact() ?? '');
          }}
          handleMaxOutput={() => {
            onFieldBInput(maxAmounts[Field.CURRENCY_B]?.toExact() ?? '');
          }}
          errorInput={errorInput}
          errorOutput={errorOutput}
        />
        {currencies[Field.CURRENCY_A] && currencies[Field.CURRENCY_B] && pairState !== PairState.INVALID && (
          <PoolBlock
            currencies={currencies}
            poolTokenPercentage={poolTokenPercentage}
            noLiquidity={noLiquidity}
            price={price}
          />
        )}
        <div className={classes.buttonBlock}>
          {isCurrencyChosen && (
            <div className={classes.toleranceWrapper}>
              <HeadingWithTooltip heading="Slippage Tolerance" tooltipText="Test tooltip text" />
              <p className={classes.value}>3%</p>
            </div>
          )}
          {(approvalA === ApprovalState.NOT_APPROVED ||
            approvalA === ApprovalState.PENDING ||
            approvalB === ApprovalState.NOT_APPROVED ||
            approvalB === ApprovalState.PENDING) &&
            isValid && (
              <>
                {approvalA !== ApprovalState.APPROVED && (
                  <div className={classes.buttonWrapper}>
                    <BtnGradient
                      text={
                        approvalA === ApprovalState.PENDING
                          ? `Approving ${currencies[Field.CURRENCY_A]?.symbol}`
                          : `Approve ${currencies[Field.CURRENCY_A]?.symbol}`
                      }
                      disabled={approvalA === ApprovalState.PENDING}
                      func={() => approveACallback()}
                    />
                  </div>
                )}
                {approvalB !== ApprovalState.APPROVED && (
                  <div className={classes.buttonWrapper}>
                    <BtnGradient
                      text={
                        approvalB === ApprovalState.PENDING
                          ? `Approving ${currencies[Field.CURRENCY_B]?.symbol}`
                          : `Approve ${currencies[Field.CURRENCY_B]?.symbol}`
                      }
                      disabled={approvalB === ApprovalState.PENDING}
                      func={() => approveBCallback()}
                    />
                  </div>
                )}
              </>
            )}
          <div className={classes.buttonWrapper}>
            <BtnGradient
              text={account ? error ?? 'Supply' : 'Unlock Wallet'}
              disabled={
                !account || !isValid || approvalA !== ApprovalState.APPROVED || approvalB !== ApprovalState.APPROVED
              }
              func={() => setModalState(true)}
            />
          </div>
        </div>
        {txHash ? (
          <></>
        ) : (
          // <ModalWrapper
          //     state={modalState}
          //     onClose={() => closeModal()}
          //     heading='Transaction submitted'
          // >
          //     <div className={classes.modalWrapper}>
          //         <a className={classes.modalLink} href={getEtherscanLink(chainId, txHash, 'transaction')} target='_blank' rel='noreferrer'>View on QIcscan</a>
          //     </div>
          // </ModalWrapper>
          <ModalWrapper
            state={modalState}
            onClose={() => closeModal()}
            heading={noLiquidity ? 'You are creating a pool' : 'You will receive'}
          >
            <div className={classes.modalWrapper}>
              <h3 className={classes.subheading}>{`${currencies[Field.CURRENCY_A]?.symbol}/${
                currencies[Field.CURRENCY_B]?.symbol
              }`}</h3>
              <div className={classes.valueWrapper}>
                <h4 className={classes.valueHeading}>{currencies[Field.CURRENCY_A]?.symbol} Deposited</h4>
                <p className={classes.value}>{formatValue(parsedAmounts[Field.CURRENCY_A]?.toSignificant(6))}</p>
              </div>
              <div className={classes.valueWrapper}>
                <h4 className={classes.valueHeading}>{currencies[Field.CURRENCY_B]?.symbol} Deposited</h4>
                <p className={classes.value}>{formatValue(parsedAmounts[Field.CURRENCY_B]?.toSignificant(6))}</p>
              </div>
              <div className={classes.valueWrapper}>
                <h4 className={classes.valueHeading}>Rates</h4>
                <div>
                  <p className={classes.value}>{`1 ${currencies[Field.CURRENCY_A]?.symbol} = ${formatValue(
                    price?.toSignificant(4)
                  )} ${currencies[Field.CURRENCY_B]?.symbol}`}</p>
                  <p className={classes.value}>{`1 ${currencies[Field.CURRENCY_B]?.symbol} = ${formatValue(
                    price?.invert().toSignificant(4)
                  )} ${currencies[Field.CURRENCY_A]?.symbol}`}</p>
                </div>
              </div>
              <div className={classes.valueWrapper}>
                <h4 className={classes.valueHeading}>Share of Pool:</h4>
                <p className={classes.value}>{noLiquidity ? '100' : poolTokenPercentage?.toSignificant(4)}%</p>
              </div>
              <div className={classes.buttonWrapper}>
                <BtnGradient
                  text={noLiquidity ? 'Create Pool & Supply' : 'Confirm Supply'}
                  disabled={
                    !account || !isValid || approvalA !== ApprovalState.APPROVED || approvalB !== ApprovalState.APPROVED
                  }
                  func={() => {
                    onAdd();
                  }}
                />
              </div>
            </div>
          </ModalWrapper>
        )}
      </div>
    </ContentCenterWrapper>
  );
};

export default ContentLoggedIn;
