import React from 'react';
import { Field } from 'state/mint/actions';
import config from './config';
import PoolItem from './poolItem';
import { ONE_BIPS } from '../../../../../../constants';
import formatValue from '../../../../../../utils/formatNumber';
import classes from './styles.module.scss';

const PoolBlock = ({ currencies, noLiquidity, poolTokenPercentage, price }) => {
  return (
    <div className={classes.wrapper}>
      <h5 className={classes.heading}>Prices and pool share</h5>
      <div className={classes.poolValuesWrapper}>
        <PoolItem
          heading={`${currencies[Field.CURRENCY_B]?.symbol} per ${currencies[Field.CURRENCY_A]?.symbol}`}
          value={formatValue(price?.toSignificant(6)) ?? '-'}
        />
        <PoolItem
          heading={`${currencies[Field.CURRENCY_A]?.symbol} per ${currencies[Field.CURRENCY_B]?.symbol}`}
          value={formatValue(price?.invert()?.toSignificant(6)) ?? '-'}
        />
        <PoolItem
          heading="Share of Poll"
          value={`${
            noLiquidity && price
              ? '100'
              : poolTokenPercentage?.lessThan(ONE_BIPS)
              ? '<0.01'
              : poolTokenPercentage?.toFixed(2) ?? '0'
          } %`}
        />
      </div>
    </div>
  );
};

export default PoolBlock;
