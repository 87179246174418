import React from 'react';
import classes from './styles.module.scss';

type Props = {
  id?: any;
  name?: any;
  value?: any;
  checkStatus?: any;
  children?: any;
  checkBoxHandler?: any;
  disabled?: boolean;
};

const Checkbox = ({ id, name, value, checkStatus, children, checkBoxHandler, disabled }: Props) => {
  return (
    <label htmlFor={id} className={classes.wrapper}>
      <input
        id={id}
        className={classes.input}
        disabled={disabled}
        type="checkbox"
        checked={checkStatus}
        name={name}
        value={value}
        onChange={(e) => checkBoxHandler(e, name)}
      />
      {children}
    </label>
  );
};

export default Checkbox;
