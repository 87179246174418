import React from 'react';
import { ReactComponent as InfoIcon } from 'assets/images/info-icon.svg';
import classes from './styles.module.scss';

const ErrorField = ({ text }) => {
  return (
    <div className={classes.wrapper}>
      <InfoIcon />
      <div className={classes.text}>{text}</div>
    </div>
  );
};

export default ErrorField;
