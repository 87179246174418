import { ethers } from 'ethers';

const toWeiDecimals = (balanceOfWei, decimals) => {
  if (balanceOfWei) {
    const balanceOfFromWei = ethers.utils.parseUnits(balanceOfWei.toString(), decimals);

    return balanceOfFromWei.toString();
  }

  return balanceOfWei;
};

export default toWeiDecimals;
