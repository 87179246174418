// @ts-ignore
import React, { useEffect, useState } from 'react';
import axios from 'axios';
// @ts-ignore
import classes from './styles.module.scss';

const Content = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    // Fetch data from the external API
    axios
      .get('https://dex-api.qie.systems/api/swap')
      .then((response) => {
        setData(response.data.reverse());
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  return (
    <div className={classes.contentWrapper}>
      <table className={classes.table}>
        <thead>
          <tr>
            <th className={classes.column}>
              <div className={classes.columnHeadWrapper}>
                <div className={classes.columnHead}>From</div>
              </div>
            </th>
            <th className={classes.column}>
              <div className={classes.columnHeadWrapper}>
                <div className={classes.columnHead}>To</div>
              </div>
            </th>
            <th className={classes.column}>
              <div className={classes.columnHeadWrapper}>
                <div className={classes.columnHead}>Txid</div>
              </div>
            </th>
            <th className={classes.column}>
              <div className={classes.columnHeadWrapper}>
                <div className={classes.columnHead}>Time</div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, k) => (
            <tr>
              <td>
                <div>
                  <div className={classes.value}>
                    {item.AmountIn} {item.TokenIn}
                  </div>
                </div>
              </td>
              <td>
                <div>
                  <div className={classes.value}>
                    {item.AmountOut} {item.TokenOut}
                  </div>
                </div>
              </td>
              <td>
                <div>
                  <div className={classes.value}>{item.TxHash}</div>
                </div>
              </td>
              <td>
                <div>
                  <div className={classes.value}>{item.Time}</div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Content;
