import React from 'react';
import ContentHeading from 'components/contentHeading';
import classes from './styles.module.scss';

type Props = {
  heading: string;
  children: any;
};

const BlockWrapper = ({ heading, children }: Props) => {
  return (
    <div className={classes.wrapper}>
      <ContentHeading text={heading} />
      {children}
    </div>
  );
};

export default BlockWrapper;
