const ROUTES = {
  TRADE: '/swap',
  STAKE: '/stake',
  TOKEN_CREATOR: '/token-creator',
  HOME: '/',
  FAQ: '/faq',
  DISCLAIMER: '/disclaimer',
  TERMS: '/terms',
  POLICY: '/policy',
  LIQUIDITY_REMOVE: '/swap/liquidity-remove',
  ALL_SWAPS: '/swap/history',
  DOCS: 'https://document.qidex.site',
  QI_BLOCKCHAIN: 'https://qiblockchain.online/',
};

export default ROUTES;
