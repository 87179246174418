// @ts-nocheck

import { ContractFactory } from '@ethersproject/contracts';
import { ReactComponent as CoinIcon } from 'assets/images/coin-horizontal-icon.svg';
import BtnGradient from 'components/btnGradient';
import InputHeadingWrapper from 'components/inputHeadingWrapper';
import InputSubHeadingWrapper from 'components/inputSubHeadingWrapper';
import InputWithControls from 'components/inputWithControls';
import ModalWrapper from 'components/modalWrapper';
import InputNumWithHeading from 'components/ui/input/inputNumWithHeading';
import ReflectionTokenABI from 'constants/abis/ReflectionToken/ReflectionTokenABI.json';
import ReflectionTokenData from 'constants/abis/ReflectionToken/ReflectionTokenData';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import convertToInternationalCurrencySystem from 'utils/convertToInternationalCurrencySystem';
import { useSwitchNetwork } from 'wagmi';
import Web3 from 'web3';
import { ACTIVE_NETWORK, AdminOwner, explorerURL } from '../../../../../../constants';
import { useActiveWeb3React } from '../../../../../../hooks';
import { useEthersProvider, useProvider } from '../../../../../../hooks/v2/ethers-adapters';
import {
  nameValidatorCreator,
  percentValidatorCreator,
  supplyValidatorCreator,
  symbolValidatorCreator,
} from '../../../../../../utils/validator';
import InputTextWithHeading from '../../inputTextWithHeading';
import TotalNum from '../../totalNum';
import classes from './styles.module.scss';

const StandartContent = () => {
  const tockenCreatorFees = 0.000001;
  const { account, chainId } = useActiveWeb3React();
  const { switchNetwork } = useSwitchNetwork();
  const [isSubmit, setIsSubmit] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [formData, setFormData] = useState({
    TokenType: 'Reflection',
    types: 'Standard Token',
    name: '',
    symbol: '',
    decimals: 18,
    totalSupply: '',
    taxFee: '',
    liquidityFee: '',
    burnFee: '',
    marketingFee: '',
    maxTransfer: '',
    numberOfTokens: '',

    rewardTokens: '',
    marketingWallet: '',
    balanceForDividends: '',
    investmentToken: 'liquidityGeneratorToken',
  });
  const [modalState, setModalState] = useState(false);
  const [contractAddress, setContractAddress] = useState('');
  const provider = useProvider();
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    trigger,
  } = useForm({
    mode: 'onChange',
  });

  const _onInputChange = (e) => {
    console.log(e);
    const temp = { ...formData, [e.target.name]: e.target.value };
    setFormData(temp);
  };

  const _onInputChange1 = (e) => {
    const cursor = e.target?.selectionStart;
    const parsedValue = e.target.value.replace(/,/g, '');

    if (/^\d*\.?\d*$/.test(parsedValue)) {
      const temp = { ...formData, [e.target.name]: parsedValue };
      setFormData(temp);
    }

    e.target.selectionStart = cursor;
  };

  const handleInputControlClick = (action, dataName, step) => {
    const temp = {
      ...formData,
      [dataName]: action === 'increase' ? Number(formData[dataName]) + step : Number(formData[dataName]) - step,
    };
    setValue(dataName, action === 'increase' ? Number(formData[dataName]) + step : Number(formData[dataName]) - step);
    trigger(dataName);
    setFormData(temp);
  };

  const closeModal = () => {
    setFormData({
      TokenType: 'Reflection',
      types: 'Standard Token',
      name: '',
      symbol: '',
      decimals: 18,
      totalSupply: '',
      taxFee: '',
      liquidityFee: '',
      burnFee: '',
      marketingFee: '',
      maxTransfer: '',
      numberOfTokens: '',

      rewardTokens: '',
      marketingWallet: '',
      balanceForDividends: '',
      investmentToken: 'liquidityGeneratorToken',
    });
    setModalState(false);
  };

  const uploadContractHandler = async (
    accountValue,
    formDataValue,
    tockenCreatorFeeValue,
    gasLimitHexValue,
    cbValue
  ) => {
    try {
      const web3 = (window.web3 = new Web3(provider));

      const nfttokenContract = new ContractFactory(
        ReflectionTokenABI,
        ReflectionTokenData,
        provider.getSigner(account)
      );

      const totalSupplyS = web3.utils.toWei(formDataValue.totalSupply.toString());
      const TaxFees = Number(formDataValue.taxFee) * 10;
      const tockenCreatorFeeS = web3.utils.toWei(tockenCreatorFeeValue.toString());

      const contract = await nfttokenContract.deploy(
        formDataValue.name.toString(),
        formDataValue.symbol.toString(),
        totalSupplyS,
        TaxFees.toString(),
        AdminOwner,
        tockenCreatorFeeS,
        { gasLimit: gasLimitHexValue, value: web3.utils.toHex(tockenCreatorFeeS) }
      );

      await contract.deployTransaction.wait();

      if (contract.address) {
        cbValue({
          status: true,
          address: contract.address,
        });
      }
    } catch (error) {
      console.error(error);
      toast.error(error.message);
      cbValue({
        status: false,
        address: null,
      });
    }
  };

  const submitHandler = async () => {
    if (ACTIVE_NETWORK == chainId) {
      setIsSubmit(true);

      const block = await provider.getBlock('latest');

      const gasLimit = block.gasLimit;

      try {
        if (formData.investmentToken == 'liquidityGeneratorToken') {
          if (
            formData.totalSupply !== '' &&
            formData.taxFee !== '' &&
            Number(formData.totalSupply) > 0 &&
            Number(formData.taxFee) > 0 &&
            formData.name !== '' &&
            formData.symbol !== '' &&
            formData.decimals !== '' &&
            Number(formData.decimals) > 1 &&
            Number(formData.decimals) < 19
          ) {
            console.log('-----------346----------');
            setIsUpdating(true);
            await uploadContractHandler(account, formData, tockenCreatorFees, gasLimit, (result) => {
              if (result.status && result.address) {
                console.log(`${explorerURL}/address/${result.address}`);
                // window.open(`${explorerURL}/address/${result.address}`);
                // history.push({
                //     pathname: "/app/view-token",
                //     search: result.address,
                //     state: formData,
                // });
                // toast.success("Token created successfully");
                setContractAddress(result.address);
                setModalState(true);
                toast.success('Token created successfully');
              } else {
              }
            });
          }
        }

        setIsUpdating(false);
      } catch (error) {
        setIsUpdating(false);
        console.log('ERROR', error);
      }
    } else {
      switchNetwork?.(ACTIVE_NETWORK);
    }
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.gridWrapper}>
        <InputTextWithHeading
          heading="Name"
          placeholder="enter a name"
          value={formData.name}
          errors={errors?.name?.message}
          register={{
            ...register('name', {
              validate: nameValidatorCreator,
              onChange: (e) => {
                setValue('name', e.target.value);
                _onInputChange(e);
              },
            }),
          }}
        />
        <InputTextWithHeading
          heading="Symbol"
          placeholder="enter a Symbol"
          value={formData.symbol}
          errors={errors?.symbol?.message}
          register={{
            ...register('symbol', {
              validate: symbolValidatorCreator,
              onChange: (e) => {
                setValue('symbol', e.target.value);
                _onInputChange(e);
              },
            }),
          }}
        />
        <div className={classes.inputWrapper}>
          <div className={classes.input}>
            <InputSubHeadingWrapper name="">
              <InputNumWithHeading
                heading="Decimals"
                name="decimals"
                value={formData.decimals}
                disabled
                setValue={(e) => _onInputChange1(e)}
                step={1}
              />
            </InputSubHeadingWrapper>
          </div>
          <div className={classes.input}>
            <InputHeadingWrapper name="Tax fee (%)">
              <InputWithControls
                value={formData.taxFee}
                name="taxFee"
                controlHandler={handleInputControlClick}
                minValue={0}
                step={1}
                errors={errors?.taxFee?.message}
                register={{
                  ...register('taxFee', {
                    validate: percentValidatorCreator,
                    onChange: (e) => {
                      setValue('taxFee', e.target.value);
                      _onInputChange1(e);
                    },
                  }),
                }}
              />
            </InputHeadingWrapper>
          </div>
        </div>
        <div className={classes.decimalsWrapper}>
          <InputHeadingWrapper name="Total supply">
            <InputWithControls
              value={formData.totalSupply}
              name="totalSupply"
              controlHandler={handleInputControlClick}
              minValue={0}
              step={1000}
              errors={errors?.totalSupply?.message}
              register={{
                ...register('totalSupply', {
                  validate: supplyValidatorCreator,
                  onChange: (e) => {
                    setValue('totalSupply', e.target.value);
                    _onInputChange1(e);
                  },
                }),
              }}
            />
          </InputHeadingWrapper>
        </div>
      </div>
      <div className={classes.checkoutBlock}>
        <div className={classes.totalWrapper}>
          <TotalNum label="Token creation cost" value={tockenCreatorFees} measure="QIE" />
        </div>
        <div className={classes.btnWrapper}>
          <BtnGradient
            text={isUpdating ? 'Creating...' : 'Create Token'}
            Icon={CoinIcon}
            disabled={
              !account ||
              isUpdating ||
              Number(formData.taxFee) +
                Number(formData.liquidityFee) +
                Number(formData.burnFee) +
                Number(formData.marketingFee) >
                25 ||
              Number(formData.totalSupply) > 100000000000000000000 ||
              formData.symbol.length > 32 ||
              formData.name.length > 32
            }
            func={submitHandler}
          />
        </div>
      </div>
      <ModalWrapper state={modalState} onClose={() => closeModal()} heading={`Token ${formData?.name}`}>
        <div className={classes.modalWrapper}>
          <div>
            <h3 className={classes.subheading}>
              {formData?.types} {formData?.TokenType}
            </h3>
          </div>
          <div className={classes.valueGrid}>
            <div className={classes.valueWrapper}>
              <h4 className={classes.valueHeading}>Name</h4>
              <p className={classes.value}>{formData?.name}</p>
            </div>
            <div className={classes.valueWrapper}>
              <h4 className={classes.valueHeading}>Symbol</h4>
              <p className={classes.value}>{formData?.symbol}</p>
            </div>
            <div className={classes.valueWrapper}>
              <h4 className={classes.valueHeading}>Decimals</h4>
              <p className={classes.value}>{formData?.decimals}</p>
            </div>
            <div className={classes.valueWrapper}>
              <h4 className={classes.valueHeading}>Total Supply</h4>
              <p className={classes.value}>{convertToInternationalCurrencySystem(formData?.totalSupply)}</p>
            </div>
            <div className={classes.valueWrapper}>
              <h4 className={classes.valueHeading}>Tax fee</h4>
              <p className={classes.value}>{formData.taxFee} %</p>
            </div>
          </div>
          <div className={classes.valueContractWrapper}>
            <h4 className={classes.valueHeading}>Contract Address</h4>
            <p className={classes.valueContract}>{contractAddress}</p>
          </div>
        </div>
      </ModalWrapper>
    </div>
  );
};

export default StandartContent;
