import React from 'react';
import InputNum from '../../../../ui/input/inputNum';
import config from './config';
import InputOption from './inputOption';
import classes from './styles.module.scss';

const InputWithBuiltInValues = ({ value, setValue, handler, minValue, error }) => {
  return (
    <div className={classes.wrapper}>
      <InputNum value={value} setValue={handler} minValue={minValue} error={error} />
      {error ? (
        <p className={classes.error}>{error}</p>
      ) : (
        <div className={classes.optionsWrapper}>
          {config.map((elem) => (
            <InputOption key={elem.id} value={elem.value} setValue={setValue} />
          ))}
        </div>
      )}
    </div>
  );
};

export default InputWithBuiltInValues;
