import React, { useMemo } from 'react';
import { isTransactionRecent, useAllTransactions } from '../../../../../state/transactions/hooks';
import { TransactionDetails } from '../../../../../state/transactions/reducer';
import formatValue from '../../../../../utils/formatNumber';
import HistoryItem from './historyItem';
import classes from './styles.module.scss';

const HistoryList = () => {
  const allTransactions = useAllTransactions();

  const newTransactionsFirst = (a: TransactionDetails, b: TransactionDetails) => b.addedTime - a.addedTime;

  // Logic taken from Web3Status/index.tsx line 175
  const sortedRecentTransactions = useMemo(() => {
    const txs = Object.values(allTransactions);
    return txs.filter(isTransactionRecent).sort(newTransactionsFirst);
  }, [allTransactions]);

  return (
    <div className={classes.wrapper}>
      {sortedRecentTransactions.map((elem) => (
        <HistoryItem
          key={elem.hash}
          hash={elem.hash}
          from={elem?.summary?.split(' ')[2]}
          to={elem?.summary?.split(' ')[5]}
          amountFrom={formatValue(elem?.summary?.split(' ')[1])}
          amountTo={formatValue(elem?.summary?.split(' ')[4])}
          date={elem.addedTime}
        />
      ))}
    </div>
  );
};

export default HistoryList;
