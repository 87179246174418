import { createSlice } from '@reduxjs/toolkit';

const tokenCreatorSlice = createSlice({
  name: 'tokenCreator',
  initialState: {
    activeIndex: 0,
  },
  reducers: {
    setActiveIndex(state, action) {
      state.activeIndex = action.payload;
    },
  },
});

export default tokenCreatorSlice.reducer;
export const { setActiveIndex } = tokenCreatorSlice.actions;
