import React, { useState } from 'react';
import classNames from 'classnames';
import { ReactComponent as CaretRight } from 'assets/images/caret-right-icon.svg';
import { ReactComponent as CaretDown } from 'assets/images/caret-down-icon.svg';
import classes from '../styles.module.scss';

const AccordionMetamask = () => {
  const [isActive, setIsActive] = useState(false);

  const handleToggleVisibility = () => setIsActive(!isActive);

  const buttonClasses = () =>
    classNames({
      [classes.button]: true,
      [classes.buttonActive]: isActive,
    });

  const textClasses = () =>
    classNames({
      [classes.content]: true,
      [classes.contentActive]: isActive,
    });

  const breakLineClasses = () =>
    classNames({
      [classes.breakLine]: true,
      [classes.breakLineActive]: isActive,
    });

  return (
    <div>
      <button type="button" className={buttonClasses()} onClick={handleToggleVisibility}>
        <div className={classes.headingWrapper}>How to add QIDex token in my custom tokens in metamask?</div>
        <div>{isActive ? <CaretDown /> : <CaretRight />}</div>
      </button>
      <p className={textClasses()}>Copy the token address.</p>
      <br className={breakLineClasses()} />
      <p className={textClasses()}>
        Go to your account &gt;&gt; assets &gt;&gt; import tokens, and now paste the token address
        0x079Acabcc1fcd9E7211a0Bc294aee02EEfCe77e3 in the “Token contract address” field.
      </p>
      <br className={breakLineClasses()} />
      <p className={textClasses()}>
        It will fetch token symbol and token decimals on its own. Click on Add custom token &gt;&gt; Import token.
      </p>
    </div>
  );
};

export default AccordionMetamask;
