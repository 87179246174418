import React, { useEffect, useState } from 'react';
import { Direction, getTrackBackground, Range } from 'react-range';
import config from './config';
import classes from './styles.module.scss';

const RangeSlider = ({ rangeSliderValue, setRangeSliderValue }) => {
  const [values, setValues] = useState([0]);

  const convertNumber = (value) => Math.abs(value - 100);

  // useEffect(() => {
  //     const newValue = convertNumber(values.join());
  //     setRangeSliderValue(newValue);
  // }, [values]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const newValue = convertNumber(rangeSliderValue);
    setValues([newValue]);
  }, [rangeSliderValue]);

  return (
    <div className={classes.wrapper}>
      <Range
        direction={Direction.Left}
        values={[convertNumber(rangeSliderValue)]}
        step={config.step.value}
        min={config.step.min}
        max={config.step.max}
        onChange={(value) => setRangeSliderValue(convertNumber(value.join()))}
        renderTrack={({ props, children }) => (
          <div
            aria-hidden="true"
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
              height: '36px',
              display: 'flex',
              width: '100%',
            }}
          >
            <div
              ref={props.ref}
              style={{
                height: '5px',
                width: '100%',
                borderRadius: '4px',
                background: getTrackBackground({
                  values,
                  colors: ['rgba(255, 255, 255, 0.05)', '#FF136D', '#453FD6'],
                  direction: Direction.Left,
                  min: config.step.min,
                  max: config.step.max,
                }),
                alignSelf: 'center',
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props, isDragged }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: '20px',
              width: '20px',
              borderRadius: '50%',
              backgroundColor: '#181919',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                height: '18px',
                width: '18px',
                backgroundColor: '#FF136D',
                borderRadius: '50%',
              }}
            />
          </div>
        )}
      />
      <div className={classes.labelWrapper}>
        {config.labels.map((elem) => (
          <span key={elem.id} className={classes.label}>
            {elem.value}
          </span>
        ))}
      </div>
    </div>
  );
};

export default RangeSlider;
