import React from 'react';
import { ReactComponent as TooltipIcon } from 'assets/images/tooltip-icon.svg';
import classes from './styles.module.scss';

const TooltipBtn = ({ text }) => {
  return (
    <button className={classes.button} type="button" aria-label={text}>
      <TooltipIcon />
    </button>
  );
};

export default TooltipBtn;
