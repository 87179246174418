import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { JSBI, Percent } from '@pancakeswap-libs/sdk';
import BtnGradient from 'components/btnGradient';
import { ReactComponent as CaretDown } from 'assets/images/caret-down-icon.svg';
import { ReactComponent as CaretRight } from 'assets/images/caret-right-icon.svg';
import ROUTES from '../../../../../../constants/routes';
import { useActiveWeb3React } from '../../../../../../hooks';
import { unwrappedToken } from '../../../../../../utils/wrappedCurrency';
import { currencyId } from '../../../../../../utils/currencyId';
import { useTokenBalance } from '../../../../../../state/wallet/hooks';
import { useTotalSupply } from '../../../../../../data/TotalSupply';
// @ts-ignore
import classes from './styles.module.scss';
import ButtonBorderGradient from '../../../../../../components/ui/button/buttonBorderGradient';
import formatValue from '../../../../../../utils/formatNumber';

const LiquidityStats = ({ pair, setState }) => {
  const [isActive, setIsActive] = useState(false);
  const { account } = useActiveWeb3React();

  const currency0 = unwrappedToken(pair.token0);
  const currency1 = unwrappedToken(pair.token1);

  const [showMore, setShowMore] = useState(false);

  const userPoolBalance = useTokenBalance(account ?? undefined, pair.liquidityToken);
  const totalPoolTokens = useTotalSupply(pair.liquidityToken);

  const poolTokenPercentage =
    !!userPoolBalance && !!totalPoolTokens && JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? new Percent(userPoolBalance.raw, totalPoolTokens.raw)
      : undefined;

  const [token0Deposited, token1Deposited] =
    !!pair &&
    !!totalPoolTokens &&
    !!userPoolBalance &&
    // this condition is a short-circuit in the case where useTokenBalance updates sooner than useTotalSupply
    JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? [
          pair.getLiquidityValue(pair.token0, totalPoolTokens, userPoolBalance, false),
          pair.getLiquidityValue(pair.token1, totalPoolTokens, userPoolBalance, false),
        ]
      : [undefined, undefined];

  const contentWrapper = () =>
    classNames({
      [classes.contentWrapperActive]: isActive,
      [classes.contentWrapper]: !isActive,
    });

  return (
    <div className={classes.wrapper}>
      <button type="button" onClick={() => setIsActive(!isActive)} className={classes.heading}>
        <h3 className={classes.headingText}>{`${currency0.symbol}/${currency1.symbol}`}</h3>
        {isActive ? <CaretDown /> : <CaretRight />}
      </button>
      <div className={contentWrapper()}>
        <div className={classes.valueWrapper}>
          <h4 className={classes.valueHeading}>Pooled {currency0.symbol}:</h4>
          <p className={classes.value}>{token0Deposited ? formatValue(token0Deposited?.toSignificant(6)) : '-'}</p>
        </div>
        <div className={classes.valueWrapper}>
          <h4 className={classes.valueHeading}>Pooled {currency1.symbol}:</h4>
          <p className={classes.value}>{token1Deposited ? formatValue(token1Deposited?.toSignificant(6)) : '-'}</p>
        </div>
        <div className={classes.valueWrapper}>
          <h4 className={classes.valueHeading}>Your pool tokens:</h4>
          <p className={classes.value}>{userPoolBalance ? formatValue(userPoolBalance.toSignificant(4)) : '-'}</p>
        </div>
        <div className={classes.valueWrapper}>
          <h4 className={classes.valueHeading}>Your pool share:</h4>
          <p className={classes.value}>{poolTokenPercentage ? `${poolTokenPercentage.toFixed(2)}%` : '-'}</p>
        </div>
        <div className={classes.buttonWrapper}>
          <div className={classes.button}>
            <BtnGradient text="Add" func={setState} />
          </div>
          <Link
            to={`${ROUTES.LIQUIDITY_REMOVE}/${currencyId(currency0)}/${currencyId(currency1)}`}
            className={classes.button}
          >
            <ButtonBorderGradient text="Remove" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LiquidityStats;
