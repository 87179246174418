import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FixedSizeList } from 'react-window';
import { Currency, Token } from '@pancakeswap-libs/sdk';
import InputText from 'components/ui/input/inputText';
import { useTokenComparator } from 'components/SearchModal/sorting';
import { filterTokens } from 'components/SearchModal/filtering';
import { useAllTokens, useToken } from 'hooks/Tokens';
import ItemFav from './itemFav';
import ListItem from './listItem';
import { isAddress } from '../../../../../../utils';
import { useActiveWeb3React } from '../../../../../../hooks';
import { AppState } from '../../../../../../state';
import { useSelectedListInfo } from '../../../../../../state/lists/hooks';
// @ts-ignore
import classes from './styles.module.scss';
import ErrorField from '../../../../../../components/errorField';

const Modal = ({ onCurrencySelect, setModalState }) => {
  const { t } = useTranslation();
  const { chainId } = useActiveWeb3React();
  // const theme = useContext(ThemeContext)

  const fixedList = useRef<FixedSizeList>();
  const [searchValue, setSearchValue] = useState('');
  const tokenDefault = Currency.ETHER;
  const [invertSearchOrder, setInvertSearchOrder] = useState<boolean>(true);
  const allTokens = useAllTokens();
  const tokensList = useMemo(() => {
    return [tokenDefault, ...Object.values(allTokens)];
  }, [allTokens, tokenDefault]);

  console.log({ tokensList });

  const isAddressSearch = isAddress(searchValue);
  const searchToken = useToken(searchValue);

  const showETH: boolean = useMemo(() => {
    const s = searchValue.toLowerCase().trim();
    return s === '' || s === 'e' || s === 'et' || s === 'eth';
  }, [searchValue]);

  const tokenComparator = useTokenComparator(invertSearchOrder);

  const audioPlay = useSelector<AppState, AppState['user']['audioPlay']>((state) => state.user.audioPlay);

  const filteredTokens: Token[] = useMemo(() => {
    if (isAddressSearch) return searchToken ? [searchToken] : [];
    return filterTokens(tokensList, searchValue);
  }, [isAddressSearch, searchToken, tokensList, searchValue]);

  const filteredSortedTokens: Token[] = useMemo(() => {
    if (searchToken) return [searchToken];
    const sorted = filteredTokens.sort(tokenComparator);
    const symbolMatch = searchValue
      .toLowerCase()
      .split(/\s+/)
      .filter((s) => s.length > 0);
    if (symbolMatch.length > 1) return sorted;

    return [
      ...(searchToken ? [searchToken] : []),
      // sort any exact symbol matches first
      ...sorted.filter((token) => token.symbol?.toLowerCase() === symbolMatch[0]),
      ...sorted.filter((token) => token.symbol?.toLowerCase() !== symbolMatch[0]),
    ];
  }, [filteredTokens, searchValue, searchToken, tokenComparator]);

  const inputRef = useRef<HTMLInputElement>();

  const handleChange = useCallback((event) => {
    const input = event.target.value;
    const checksummedInput = isAddress(input);
    setSearchValue(checksummedInput || input);
  }, []);

  const filteredData = tokensList.filter((token) => {
    if (searchValue === '') {
      return token;
    }

    return token?.name?.toLowerCase().includes(searchValue);
  });

  const handleClick = (currency: Currency) => {
    setModalState(false);
    onCurrencySelect(currency);
  };

  const selectedListInfo = useSelectedListInfo();

  return (
    <>
      <InputText placeholder="Search or paste token address" handler={handleChange} />
      <div className={classes.listFav}>
        {tokensList.map((token) =>
          token.name === 'QIE' ? (
            <ItemFav key={token.name} label={token.symbol} func={() => handleClick(token)} />
          ) : (
            <ItemFav key={token.name} label={token.symbol} token={token} func={() => handleClick(token)} />
          )
        )}
      </div>
      <div className={classes.itemsList}>
        {filteredSortedTokens.length > 0 ? (
          filteredSortedTokens.map((token, index) => (
            <ListItem
              key={token.name}
              label={token.symbol}
              token={token}
              description={token.name}
              func={() => handleClick(token)}
            />
          ))
        ) : (
          <ErrorField text="Be careful with custom tokens" />
        )}
      </div>
    </>
  );
};

export default Modal;
