import React from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import classNames from 'classnames';
import PageWrapper from 'components/pageWrapper';
import CardNavNew from 'components/CardNavNew';
import HistoryModal from 'components/CardNavNew/cardToolbar/historyModal';
import SettingsModal from 'components/CardNavNew/cardToolbar/settingsModal';
import PageContent from './pageContent';
// @ts-ignore
import classes from './styles.module.scss';

type State = {
  sideMenu: {
    isActive: boolean;
  };
};

const Exchange = () => {
  const isFull = useSelector((state: State) => state.sideMenu.isActive);

  const wrapperClass = () =>
    classNames({
      [classes.wrapper]: true,
      [classes.widthSmall]: isFull,
      [classes.widthFull]: !isFull,
    });

  return (
    <>
      <Helmet>
        <title>Trade</title>
      </Helmet>
      <PageWrapper heading="Trade">
        <div className={wrapperClass()}>
          <CardNavNew />
          <div className={classes.contentWrapper}>
            <PageContent />
          </div>
        </div>
        <SettingsModal />
        <HistoryModal />
      </PageWrapper>
    </>
  );
};

export default Exchange;
