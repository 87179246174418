// @ts-nocheck

import { ContractFactory } from '@ethersproject/contracts';
import ModalWrapper from 'components/modalWrapper';
import Checkbox from 'components/ui/checkbox';
import OwnableCappedABI from 'constants/abis/OwnableCapped/OwnableCappedABI.json';
import OwnableCappedData from 'constants/abis/OwnableCapped/OwnableCappedData';
import OwnableUnlimitedFixedABI from 'constants/abis/OwnableUnlimitedFixed/OwnableUnlimitedFixedABI.json';
import OwnableUnlimitedFixedData from 'constants/abis/OwnableUnlimitedFixed/OwnableUnlimitedFixedData';
import RoleBasedCappedABI from 'constants/abis/RoleBasedCapped/RoleBasedCappedABI.json';
import RoleBasedCappedData from 'constants/abis/RoleBasedCapped/RoleBasedCappedData';
import RoleBasedUnlimitedFixedABI from 'constants/abis/RoleBasedUnlimitedFixed/RoleBasedUnlimitedFixedABI.json';
import RoleBasedUnlimitedFixedData from 'constants/abis/RoleBasedUnlimitedFixed/RoleBasedUnlimitedFixedData';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import convertToInternationalCurrencySystem from 'utils/convertToInternationalCurrencySystem';
import toWeiDecimals from 'utils/toWeiDecimals';
import { useSwitchNetwork } from 'wagmi';
import Web3 from 'web3';
import { ACTIVE_NETWORK, AdminOwner, explorerURL } from '../../../../../../constants';
import { useActiveWeb3React } from '../../../../../../hooks';
import { useProvider } from '../../../../../../hooks/v2/ethers-adapters';
import {
  decimalsValidatorCreator,
  nameValidatorCreator,
  supplyValidatorCreator,
  symbolValidatorCreator,
} from '../../../../../../utils/validator';
import DetailsInput from './detailsInput';
import FeatureInput from './featureInput';
import classes from './styles.module.scss';
import TransactionBlock from './transactionBlock';

const BurningContent = () => {
  const tockenCreatorFee = 0.0001;
  const { account, chainId } = useActiveWeb3React();
  const { switchNetwork } = useSwitchNetwork();
  const [isSubmit, setIsSubmit] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [checked, setChecked] = useState(true);
  const provider = useProvider();
  const [state, setState] = useState({
    source: false,
    remove: false,
    burnable: false,
    mintable: false,
    operable: false,
    recover: false,
  });
  const history = useHistory();
  const [formData, setFormData] = useState({
    TokenType: 'QIE20',
    types: 'Minting & Burnable',
    name: '',
    symbol: '',
    decimals: '',
    initialSupply: '',
    totalSupply: '',
    accessType: '',
    supplyType: '',
    burnable: false,
    mintable: false,
    defaultCheck: false,
  });
  const [modalState, setModalState] = useState(false);
  const [contractAddress, setContractAddress] = useState('');
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    trigger,
  } = useForm({
    mode: 'onChange',
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleCheck = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
    const temp = { ...formData, [event.target.name]: event.target.checked };
    setFormData(temp);
  };

  const uploadContractHandlerOwnableUnlimitedFixed = async (
    accountValue,
    stateValue,
    formDataValue,
    tockenCreatorFeeValue,
    cbValue
  ) => {
    try {
      const web3 = (window.web3 = new Web3(provider));
      const nfttokenContract = new ContractFactory(
        OwnableUnlimitedFixedABI,
        OwnableUnlimitedFixedData,
        provider.getSigner(account)
      );

      const supplyType = formDataValue.supplyType == 'fixed' ? 0 : 1;
      const tockenCreatorFeeS = web3.utils.toWei(tockenCreatorFeeValue.toString());

      const totalSupplyWE = toWeiDecimals(formDataValue.totalSupply.toString(), formDataValue.decimals);
      console.log('testing ---- OwnableUnlimitedFixed  ');

      const contract = await nfttokenContract.deploy(
        formDataValue.name,
        formDataValue.symbol,
        formDataValue.decimals.toString(),
        totalSupplyWE,
        formDataValue.mintable,
        formDataValue.burnable,
        supplyType,
        AdminOwner,
        tockenCreatorFeeS,
        { gasLimit: web3.utils.toHex('6540692'), value: web3.utils.toHex(tockenCreatorFeeS) }
      );

      await contract.deployTransaction.wait();

      if (contract.address) {
        cbValue({
          status: true,
          address: contract.address,
        });
      }
    } catch (error) {
      console.error(error);
      toast.error(error.message);
      cbValue({
        status: false,
        address: null,
      });
    }
  };

  const uploadContractHandlerOwnableCapped = async (
    accountValue,
    stateValue,
    formDataValue,
    tockenCreatorFeeValue,
    cbValue
  ) => {
    console.log('testing ---- OwnableCapped', tockenCreatorFeeValue);
    try {
      const web3 = (window.web3 = new Web3(provider));
      const nfttokenContract = new ContractFactory(OwnableCappedABI, OwnableCappedData, provider.getSigner(account));

      let tockenCreatorFeeS = web3.utils.toWei(tockenCreatorFeeValue.toString());

      let totalSupplyWE = toWeiDecimals(formDataValue.totalSupply.toString(), formDataValue.decimals);
      let initialSupplyWE = toWeiDecimals(formDataValue.initialSupply.toString(), formDataValue.decimals);
      // console.log("testing ---- OwnableUnlimitedFixed  ", tockenCreatorFeeS);

      const contract = await nfttokenContract.deploy(
        formDataValue.name,
        formDataValue.symbol,
        formDataValue.decimals.toString(),
        initialSupplyWE,
        totalSupplyWE,
        formDataValue.mintable,
        formDataValue.burnable,
        AdminOwner,
        tockenCreatorFeeS,
        { gasLimit: web3.utils.toHex('6540692'), value: web3.utils.toHex(tockenCreatorFeeS) }
      );

      await contract.deployTransaction.wait();

      if (contract.address) {
        cbValue({
          status: true,
          address: contract.address,
        });
      }
    } catch (error) {
      console.error(error);
      toast.error(error.message);
      cbValue({
        status: false,
        address: null,
      });
    }
  };

  const uploadContractHandlerRoleBasedUnlimitedFixed = async (
    accountValue,
    stateValue,
    formDataValue,
    tockenCreatorFeeValue,
    cbValue
  ) => {
    try {
      const web3 = (window.web3 = new Web3(provider));
      const nfttokenContract = new ContractFactory(
        RoleBasedUnlimitedFixedABI,
        RoleBasedUnlimitedFixedData,
        provider.getSigner(account)
      );

      const tockenCreatorFeeS = web3.utils.toWei(tockenCreatorFeeValue.toString());
      const supplyType = formData.supplyType == 'fixed' ? 0 : 1;

      const totalSupplyWE = toWeiDecimals(formDataValue.totalSupply.toString(), formDataValue.decimals);
      console.log(formDataValue);
      console.log(stateValue);

      const contract = await nfttokenContract.deploy(
        formDataValue.name,
        formDataValue.symbol,
        formDataValue.decimals.toString(),
        totalSupplyWE,
        // web3.utils.toWei(formData.totalSupply.toString()),
        formDataValue.mintable,
        formDataValue.burnable,
        supplyType,
        AdminOwner,
        tockenCreatorFeeS,
        { gasLimit: web3.utils.toHex('6000000'), value: web3.utils.toHex(tockenCreatorFeeS) }
      );

      await contract.deployTransaction.wait();

      if (contract.address) {
        cbValue({
          status: true,
          address: contract.address,
        });
      }
    } catch (error) {
      console.error(error);
      toast.error(error.message);
      cbValue({
        status: false,
        address: null,
      });
    }
  };

  const uploadContractHandlerRoleBasedCapped = async (
    accountValue,
    stateValue,
    formDataValue,
    tockenCreatorFeeValue,
    cbValue
  ) => {
    try {
      const web3 = (window.web3 = new Web3(provider));
      const nfttokenContract = new ContractFactory(
        RoleBasedCappedABI,
        RoleBasedCappedData,
        provider.getSigner(account)
      );
      const tockenCreatorFeeS = web3.utils.toWei(tockenCreatorFeeValue.toString());

      const totalSupplyWE = toWeiDecimals(formDataValue.totalSupply.toString(), formDataValue.decimals);
      const initialSupplyWE = toWeiDecimals(formDataValue.initialSupply.toString(), formDataValue.decimals);
      // console.log("testing ---- OwnableUnlimitedFixed  ", tockenCreatorFeeS);

      const contract = await nfttokenContract.deploy(
        formDataValue.name,
        formDataValue.symbol,
        formDataValue.decimals.toString(),
        initialSupplyWE,
        totalSupplyWE,
        // web3.utils.toWei(formData.initialSupply.toString()),
        // web3.utils.toWei(formData.totalSupply.toString()),
        formDataValue.mintable,
        formDataValue.burnable,
        // supplyType,
        AdminOwner,
        tockenCreatorFeeS,
        { gasLimit: web3.utils.toHex('6540692'), value: web3.utils.toHex(tockenCreatorFeeS) }
      );

      await contract.deployTransaction.wait();

      if (contract.address) {
        cbValue({
          status: true,
          address: contract.address,
        });
      }
    } catch (error) {
      console.error(error);
      toast.error(error.message);
      cbValue({
        status: false,
        address: null,
      });
    }
  };

  const submitHandler = async () => {
    if (ACTIVE_NETWORK == chainId) {
      setIsSubmit(true);
      try {
        if (formData.accessType !== '' && formData.supplyType !== '' && formData.defaultCheck) {
          if (formData.accessType !== 'roleBased') {
            console.log('formData.accessType ', formData.accessType);
            if (formData.totalSupply !== '') {
              if (formData.supplyType !== 'capped') {
                setIsUpdating(true);
                await uploadContractHandlerOwnableUnlimitedFixed(
                  account,
                  state,
                  formData,
                  tockenCreatorFee,
                  (result) => {
                    if (result.status && result.address) {
                      console.log(`${explorerURL}/address/${result.address}`);
                      // window.open(`${explorerURL}/address/${result.address}`);
                      // history.push({
                      //     pathname: "/app/view-token",
                      //     search: result.address,
                      //     state: formData,
                      // });
                      // toast.success("Success");
                      setContractAddress(result.address);
                      setModalState(true);
                      toast.success('Token created successfully');
                    } else {
                    }
                  }
                );
              } else {
                setIsUpdating(true);
                await uploadContractHandlerOwnableCapped(account, state, formData, tockenCreatorFee, (result) => {
                  if (result.status && result.address) {
                    console.log(`${explorerURL}/address/${result.address}`);
                    // window.open(`${explorerURL}/address/${result.address}`);
                    setContractAddress(result.address);
                    setModalState(true);
                    toast.success('Token created successfully');
                  } else {
                  }
                });
              }
              // } else {
              //   toast.error("Wrong combination");
            }
          } else {
            console.log('formData.accessType ', formData.accessType);
            if (formData.totalSupply !== '') {
              if (formData.supplyType !== 'capped') {
                setIsUpdating(true);
                await uploadContractHandlerRoleBasedUnlimitedFixed(
                  account,
                  state,
                  formData,
                  tockenCreatorFee,
                  (result) => {
                    if (result.status && result.address) {
                      console.log(`${explorerURL}/address/${result.address}`);
                      // window.open(`${explorerURL}/address/${result.address}`);
                      setContractAddress(result.address);
                      setModalState(true);
                      toast.success('Token created successfully');
                    } else {
                    }
                  }
                );
              } else {
                setIsUpdating(true);
                await uploadContractHandlerRoleBasedCapped(account, state, formData, tockenCreatorFee, (result) => {
                  if (result.status && result.address) {
                    console.log(`${explorerURL}/address/${result.address}`);
                    // window.open(`${explorerURL}/address/${result.address}`);
                    setContractAddress(result.address);
                    setModalState(true);
                    toast.success('Token created successfully');
                  } else {
                  }
                });
              }
              // } else {
              //   toast.error("Wrong combination");
            }
          }
        }
        setIsUpdating(false);
      } catch (error) {
        setIsUpdating(false);
        console.log('ERROR', error);
      }
    } else {
      switchNetwork?.(ACTIVE_NETWORK);
    }
  };

  const _onInputChange1 = (e) => {
    const cursor = e.target?.selectionStart;
    const parsedValue = e.target.value.replace(/,/g, '');

    if (/^\d*\.?\d*$/.test(parsedValue)) {
      const temp = { ...formData, [e.target.name]: parsedValue };
      setFormData(temp);
    }

    e.target.selectionStart = cursor;
  };

  const _onInputChange = (e) => {
    const temp = { ...formData, [e.target.name]: e.target.value };
    setFormData(temp);
  };

  const handleInputControlClick = (action, dataName, step) => {
    const temp = {
      ...formData,
      [dataName]: action === 'increase' ? Number(formData[dataName]) + step : Number(formData[dataName]) - step,
    };
    setValue(dataName, action === 'increase' ? Number(formData[dataName]) + step : Number(formData[dataName]) - step);
    trigger(dataName);
    setFormData(temp);
  };

  const onSelectChange = (e, name) => {
    if (e) {
      const temp = { ...formData, [name]: e.value };
      setFormData(temp);
    } else {
      const temp = { ...formData, [name]: '' };
      setFormData(temp);
    }
  };

  const onCheckboxChange = (e, name) => {
    const temp = { ...formData, [name]: e.target.checked };
    setFormData(temp);
  };

  const closeModal = () => {
    setFormData({
      TokenType: 'QIE20',
      types: 'Minting & Burnable',
      name: '',
      symbol: '',
      decimals: '',
      initialSupply: '',
      totalSupply: '',
      accessType: '',
      supplyType: '',
      burnable: false,
      mintable: false,
      defaultCheck: false,
    });
    setModalState(false);
  };

  return (
    <div className={classes.wrapper}>
      <DetailsInput
        inputNameValue={formData.name}
        setInputNameValue={_onInputChange}
        inputNameRegister={{
          ...register('name', {
            validate: nameValidatorCreator,
            onChange: (e) => {
              setValue('name', e.target.value);
              _onInputChange(e);
            },
          }),
        }}
        inputNameErrors={errors?.name?.message}
        inputSymbolValue={formData.symbol}
        setInputSymbolValue={_onInputChange}
        inputSymbolRegister={{
          ...register('symbol', {
            validate: symbolValidatorCreator,
            onChange: (e) => {
              setValue('symbol', e.target.value);
              _onInputChange(e);
            },
          }),
        }}
        inputSymbolErrors={errors?.symbol?.message}
        inputDecimalsValue={formData.decimals}
        inputDecimalsErrors={errors?.decimals?.message}
        inputDecimalsRegister={{
          ...register('decimals', {
            validate: decimalsValidatorCreator,
            onChange: (e) => {
              setValue('decimals', e.target.value);
              _onInputChange1(e);
            },
          }),
        }}
        handlerInputControlClick={handleInputControlClick}
        inputNumberHandler={_onInputChange1}
        inputSupplyValue={formData.totalSupply}
        inputSupplyErrors={errors?.totalSupply?.message}
        inputSupplyRegister={{
          ...register('totalSupply', {
            validate: supplyValidatorCreator,
            onChange: (e) => {
              setValue('totalSupply', e.target.value);
              _onInputChange1(e);
            },
          }),
        }}
        inputInitialValue={formData.initialSupply}
      />
      <FeatureInput
        selectHandler={onSelectChange}
        checkboxMintValue={formData.mintable}
        checkboxBurnValue={formData.burnable}
        checkboxTermsValue={formData.defaultCheck}
        checkBoxHandler={onCheckboxChange}
      />
      <TransactionBlock
        btnText={isUpdating ? 'Creating...' : 'Create Token'}
        disabled={
          formData.totalSupply.toString().length > 21 ||
          isUpdating ||
          formData.name.length > 32 ||
          formData.symbol.length > 32 ||
          Number(formData.totalSupply) >= 1000000000000000000000
        }
        submitHandler={submitHandler}
        comissionFee={tockenCreatorFee}
      />
      <ModalWrapper state={modalState} onClose={() => closeModal()} heading={`Token ${formData?.name}`}>
        <div className={classes.modalWrapper}>
          <div>
            <h3 className={classes.subheading}>
              {formData?.types} {formData?.TokenType}
            </h3>
          </div>
          <div className={classes.valueGrid}>
            <div className={classes.valueWrapper}>
              <h4 className={classes.valueHeading}>Name</h4>
              <p className={classes.value}>{formData?.name}</p>
            </div>
            <div className={classes.valueWrapper}>
              <h4 className={classes.valueHeading}>Symbol</h4>
              <p className={classes.value}>{formData?.symbol}</p>
            </div>
            <div className={classes.valueWrapper}>
              <h4 className={classes.valueHeading}>Decimals</h4>
              <p className={classes.value}>{formData?.decimals}</p>
            </div>
            <div className={classes.valueWrapper}>
              <h4 className={classes.valueHeading}>Total Supply</h4>
              <p className={classes.value}>{convertToInternationalCurrencySystem(formData?.totalSupply)}</p>
            </div>
            <div className={classes.valueWrapper}>
              <h4 className={classes.valueHeading}>Initial Supply</h4>
              <p className={classes.value}>{formData.initialSupply}</p>
            </div>
            <div className={classes.valueWrapper}>
              <h4 className={classes.valueHeading}>Supply Type</h4>
              <p className={classes.value}>{formData.supplyType}</p>
            </div>
            <div className={classes.valueWrapper}>
              <h4 className={classes.valueHeading}>Access Type</h4>
              <p className={classes.value}>{formData.accessType}</p>
            </div>
            <div className={classes.valueWrapper}>
              <h4 className={classes.valueHeading}>Burnable Status</h4>
              <div>
                <Checkbox checkStatus={formData.burnable} disabled={true} />
              </div>
            </div>
            <div className={classes.valueWrapper}>
              <h4 className={classes.valueHeading}>Mintable Status</h4>
              <div>
                <Checkbox checkStatus={formData.mintable} disabled={true} />
              </div>
            </div>
          </div>
          <div className={classes.valueContractWrapper}>
            <h4 className={classes.valueHeading}>Contract Address</h4>
            <p className={classes.valueContract}>{contractAddress}</p>
          </div>
        </div>
      </ModalWrapper>
    </div>
  );
};

export default BurningContent;
