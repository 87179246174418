import React, { useState } from 'react';
import InputRadio from 'components/ui/input/inputRadio';
import FixedContent from './fixedContent';
import BurningdContent from './burningContent';
import classes from './styles.module.scss';

const BaseContent = () => {
  const [activeRadio, setActiveRadio] = useState(0);

  return (
    <div className={classes.wrapper}>
      <div className={classes.radioWrapper}>
        <InputRadio
          label="Fixed supply token"
          id="fixed-token"
          name="token"
          value={0}
          state={activeRadio}
          setState={setActiveRadio}
        />
        <InputRadio
          label="Minting & Burning Token"
          id="burning-token"
          name="token"
          value={1}
          state={activeRadio}
          setState={setActiveRadio}
        />
      </div>
      {!activeRadio ? <FixedContent /> : <></>}
      {activeRadio ? <BurningdContent /> : <></>}
    </div>
  );
};

export default BaseContent;
