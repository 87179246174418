import React from 'react';
import NavBlock from './navBlock';
import LogoBlock from './logoBlock';
import CopyrightBlock from './copyrightBlock';
import classes from './styles.module.scss';

const PageFooter = () => {
  return (
    <div className={classes.wrapper}>
      <LogoBlock />
      <NavBlock />
      <CopyrightBlock />
    </div>
  );
};

export default PageFooter;
