import React, { useRef } from 'react';
import Modal from 'react-modal';

import { ReactComponent as WalletIcon } from 'assets/images/wallet-icon.svg';
import { useAccount, useDisconnect } from 'wagmi';
import classes from './styles.module.scss';
import WalletConnect from '../../walletConnect';
import Wallet from '../../wallet';
import BuyButton from '../../ui/button/buyButton';

const paymentSettings = {
  apiKey: '0a2236fc-af87-4939-bb34-9eb3f0280e77',
  colors: {
    main: '#453fd6',
    background: '#100f0f',
  },
};

const paymentStyles = {
  content: {
    width: 'fit-content',
    height: 'fit-content',
  },
};

const PageHeader = ({ heading }) => {
  const [modalIsOpen, setIsOpen] = React.useState(false);

  const ref = useRef();

  const { address, isConnected } = useAccount();

  const defaultValues = {
    targetAddress: {
      value: address,
      editable: true,
    },
  };

  const openModal = () => setIsOpen(true);

  const closeModal = () => setIsOpen(false);

  const openPaymentForm = async () => {
    await openModal();

    const widget = new window.SwipeluxWidget(ref.current, { ...paymentSettings, defaultValues });
    widget.init();
  };

  return (
    <>
      <div className={classes.wrapper}>
        <h2 className={classes.heading}>{heading}</h2>
        <div className={classes.buttonsBlock}>
          <div className={classes.buyBtn}>
            <BuyButton onClick={openPaymentForm} />
          </div>
          {isConnected ? (
            <Wallet />
          ) : (
            <div className={classes.btnWrapper}>
              <WalletConnect icon={WalletIcon} />
            </div>
          )}
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        style={paymentStyles}
        className="paymentModal"
        ariaHideApp={false}
      >
        <div ref={ref} className={classes.payment} />
      </Modal>
    </>
  );
};

export default PageHeader;
