import React from 'react';
import { ReactComponent as ArrowIcon } from 'assets/images/arrow-left-icon.svg';
import classes from './styles.module.scss';

const Heading = ({ setIsActive }) => {
  return (
    <div className={classes.wrapper}>
      <button type="button" className={classes.button} onClick={setIsActive}>
        <ArrowIcon />
      </button>
      <h2 className={classes.heading}>Add Liquidity</h2>
    </div>
  );
};

export default Heading;
