import React, { useState } from 'react';
import classNames from 'classnames';
import { ReactComponent as CaretRight } from 'assets/images/caret-right-icon.svg';
import { ReactComponent as CaretDown } from 'assets/images/caret-down-icon.svg';
import classes from '../styles.module.scss';

const AccordionDefi = () => {
  const [isActive, setIsActive] = useState(false);

  const handleToggleVisibility = () => setIsActive(!isActive);

  const buttonClasses = () =>
    classNames({
      [classes.button]: true,
      [classes.buttonActive]: isActive,
    });

  const textClasses = () =>
    classNames({
      [classes.content]: true,
      [classes.contentActive]: isActive,
    });

  const breakLineClasses = () =>
    classNames({
      [classes.breakLine]: true,
      [classes.breakLineActive]: isActive,
    });

  return (
    <div>
      <button type="button" className={buttonClasses()} onClick={handleToggleVisibility}>
        <div className={classes.headingWrapper}>Defi use cases?</div>
        <div>{isActive ? <CaretDown /> : <CaretRight />}</div>
      </button>
      <p className={textClasses()}>- Asset management</p>
      <br className={breakLineClasses()} />
      <p className={textClasses()}>- Compliance and KYT</p>
      <br className={breakLineClasses()} />
      <p className={textClasses()}>- Data and Analytics</p>
      <br className={breakLineClasses()} />
      <p className={textClasses()}>- DeFi Exchanges (DEX)</p>
      <br className={breakLineClasses()} />
      <p className={textClasses()}>- Gaming</p>
      <br className={breakLineClasses()} />
      <p className={textClasses()}>- Stable coins</p>
      <br className={breakLineClasses()} />
      <p className={textClasses()}>- Synthetic assets</p>
      <br className={breakLineClasses()} />
      <p className={textClasses()}>- Tokenization</p>
    </div>
  );
};

export default AccordionDefi;
