import React from 'react';
import ContentHeading from 'components/contentHeading';
import classes from './styles.module.scss';

const Header = () => {
  return (
    <div className={classes.wrapper}>
      <ContentHeading text="Token Swap Info" />
    </div>
  );
};

export default Header;
