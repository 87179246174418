import React from 'react';
import classes from './styles.module.scss';

const Content = () => {
  return (
    <div className={classes.wrapper}>
      <div className={classes.contentWrapper}>
        <p className={classes.text}>
          Qidex is a decentralized peer-to-peer protocol that people can use to create liquidity and trade Qi-20 tokens.
          Qidex is made up of free, public, open-source or source-available software including a set of smart contracts
          that are deployed on the Qi Blockchain. Your use of the Qidex protocol involves various risks, including, but
          not limited to, losses while digital assets are being supplied to the Qidex protocol and losses due to the
          fluctuation of prices of tokens in a trading pair or liquidity pool. Before using the Qidex protocol, you
          should review the relevant documentation to make sure you understand how the Qidex protocol works.
          Additionally, just as you can access email protocols such as SMTP through multiple email clients, you can
          access the Qidex protocol through dozens of web or mobile interfaces. You are responsible for doing your own
          diligence on those interfaces to understand the fees and risks they present.
        </p>
        <p className={classes.text}>
          AS DESCRIBED IN THE QIDEX PROTOCOL LICENSES, THE QIDEX PROTOCOL IS PROVIDED &quot;AS IS&quot;, AT YOUR OWN
          RISK, AND WITHOUT WARRANTIES OF ANY KIND. Although VortX Capital’s subsidiary &quot;Qidex Labs&quot; (
          &quot;Qidex Labs&quot; ) developed much of the initial code for the Qidex protocol, it does not provide, own,
          or control the Qidex protocol, which is run by smart contracts deployed on the Qi blockchain. Upgrades and
          modifications to the protocol are managed in a community-driven way by holders of the governance token. No
          developer or entity involved in creating the Qidex protocol will be liable for any claims or damages
          whatsoever associated with your use, inability to use, or your interaction with other users of, the Qidex
          protocol, including any direct, indirect, incidental, special, exemplary, punitive or consequential damages,
          or loss of profits, cryptocurrencies, tokens, or anything else of value. By using Qidex you acknowledge that
          your token has passed the Howey test and not a security in any shape or form. You also ensure that you have
          done the necessary KYC/AML if applicable to all holders you intend to send your tokens.
        </p>
      </div>
    </div>
  );
};

export default Content;
