import React from 'react';
import classnames from 'classnames';
import classesCommon from '../styles.module.scss';
import classes from './styles.module.scss';

type Props = {
  heading: string;
  value?: number;
  children?: any;
  setValue?: any;
  name?: string;
  disabled?: boolean;
  error?: any;
};

const InputNumWithHeading = ({ heading, value, children, setValue, name, disabled, error }: Props) => {
  const inputClass = () =>
    classnames({
      [classesCommon.input]: true,
      [classes.input]: true,
      [classesCommon.inputError]: error,
    });

  return (
    <div className={classes.wrapper}>
      <h6 className={classes.heading}>{heading}</h6>
      <div className={classesCommon.wrapper}>
        <input
          className={inputClass()}
          name={name}
          value={value}
          disabled={disabled}
          type="text"
          inputMode="decimal"
          placeholder="0.00"
          onChange={setValue}
        />
        {error && <p className={classes.error}>{error}</p>}
        {children}
      </div>
    </div>
  );
};

export default InputNumWithHeading;
