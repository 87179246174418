import { ModalProvider } from '@pancakeswap-libs/uikit';
import { RainbowKitProvider } from '@rainbow-me/rainbowkit';
import '@rainbow-me/rainbowkit/styles.css';
import React from 'react';
import { Provider } from 'react-redux';
import { WagmiConfig } from 'wagmi';
import { chains, demoAppInfo, themeDark, wagmiConfig } from './providers-config';
import store from './state';
import { ThemeContextProvider } from './ThemeContext';

const Providers: React.FC = ({ children }) => {
  return (
    <WagmiConfig config={wagmiConfig}>
      <RainbowKitProvider appInfo={demoAppInfo} chains={chains} theme={themeDark}>
        <Provider store={store}>
          <ThemeContextProvider>
            <ModalProvider>{children}</ModalProvider>
          </ThemeContextProvider>
        </Provider>
      </RainbowKitProvider>
    </WagmiConfig>
  );
};

export default Providers;
