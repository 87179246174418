import React from 'react';
import { Helmet } from 'react-helmet';
import PageWrapper from 'components/pageWrapper';
import Content from './content';
import classes from './styles.module.scss';

const FAQ = () => {
  return (
    <>
      <Helmet>
        <title>FAQ</title>
      </Helmet>
      <PageWrapper heading="FAQ">
        <div className={classes.wrapper}>
          <Content />
        </div>
      </PageWrapper>
    </>
  );
};

export default FAQ;
