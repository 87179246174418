import React from 'react';
import classes from './styles.module.scss';

type Props = {
  Icon?: any;
  text?: string;
  setState?: () => void;
};

const ButtonBorderGradient = ({ Icon, text, setState }: Props) => {
  return (
    <div className={classes.moduleBorderWrap}>
      <button type="button" className={classes.button} onClick={setState}>
        {Icon && (
          <div className={classes.icon}>
            <Icon />
          </div>
        )}
        {text && <div className={classes.text}>{text}</div>}
      </button>
    </div>
  );
};

export default ButtonBorderGradient;
