import { createSlice } from '@reduxjs/toolkit';

const chartTimeSwitcherSlice = createSlice({
  name: 'chartTimeSwitcher',
  initialState: {
    isActive: '1D',
  },
  reducers: {
    setIsActive(state, action) {
      state.isActive = action.payload;
    },
  },
});

export default chartTimeSwitcherSlice.reducer;
export const { setIsActive } = chartTimeSwitcherSlice.actions;
