// @ts-nocheck

import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Tooltip } from 'chart.js';
import { Line } from 'react-chartjs-2';
import dayjs from 'dayjs';
import swapInfoApi from '../../../../../../../services/endpoints';
import classes from './styles.module.scss';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip);

type StateType = {
  chartTimeSwitcher: {
    isActive: boolean;
  };
};

const ChartItem = ({
  tickData,
  setTickData,
  priceInverted,
  setPriceInverted,
  setChartDataHeader,
  pairAddress,
  selectInputValue,
  tokenInverted,
}) => {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        data: [],
        lineTension: 0.3,
        fill: true,
        borderColor: (context) => {
          const colorStart = '#3731B9';
          const colorEnd = '#FF136D';
          const { ctx } = context.chart;
          const area = context.chart.chartArea;

          const gradient = ctx.createLinearGradient(area.left, 0, area.right, 0);

          gradient.addColorStop(0, colorStart);
          gradient.addColorStop(1, colorEnd);

          return gradient;
        },
        pointRadius: 0,
      },
    ],
  });
  const [perElement, setPerElement] = useState(6);
  const [timeFrame, setTimeFrame] = useState(dayjs().subtract(24, 'hours').valueOf());
  const periodValue = useSelector((state: StateType) => state.chartTimeSwitcher.isActive);
  const ref = useRef();

  const checkPerElement = () => {
    if (periodValue === '1D') {
      setTimeFrame(dayjs().subtract(24, 'hours').valueOf());
      setPerElement(6);
    }

    if (periodValue === '7D') {
      setTimeFrame(
        dayjs()
          .subtract(24 * 7, 'hours')
          .valueOf()
      );
      setPerElement(42);
    }

    if (periodValue === '1M') {
      setTimeFrame(
        dayjs()
          .subtract(24 * 30, 'hours')
          .valueOf()
      );
      setPerElement(180);
    }

    if (periodValue === '3M') {
      setTimeFrame(
        dayjs()
          .subtract(24 * 90, 'hours')
          .valueOf()
      );
      setPerElement(540);
    }

    if (periodValue === '1Y') {
      setTimeFrame(
        dayjs()
          .subtract(24 * 365, 'hours')
          .valueOf()
      );
      setPerElement(2190);
    }
  };

  const getRatesList = async () => {
    if (pairAddress) {
      try {
        const res = await swapInfoApi.getChartData(pairAddress, timeFrame, perElement);

        const dataTemp = res.data;
        const ratesData = [];
        const labelData = [];

        dataTemp.forEach((elem, index) => {
          // if (index % perElement === 0) {
          if (tokenInverted) {
            if (!priceInverted) {
              ratesData.push(1 / elem[elem.length - 1]);
            } else {
              ratesData.push(elem[elem.length - 1]);
            }
          } else {
            // eslint-disable-next-line
            if (priceInverted) {
              ratesData.push(1 / elem[elem.length - 1]);
            } else {
              ratesData.push(elem[elem.length - 1]);
            }
          }
          labelData.push(elem[0]);
          // }
        });

        setChartDataHeader(ratesData);

        setChartData({
          labels: labelData,
          datasets: [
            {
              data: ratesData,
              lineTension: 0.3,
              fill: true,
              borderColor: (context) => {
                const colorStart = '#3731B9';
                const colorEnd = '#FF136D';
                const { ctx } = context.chart;
                const area = context.chart.chartArea;

                const gradient = ctx.createLinearGradient(area.left, 0, area.right, 0);

                gradient.addColorStop(0, colorStart);
                gradient.addColorStop(1, colorEnd);

                return gradient;
              },
              pointRadius: 0,
            },
          ],
        });
      } catch (e) {
        console.log(e);
      }
    }
  };

  useEffect(() => {
    checkPerElement();
  }, [periodValue]); // eslint-disable-line

  console.log({ tokenInverted });

  useEffect(() => {
    getRatesList();
  }, [perElement, priceInverted, pairAddress, tokenInverted]); // eslint-disable-line

  useEffect(() => {
    const interval = setInterval(() => {
      getRatesList();
    }, 60000);

    return () => clearInterval(interval);
  }, []); // eslint-disable-line

  const options = {
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {},
  };

  const setChartTickets = () => {
    const arr = [];
    const { ticks } = ref.current.scales.y;
    const ticksSorted = ticks.reverse();

    ticksSorted.forEach((elem) => arr.push(elem.label));

    setTickData(arr);
  };

  useEffect(() => {
    setChartTickets();
  }, [chartData]); // eslint-disable-line

  return (
    <div className={classes.wrapper}>
      <div className={classes.legendWrapper}>
        {tickData.length &&
          tickData.map((elem, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={`key_${index}`} className={classes.legendItem}>
              {elem}
            </div>
          ))}
      </div>
      <div className={classes.chartContainer}>
        <Line ref={ref} data={chartData} options={options} updateMode="none" />
      </div>
    </div>
  );
};

export default ChartItem;
