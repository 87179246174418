import React, { useState } from 'react';
import classNames from 'classnames';
import { ReactComponent as CaretRight } from 'assets/images/caret-right-icon.svg';
import { ReactComponent as CaretDown } from 'assets/images/caret-down-icon.svg';
import classes from '../styles.module.scss';

const AccordionSecurityToken = () => {
  const [isActive, setIsActive] = useState(false);

  const handleToggleVisibility = () => setIsActive(!isActive);

  const buttonClasses = () =>
    classNames({
      [classes.button]: true,
      [classes.buttonActive]: isActive,
    });

  const textClasses = () =>
    classNames({
      [classes.content]: true,
      [classes.contentActive]: isActive,
    });

  const breakLineClasses = () =>
    classNames({
      [classes.breakLine]: true,
      [classes.breakLineActive]: isActive,
    });

  return (
    <div>
      <button type="button" className={buttonClasses()} onClick={handleToggleVisibility}>
        <div className={classes.headingWrapper}>Are security tokens allowed on Qidex?</div>
        <div>{isActive ? <CaretDown /> : <CaretRight />}</div>
      </button>
      <p className={textClasses()}>
        No. Qidex only make provision for utility tokens used in closed ecosystem and each creator need to ensure they
        abide to the Howey test. The Howey Test determines what qualifies as an &quot;investment contract&quot; and
        would therefore be subject to U.S. securities laws.
      </p>
      <br className={breakLineClasses()} />
      <br className={breakLineClasses()} />
      <p className={textClasses()}>
        An investment contract exists if there is an ESLint: &quot;investment of money in a common enterprise with a
        reasonable expectation of profits to be derived from the efforts of others.&quot;
      </p>
      <br className={breakLineClasses()} />
      <br className={breakLineClasses()} />
      <p className={textClasses()}>
        The Howey Test is important for situating blockchain and digital currency projects with investors and project
        backers.
      </p>
    </div>
  );
};

export default AccordionSecurityToken;
