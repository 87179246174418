const config = [
  {
    id: 0,
    value: 0.1,
  },
  {
    id: 1,
    value: 0.5,
  },
  {
    id: 2,
    value: 1,
  },
];

export default config;
