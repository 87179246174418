import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { setIsActive } from 'state/sideMenu/slice';
import useGetScreenWidth from 'hooks/useGetScreenWidth';
import LogoBlock from './logoBlock';
import MenuCtrl from './menuCtrl';
import NavBlock from './navBlock';
import classes from './styles.module.scss';

const SideMenu = () => {
  const [isFull, setIsFull] = useState(true);
  const screenWidth = useGetScreenWidth();
  const dispatch = useDispatch();

  const wrapperClass = () =>
    classNames({
      [classes.wrapper]: true,
      [classes.widthFull]: isFull,
      [classes.widthSmall]: !isFull,
    });

  const setMenuView = () => {
    if (screenWidth > 1400) {
      setIsFull(true);
      dispatch(setIsActive(true));
    } else {
      setIsFull(false);
      dispatch(setIsActive(false));
    }
  };

  useEffect(() => {
    setMenuView();
    // eslint-disable-next-line
  }, [screenWidth]);

  return (
    <div className={wrapperClass()}>
      <div>
        <LogoBlock isFull={isFull} />
        <NavBlock isFull={isFull} />
      </div>
      <MenuCtrl
        isFull={isFull}
        setState={() => {
          dispatch(setIsActive(!isFull));
          setIsFull(!isFull);
        }}
      />
    </div>
  );
};

export default SideMenu;
