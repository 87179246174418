import { createSlice } from '@reduxjs/toolkit';

const exchangeSlice = createSlice({
  name: 'exchange',
  initialState: {
    activeIndex: 0,
    isHistoryModalActive: false,
    isSettingsModalActive: false,
    isCurrencyChosen: false,
  },
  reducers: {
    setActiveIndex(state, action) {
      state.activeIndex = action.payload;
    },
    setIsHistoryModalActive(state, action) {
      state.isHistoryModalActive = action.payload;
    },
    setIsSettingsModalActive(state, action) {
      state.isSettingsModalActive = action.payload;
    },
    setIsCurrencyChosen(state, action) {
      state.isCurrencyChosen = action.payload;
    },
  },
});

export default exchangeSlice.reducer;
export const {
  setActiveIndex,
  setIsHistoryModalActive,
  setIsSettingsModalActive,
  setIsCurrencyChosen,
} = exchangeSlice.actions;
