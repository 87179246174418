import React from 'react';
import classes from './styles.module.scss';

type Props = {
  label: string;
  value: string;
  measure?: string;
};

const TotalNum = ({ label, value, measure }: Props) => {
  return (
    <div className={classes.wrapper}>
      <h6 className={classes.label}>{label}</h6>
      <div className={classes.valueWrapper}>
        <span className={classes.value}>{value}</span>
        <span className={classes.measure}>{measure}</span>
      </div>
    </div>
  );
};

export default TotalNum;
