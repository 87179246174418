import React from 'react';
import HeadingWithTooltip from 'components/headingWithTooltip';
import classes from './styles.module.scss';

const CheckoutBlockXs = ({ heading, tooltipText, value }) => {
  return (
    <div className={classes.wrapper}>
      <HeadingWithTooltip heading={heading} tooltipText={tooltipText} />
      <p className={classes.value}>{value}</p>
    </div>
  );
};

export default CheckoutBlockXs;
