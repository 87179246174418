import React from 'react';
import CardContent from 'components/cardContent';
import classes from './styles.module.scss';

const CardItem = ({ label, value, add }) => {
  return (
    <div className={classes.wrapper}>
      <CardContent label={label} value={value} add={add} />
    </div>
  );
};

export default CardItem;
