import React from 'react';
import config from './config';
import SpecItem from './specItem';
import classes from './styles.module.scss';

const SpecList = () => {
  return (
    <div className={classes.wrapper}>
      {config.map((elem) => (
        <SpecItem key={elem.id} text={elem.text} Icon={elem.Icon} />
      ))}
    </div>
  );
};

export default SpecList;
