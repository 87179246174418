import React from 'react';
import TooltipBtn from 'components/tooltipBtn';
import classes from './styles.module.scss';

const Subheading = () => {
  return (
    <div className={classes.wrapper}>
      <h4 className={classes.text}>Your Liquidity</h4>
      <TooltipBtn text="When you add liquidity, you are given pool tokens that represent your share." />
    </div>
  );
};

export default Subheading;
