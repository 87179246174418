import React from 'react';
import classnames from 'classnames';
import { ReactComponent as PlusIcon } from 'assets/images/plus-icon.svg';
import { ReactComponent as MinusIcon } from 'assets/images/minus-icon.svg';
import ButtonBorderGradient from '../ui/button/buttonBorderGradient';
import classesCommon from '../ui/input/styles.module.scss';
import classes from './styles.module.scss';

type Props = {
  value?: any;
  setValue?: any;
  handler?: any;
  minValue?: number;
  step?: any;
  name?: string;
  controlHandler?: any;
  hideCtrl?: boolean;
  register?: any;
  errors?: any;
};

const InputWithControls = ({
  value,
  setValue,
  handler,
  minValue,
  step,
  name,
  controlHandler,
  hideCtrl,
  register,
  errors,
}: Props) => {
  const handleControlClick = (action) => {
    if (minValue) {
      if (action >= minValue) {
        setValue(action);
      }
    } else {
      setValue(action);
    }
  };

  const buttonWrapper = () =>
    classnames({
      [classes.buttonWrapper]: true,
      [classes.inputControl]: hideCtrl,
    });

  const inputClass = () =>
    classnames({
      [classesCommon.input]: true,
      [classes.input]: true,
      [classesCommon.inputError]: errors,
    });

  return (
    <div className={classes.wrapper}>
      <div className={buttonWrapper()}>
        <ButtonBorderGradient
          Icon={MinusIcon}
          setState={() => {
            if (controlHandler) {
              controlHandler('decrease', name, step);
            } else {
              handleControlClick(value - step);
            }
          }}
        />
      </div>
      <div className={classesCommon.wrapper}>
        <input
          className={inputClass()}
          // name={name}
          value={value}
          min={minValue}
          type="text"
          inputMode="decimal"
          placeholder="0"
          onChange={handler}
          {...register}
        />
        {errors && <p className={classes.error}>{errors}</p>}
      </div>
      <div className={buttonWrapper()}>
        <ButtonBorderGradient
          Icon={PlusIcon}
          setState={() => {
            if (controlHandler) {
              controlHandler('increase', name, step);
            } else {
              handleControlClick(value + step);
            }
          }}
        />
      </div>
    </div>
  );
};

export default InputWithControls;
