// @ts-ignore
import React from 'react';
import { Helmet } from 'react-helmet';
// @ts-ignore
import PageWrapper from 'components/pageWrapper';
import Content from './content';
// @ts-ignore
import classes from './styles.module.scss';

const SwapHistory = () => {
  return (
    <>
      <Helmet>
        <title>Swap History</title>
      </Helmet>
      <PageWrapper heading="Swap History">
        <div className={classes.wrapper}>
          <Content />
        </div>
      </PageWrapper>
    </>
  );
};

export default SwapHistory;
