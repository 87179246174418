import React, { useState } from 'react';
import classNames from 'classnames';
import { ReactComponent as CaretRight } from 'assets/images/caret-right-icon.svg';
import { ReactComponent as CaretDown } from 'assets/images/caret-down-icon.svg';
import classes from './styles.module.scss';

const AccordionItem = ({ heading, text }) => {
  const [isActive, setIsActive] = useState(false);

  const handleToggleVisibility = () => setIsActive(!isActive);

  const buttonClasses = () =>
    classNames({
      [classes.button]: true,
      [classes.buttonActive]: isActive,
    });

  const textClasses = () =>
    classNames({
      [classes.content]: true,
      [classes.contentActive]: isActive,
    });

  return (
    <div>
      <button type="button" className={buttonClasses()} onClick={handleToggleVisibility}>
        <div className={classes.headingWrapper}>{heading}</div>
        <div>{isActive ? <CaretDown /> : <CaretRight />}</div>
      </button>
      <p className={textClasses()}>{text}</p>
    </div>
  );
};

export default AccordionItem;
