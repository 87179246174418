// @ts-nocheck

import { ContractFactory } from '@ethersproject/contracts';
import { ReactComponent as CoinIcon } from 'assets/images/coin-horizontal-icon.svg';
import BtnGradient from 'components/btnGradient';
import InputHeadingWrapper from 'components/inputHeadingWrapper';
import InputWithControls from 'components/inputWithControls';
import ModalWrapper from 'components/modalWrapper';
import InputNumWithHeading from 'components/ui/input/inputNumWithHeading';
import LiquidityGeneratorTokenABI from 'constants/abis/LiquidityGeneratorToken/LiquidityGeneratorTokenABI.json';
import LiquidityGeneratorTokenData from 'constants/abis/LiquidityGeneratorToken/LiquidityGeneratorTokenData';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import convertToInternationalCurrencySystem from 'utils/convertToInternationalCurrencySystem';
import { useSwitchNetwork } from 'wagmi';
import Web3 from 'web3';
import { ACTIVE_NETWORK, AdminOwner, explorerURL, pancakeRouter } from '../../../../../../constants';
import { useActiveWeb3React } from '../../../../../../hooks';
import { useProvider } from '../../../../../../hooks/v2/ethers-adapters';
import {
  addressValidatorCreator,
  nameValidatorCreator,
  percentValidatorCreator,
  supplyValidatorCreator,
  symbolValidatorCreator,
} from '../../../../../../utils/validator';
import InputTextWithHeading from '../../inputTextWithHeading';
import TotalNum from '../../totalNum';
import classes from './styles.module.scss';

const GeneratorContent = () => {
  const tockenCreatorFee = 0.00001;
  const { account, chainId } = useActiveWeb3React();
  const { switchNetwork } = useSwitchNetwork();
  const [isSubmit, setIsSubmit] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [charityAddresssErr, setcharityAddresssErr] = useState(true);
  const [formData, setFormData] = useState({
    TokenType: 'Reflection',
    types: 'Liquidity Generator Token',
    name: '',
    symbol: '',
    decimals: 18,
    totalSupply: '',
    taxFee: '',
    liquidityFee: '',
    burnFee: '',
    marketingFee: '',
    maxTransfer: '',
    numberOfTokens: '',
    charityFee: '',
    charityAddress: '',
    rewardTokens: '',
    marketingWallet: '',
    balanceForDividends: '',
    investmentToken: 'liquidityGeneratorToken',
  });
  const [modalState, setModalState] = useState(false);
  const [contractAddress, setContractAddress] = useState('');
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    trigger,
  } = useForm({
    mode: 'onChange',
  });
  const provider = useProvider();

  const _onInputChange = (e) => {
    const temp = { ...formData, [e.target.name]: e.target.value };
    setFormData(temp);
  };

  const _onInputChange1 = (e) => {
    const cursor = e.target?.selectionStart;
    const parsedValue = e.target.value.replace(/,/g, '');

    if (/^\d*\.?\d*$/.test(parsedValue)) {
      const temp = { ...formData, [e.target.name]: parsedValue };
      setFormData(temp);
    }

    e.target.selectionStart = cursor;
  };

  const handleInputControlClick = (action, dataName, step) => {
    const temp = {
      ...formData,
      [dataName]: action === 'increase' ? Number(formData[dataName]) + step : Number(formData[dataName]) - step,
    };
    setValue(dataName, action === 'increase' ? Number(formData[dataName]) + step : Number(formData[dataName]) - step);
    trigger(dataName);
    setFormData(temp);
  };

  const closeModal = () => {
    setFormData({
      TokenType: 'Reflection',
      types: 'Liquidity Generator Token',
      name: '',
      symbol: '',
      decimals: 18,
      totalSupply: '',
      taxFee: '',
      liquidityFee: '',
      burnFee: '',
      marketingFee: '',
      maxTransfer: '',
      numberOfTokens: '',
      charityFee: '',
      rewardTokens: '',
      marketingWallet: '',
      balanceForDividends: '',
      investmentToken: 'liquidityGeneratorToken',
    });
    setModalState(false);
  };

  const uploadContractHandler = async (accountValue, formDataValue, tockenCreatorFeeValue, cbValue) => {
    try {
      const web3 = (window.web3 = new Web3(provider));
      const nfttokenContract = new ContractFactory(
        LiquidityGeneratorTokenABI,
        LiquidityGeneratorTokenData,
        provider.getSigner(account)
      );
      const TaxFees = Number(formDataValue.taxFee) * 100;
      const LiquidityFees = Number(formDataValue.liquidityFee) * 100;
      const charityFees = Number(formDataValue.charityFee) * 100;
      const TotalSupply = Number(formDataValue.totalSupply) * 1000000000;
      const tockenCreatorFeeSD = web3.utils.toWei(tockenCreatorFeeValue.toString());
      console.log('formData++++', tockenCreatorFeeSD);

      const contract = await nfttokenContract.deploy(
        formDataValue.name.toString(),
        formDataValue.symbol.toString(),
        TotalSupply.toString(),
        pancakeRouter,
        formDataValue.charityAddress,
        TaxFees.toString(),
        LiquidityFees.toString(),
        charityFees.toString(), //charityFee
        AdminOwner,
        tockenCreatorFeeSD,
        {
          gasLimit: web3.utils.toHex('6540692'),
          value: web3.utils.toHex(tockenCreatorFeeSD),
        }
      );

      await contract.deployTransaction.wait();

      if (contract.address) {
        cbValue({
          status: true,
          address: contract.address,
        });
      }
    } catch (error) {
      console.error(error);
      toast.error(error.message);
      cbValue({
        status: false,
        address: null,
      });
    }
  };

  const submitHandler = async () => {
    if (ACTIVE_NETWORK == chainId) {
      setIsSubmit(true);
      try {
        if (formData.investmentToken == 'liquidityGeneratorToken') {
          if (
            charityAddresssErr &&
            formData.totalSupply !== '' &&
            formData.taxFee !== '' &&
            formData.liquidityFee !== '' &&
            formData.charityFee !== '' &&
            Number(formData.totalSupply) > 0 &&
            Number(formData.taxFee) > 0 &&
            Number(formData.liquidityFee) > 0 &&
            Number(formData.charityFee) > 0 &&
            formData.name !== '' &&
            formData.symbol !== '' &&
            formData.decimals !== '' &&
            Number(formData.decimals) > 1 &&
            Number(formData.decimals) < 19
          ) {
            console.log('DATA1213214', formData);
            setIsUpdating(true);
            await uploadContractHandler(account, formData, tockenCreatorFee, (result) => {
              if (result.status && result.address) {
                console.log(`${explorerURL}/address/${result.address}`);

                // history.push({
                //     pathname: "/app/view-token",
                //     search: result.address,
                //     state: formData,
                // });
                // toast.success("Token created successfully");
                setContractAddress(result.address);
                setModalState(true);
                toast.success('Token created successfully');
              } else {
              }
            });
            // } else {
            //   toast.error("Wrong combination");
          }
        }

        setIsUpdating(false);
      } catch (error) {
        setIsUpdating(false);
        console.log('ERROR', error);
      }
    } else {
      switchNetwork?.(ACTIVE_NETWORK);
    }
  };

  useEffect(() => {
    if (formData.charityAddress != '' && formData.charityAddress) {
      const web3 = (window.web3 = new Web3(provider));
      const ValidAddress = web3.utils.isAddress(formData.charityAddress);
      console.log(ValidAddress);
      setcharityAddresssErr(ValidAddress);
    }
  }, [formData.charityAddress]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.gridWrapper}>
        <InputTextWithHeading
          heading="Name"
          placeholder="enter a name"
          value={formData.name}
          errors={errors?.name?.message}
          register={{
            ...register('name', {
              validate: nameValidatorCreator,
              onChange: (e) => {
                setValue('name', e.target.value);
                _onInputChange(e);
              },
            }),
          }}
        />
        <InputTextWithHeading
          heading="Symbol"
          placeholder="enter a Symbol"
          value={formData.symbol}
          errors={errors?.symbol?.message}
          register={{
            ...register('symbol', {
              validate: symbolValidatorCreator,
              onChange: (e) => {
                setValue('symbol', e.target.value);
                _onInputChange(e);
              },
            }),
          }}
        />
        <div className={classes.input}>
          <InputNumWithHeading
            heading="Decimals"
            name="decimals"
            disabled
            value={formData.decimals}
            setValue={(e) => _onInputChange1(e)}
          />
        </div>
        <div className={classes.input}>
          <InputHeadingWrapper name="Total supply">
            <InputWithControls
              value={formData.totalSupply}
              name="totalSupply"
              controlHandler={handleInputControlClick}
              minValue={0}
              step={1000}
              errors={errors?.totalSupply?.message}
              register={{
                ...register('totalSupply', {
                  validate: supplyValidatorCreator,
                  onChange: (e) => {
                    setValue('totalSupply', e.target.value);
                    _onInputChange1(e);
                  },
                }),
              }}
            />
          </InputHeadingWrapper>
        </div>
        <div className={classes.input}>
          <InputHeadingWrapper name="Transaction fee to generate yield (%)">
            <InputWithControls
              value={formData.liquidityFee}
              name="liquidityFee"
              controlHandler={handleInputControlClick}
              minValue={0}
              step={1}
              errors={errors?.liquidityFee?.message}
              register={{
                ...register('liquidityFee', {
                  validate: percentValidatorCreator,
                  onChange: (e) => {
                    setValue('liquidityFee', e.target.value);
                    _onInputChange1(e);
                  },
                }),
              }}
            />
          </InputHeadingWrapper>
        </div>
        <div className={classes.input}>
          <InputHeadingWrapper name="Transaction fee to generate liquidity(%)">
            <InputWithControls
              value={formData.taxFee}
              name="taxFee"
              controlHandler={handleInputControlClick}
              minValue={0}
              step={1}
              errors={errors?.taxFee?.message}
              register={{
                ...register('taxFee', {
                  validate: percentValidatorCreator,
                  onChange: (e) => {
                    setValue('taxFee', e.target.value);
                    _onInputChange1(e);
                  },
                }),
              }}
            />
          </InputHeadingWrapper>
        </div>
        <div className={classes.input}>
          <InputHeadingWrapper name="Charity/Marketing percent (%)*">
            <InputWithControls
              value={formData.charityFee}
              name="charityFee"
              controlHandler={handleInputControlClick}
              minValue={0}
              step={1}
              errors={errors?.charityFee?.message}
              register={{
                ...register('charityFee', {
                  validate: percentValidatorCreator,
                  onChange: (e) => {
                    setValue('charityFee', e.target.value);
                    _onInputChange1(e);
                  },
                }),
              }}
            />
          </InputHeadingWrapper>
        </div>
        <div className={classes.input}>
          <InputTextWithHeading
            heading="Charity/Marketing address"
            placeholder="0x..."
            value={formData.charityAddress}
            errors={errors?.charityAddress?.message}
            register={{
              ...register('charityAddress', {
                validate: addressValidatorCreator,
                onChange: (e) => {
                  setValue('charityAddress', e.target.value);
                  _onInputChange(e);
                },
              }),
            }}
          />
        </div>
      </div>
      <div className={classes.checkoutBlock}>
        <div className={classes.totalWrapper}>
          <TotalNum label="Token creation cost" value={tockenCreatorFee} measure="QIE" />
        </div>
        <div className={classes.btnWrapper}>
          <BtnGradient
            text={isUpdating ? 'Creating...' : 'Create Token'}
            Icon={CoinIcon}
            disabled={
              Number(formData.taxFee) + Number(formData.charityFee) + Number(formData.liquidityFee) > 25 ||
              isUpdating ||
              !charityAddresssErr ||
              Number(formData.totalSupply) >= 1000000000000 ||
              formData.symbol.length > 32 ||
              formData.name.length > 32
            }
            func={submitHandler}
          />
        </div>
      </div>
      <ModalWrapper state={modalState} onClose={() => closeModal()} heading={`Token ${formData?.name}`}>
        <div className={classes.modalWrapper}>
          <div>
            <h3 className={classes.subheading}>
              {formData?.types} {formData?.TokenType}
            </h3>
          </div>
          <div className={classes.valueGrid}>
            <div className={classes.valueWrapper}>
              <h4 className={classes.valueHeading}>Name</h4>
              <p className={classes.value}>{formData?.name}</p>
            </div>
            <div className={classes.valueWrapper}>
              <h4 className={classes.valueHeading}>Symbol</h4>
              <p className={classes.value}>{formData?.symbol}</p>
            </div>
            <div className={classes.valueWrapper}>
              <h4 className={classes.valueHeading}>Decimals</h4>
              <p className={classes.value}>{formData?.decimals}</p>
            </div>
            <div className={classes.valueWrapper}>
              <h4 className={classes.valueHeading}>Total Supply</h4>
              <p className={classes.value}>{convertToInternationalCurrencySystem(formData?.totalSupply)}</p>
            </div>
            <div className={classes.valueWrapper}>
              <h4 className={classes.valueHeading}>Transaction fee to generate yield</h4>
              <p className={classes.value}>{formData.liquidityFee} %</p>
            </div>
            <div className={classes.valueWrapper}>
              <h4 className={classes.valueHeading}>Transaction fee to generate liquidity</h4>
              <p className={classes.value}>{formData.taxFee} %</p>
            </div>
            <div className={classes.valueWrapper}>
              <h4 className={classes.valueHeading}>Charity/Marketing percent</h4>
              <p className={classes.value}>{formData.charityFee} %</p>
            </div>
          </div>
          <div className={classes.valueContractWrapper}>
            <h4 className={classes.valueHeading}>Charity/Marketing address</h4>
            <p className={classes.valueContract}>{formData.charityAddress}</p>
          </div>
          <div className={classes.valueContractWrapper}>
            <h4 className={classes.valueHeading}>Contract Address</h4>
            <p className={classes.valueContract}>{contractAddress}</p>
          </div>
        </div>
      </ModalWrapper>
    </div>
  );
};

export default GeneratorContent;
