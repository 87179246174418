import { CurrencyAmount, currencyEquals, JSBI, Token, Trade, TradeType } from '@pancakeswap-libs/sdk';
// @ts-ignore
import { ReactComponent as RefreshIcon } from 'assets/images/refresh-icon.svg';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useAccount, useChainId } from 'wagmi';
import BtnGradient from '../../../../components/btnGradient';
import ModalWrapper from '../../../../components/modalWrapper';
import confirmPriceImpactWithoutFee from '../../../../components/swap/confirmPriceImpactWithoutFee';
import { BETTER_TRADE_LINK_THRESHOLD, INITIAL_ALLOWED_SLIPPAGE, ONE_BIPS } from '../../../../constants';
import { usePair } from '../../../../data/Reserves';
import { isTradeBetter } from '../../../../data/V1';
import { useAllTokens, useCurrency } from '../../../../hooks/Tokens';
import { ApprovalState, useApproveCallbackFromTrade } from '../../../../hooks/useApproveCallback';
import { useSwapCallback } from '../../../../hooks/useSwapCallback';
import useToggledVersion, { Version } from '../../../../hooks/useToggledVersion';
import useWrapCallback, { WrapType } from '../../../../hooks/useWrapCallback';
import swapInfoApi from '../../../../services/endpoints';
import { Field } from '../../../../state/swap/actions';
import {
  useDefaultsFromURLSearch,
  useDerivedSwapInfo,
  useSwapActionHandlers,
  useSwapState,
} from '../../../../state/swap/hooks';
import {
  useExpertModeManager,
  usePairAdder,
  useUserDeadline,
  useUserSlippageTolerance,
} from '../../../../state/user/hooks';
import { useCurrencyBalance } from '../../../../state/wallet/hooks';
import formatValue from '../../../../utils/formatNumber';
import { maxAmountSpend } from '../../../../utils/maxAmountSpend';
import {
  computeSlippageAdjustedAmounts,
  computeTradePriceBreakdown,
  formatExecutionPrice,
  warningSeverity,
} from '../../../../utils/prices';
import ContentLoggedIn from './contentLoggedIn';
import CheckoutBlockXs from './contentLoggedIn/checkoutBlockXs';
import ContentLoggedOut from './contentLoggedOut';
// @ts-ignore
import classes from './styles.module.scss';

const SwapContent = () => {
  const [errorInput, setErrorInput] = useState('');
  const [errorOutput, setErrorOutput] = useState('');
  const [inputTokenInfo, setInputTokenInfo] = useState({});
  const [outputTokenInfo, setOutputTokenInfo] = useState({});

  const { address: account } = useAccount();
  const chainId = useChainId();

  const loadedUrlParams = useDefaultsFromURLSearch();
  const allTokens = useAllTokens();

  // token warning stuff
  const [loadedInputCurrency, loadedOutputCurrency] = [
    useCurrency(loadedUrlParams?.inputCurrencyId),
    useCurrency(loadedUrlParams?.outputCurrencyId),
  ];
  const [dismissTokenWarning, setDismissTokenWarning] = useState<boolean>(false);
  const [isSyrup, setIsSyrup] = useState<boolean>(false);
  const [syrupTransactionType, setSyrupTransactionType] = useState<string>('');
  const urlLoadedTokens: Token[] = useMemo(
    () => [loadedInputCurrency, loadedOutputCurrency]?.filter((c): c is Token => c instanceof Token) ?? [],
    [loadedInputCurrency, loadedOutputCurrency]
  );
  const handleConfirmTokenWarning = useCallback(() => {
    setDismissTokenWarning(true);
  }, []);

  const handleConfirmSyrupWarning = useCallback(() => {
    setIsSyrup(false);
    setSyrupTransactionType('');
  }, []);

  const [isExpertMode] = useExpertModeManager();

  // get custom setting values for user
  const [deadline] = useUserDeadline();
  const [allowedSlippage] = useUserSlippageTolerance();

  // swap state
  const { independentField, typedValue, recipient } = useSwapState();
  const {
    v1Trade,
    v2Trade,
    currencyBalances,
    parsedAmount,
    currencies,
    inputError: swapInputError,
  } = useDerivedSwapInfo();
  const { wrapType, execute: onWrap, inputError: wrapInputError } = useWrapCallback(
    currencies[Field.INPUT],
    currencies[Field.OUTPUT],
    typedValue
  );
  const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE;
  //   const { address: recipientAddress } = useENSAddress(recipient)
  const toggledVersion = useToggledVersion();
  const trade = showWrap
    ? undefined
    : {
        [Version.v1]: v1Trade,
        [Version.v2]: v2Trade,
      }[toggledVersion];

  const betterTradeLinkVersion: Version | undefined =
    toggledVersion === Version.v2 && isTradeBetter(v2Trade, v1Trade, BETTER_TRADE_LINK_THRESHOLD)
      ? Version.v1
      : toggledVersion === Version.v1 && isTradeBetter(v1Trade, v2Trade)
      ? Version.v2
      : undefined;

  const parsedAmounts = showWrap
    ? {
        [Field.INPUT]: parsedAmount,
        [Field.OUTPUT]: parsedAmount,
      }
    : {
        [Field.INPUT]: independentField === Field.INPUT ? parsedAmount : trade?.inputAmount,
        [Field.OUTPUT]: independentField === Field.OUTPUT ? parsedAmount : trade?.outputAmount,
      };

  const { onSwitchTokens, onCurrencySelection, onUserInput, onChangeRecipient } = useSwapActionHandlers();
  const isValid = !swapInputError;
  const dependentField: Field = independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT;
  const [pairState, pair] = usePair(currencies[Field.INPUT] ?? undefined, currencies[Field.OUTPUT] ?? undefined);
  const addPair = usePairAdder();

  // modal and loading
  const [{ showConfirm, tradeToConfirm, swapErrorMessage, attemptingTxn, txHash }, setSwapState] = useState<{
    showConfirm: boolean;
    tradeToConfirm: Trade | undefined;
    attemptingTxn: boolean;
    swapErrorMessage: string | undefined;
    txHash: string | undefined;
  }>({
    showConfirm: false,
    tradeToConfirm: undefined,
    attemptingTxn: false,
    swapErrorMessage: undefined,
    txHash: undefined,
  });

  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: showWrap
      ? parsedAmounts[independentField]?.toExact() ?? ''
      : parsedAmounts[dependentField]?.toSignificant(6) ?? '',
  };

  const route = trade?.route;
  const userHasSpecifiedInputOutput = Boolean(
    currencies[Field.INPUT] && currencies[Field.OUTPUT] && parsedAmounts[independentField]?.greaterThan(JSBI.BigInt(0))
  );
  const noRoute = !route;

  // check whether the user has approved the router on the input token
  const [approval, approveCallback] = useApproveCallbackFromTrade(trade, allowedSlippage);

  // check if user has gone through approval process, used to show two step buttons, reset on token change
  const [approvalSubmitted, setApprovalSubmitted] = useState<boolean>(false);

  // mark when a user has submitted an approval, reset onTokenSelection for input field
  useEffect(() => {
    if (approval === ApprovalState.PENDING) {
      setApprovalSubmitted(true);
    }
  }, [approval, approvalSubmitted]);

  const maxAmountInput: CurrencyAmount | undefined = maxAmountSpend(currencyBalances[Field.INPUT]);
  const atMaxAmountInput = Boolean(maxAmountInput && parsedAmounts[Field.INPUT]?.equalTo(maxAmountInput));

  // the callback to execute the swap
  const { callback: swapCallback, error: swapCallbackError } = useSwapCallback(
    trade,
    allowedSlippage,
    deadline,
    recipient
  );

  const { priceImpactWithoutFee, realizedLPFee } = computeTradePriceBreakdown(trade);

  const handleSwap = useCallback(() => {
    if (priceImpactWithoutFee && !confirmPriceImpactWithoutFee(priceImpactWithoutFee)) {
      return;
    }
    if (!swapCallback) {
      return;
    }
    setSwapState((prevState) => ({
      ...prevState,
      attemptingTxn: true,
      swapErrorMessage: undefined,
      txHash: undefined,
    }));
    swapCallback()
      .then((hash) => {
        setSwapState((prevState) => ({
          ...prevState,
          attemptingTxn: false,
          swapErrorMessage: undefined,
          txHash: hash,
        }));
      })
      .catch((error) => {
        setSwapState((prevState) => ({
          ...prevState,
          attemptingTxn: false,
          swapErrorMessage: error.message,
          txHash: undefined,
        }));
      });
  }, [priceImpactWithoutFee, swapCallback, setSwapState]);

  // errors
  const [showInverted, setShowInverted] = useState<boolean>(false);
  const [tokenInverted, setTokenInverted] = useState<boolean>(false);

  // warnings on slippage
  const priceImpactSeverity = warningSeverity(priceImpactWithoutFee);

  // show approve flow when: no error on inputs, not approved or pending, or approved in current session
  // never show if price impact is above threshold in non expert mode
  const showApproveFlow =
    !swapInputError &&
    (approval === ApprovalState.NOT_APPROVED ||
      approval === ApprovalState.PENDING ||
      (approvalSubmitted && approval === ApprovalState.APPROVED)) &&
    !(priceImpactSeverity > 3 && !isExpertMode);

  const handleConfirmDismiss = useCallback(() => {
    setSwapState((prevState) => ({ ...prevState, showConfirm: false }));

    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onUserInput(Field.INPUT, '');
    }
  }, [onUserInput, txHash, setSwapState]);

  const handleAcceptChanges = useCallback(() => {
    setSwapState((prevState) => ({ ...prevState, tradeToConfirm: trade }));
  }, [trade]);

  // This will check to see if the user has selected Syrup to either buy or sell.
  // If so, they will be alerted with a warning message.
  const checkForSyrup = useCallback(
    (selected: string, purchaseType: string) => {
      if (selected === 'syrup') {
        setIsSyrup(true);
        setSyrupTransactionType(purchaseType);
      }
    },
    [setIsSyrup, setSyrupTransactionType]
  );

  const handleInputSelect = useCallback(
    (inputCurrency) => {
      setApprovalSubmitted(false); // reset 2 step UI for approvals
      onCurrencySelection(Field.INPUT, inputCurrency);
      if (inputCurrency.symbol.toLowerCase() === 'syrup') {
        checkForSyrup(inputCurrency.symbol.toLowerCase(), 'Selling');
      }
    },
    [onCurrencySelection, setApprovalSubmitted, checkForSyrup]
  );

  const handleMaxInput = useCallback(() => {
    if (maxAmountInput) {
      onUserInput(Field.INPUT, maxAmountInput.toExact());
    }
  }, [maxAmountInput, onUserInput]);

  const handleOutputSelect = useCallback(
    (outputCurrency) => {
      onCurrencySelection(Field.OUTPUT, outputCurrency);
      if (outputCurrency.symbol.toLowerCase() === 'syrup') {
        checkForSyrup(outputCurrency.symbol.toLowerCase(), 'Buying');
      }
    },
    [onCurrencySelection, checkForSyrup]
  );

  const slippageAdjustedAmounts = useMemo(() => computeSlippageAdjustedAmounts(trade, allowedSlippage), [
    trade,
    allowedSlippage,
  ]);

  const severity = warningSeverity(priceImpactWithoutFee);

  function tradeMeaningfullyDiffers(tradeA: Trade, tradeB: Trade): boolean {
    return (
      tradeA.tradeType !== tradeB.tradeType ||
      !currencyEquals(tradeA.inputAmount.currency, tradeB.inputAmount.currency) ||
      !tradeA.inputAmount.equalTo(tradeB.inputAmount) ||
      !currencyEquals(tradeA.outputAmount.currency, tradeB.outputAmount.currency) ||
      !tradeA.outputAmount.equalTo(tradeB.outputAmount)
    );
  }

  const showAcceptChanges = useMemo(
    () => Boolean(trade && tradeToConfirm && tradeMeaningfullyDiffers(trade, tradeToConfirm)),
    [tradeToConfirm, trade]
  );
  const selectedCurrencyBalanceInput = useCurrencyBalance(account ?? undefined, currencies[Field.INPUT] ?? undefined);
  const selectedCurrencyBalanceOutput = useCurrencyBalance(account ?? undefined, currencies[Field.OUTPUT] ?? undefined);

  useEffect(() => {
    handleOutputSelect(Object.values(allTokens)[0]);
  }, [allTokens, handleOutputSelect]);

  useEffect(() => {
    if (pair) {
      addPair(pair);
    }
  }, [currencies]); // eslint-disable-line

  const handleTypeInput = useCallback(
    (e) => {
      if (e?.target) {
        const cursor = e.target?.selectionStart;
        const parsedValue = e.target.value.replace(/,/g, '');

        if (/^\d*\.?\d*$/.test(parsedValue)) {
          onUserInput(Field.INPUT, parsedValue);
        }

        if (selectedCurrencyBalanceInput) {
          if (Number(parsedValue) === 0) {
            setErrorInput('Enter correct amount');
          } else if (parsedValue > selectedCurrencyBalanceInput?.toSignificant(6)) {
            setErrorInput('Enter correct amount');
          } else {
            setErrorInput('');
          }
        }

        e.target.selectionStart = cursor;
      } else {
        onUserInput(Field.INPUT, e);
      }
    },
    [onUserInput, selectedCurrencyBalanceInput] // eslint-disable-line
  );

  useEffect(() => handleTypeInput('1'), []); // eslint-disable-line

  const handleTypeOutput = useCallback(
    (e) => {
      const cursor = e.target?.selectionStart;
      const parsedValue = e.target.value.replace(/,/g, '');

      if (/^\d*\.?\d*$/.test(parsedValue)) {
        onUserInput(Field.OUTPUT, parsedValue);
      }

      if (selectedCurrencyBalanceInput) {
        if (parsedValue === 0) {
          setErrorOutput('Enter correct amount');
        } else if (Number(formattedAmounts[Field.INPUT]) > Number(selectedCurrencyBalanceInput?.toSignificant(6))) {
          setErrorOutput('Enter correct amount');
        } else {
          setErrorOutput('');
        }
      }

      e.target.selectionStart = cursor;
    },
    [onUserInput, selectedCurrencyBalanceInput, formattedAmounts[Field.INPUT]] // eslint-disable-line
  );

  useEffect(() => {
    if (Number(formattedAmounts[Field.INPUT]) > Number(selectedCurrencyBalanceInput?.toSignificant(6))) {
      setErrorOutput('Enter correct amount');
    } else {
      setErrorOutput('');
    }
  }, [formattedAmounts[Field.INPUT]]); // eslint-disable-line

  const getTokenInfo = async () => {
    try {
      if (currencies[Field.INPUT]?.symbol === 'QIE') {
        const resInput = await swapInfoApi.getTokenQIInfo();
        const resOutput = await swapInfoApi.getTokenPairInfo([currencies[Field.OUTPUT]?.address]);
        setInputTokenInfo(resInput.data[0]);
        setOutputTokenInfo(resOutput.data[0]);
      } else if (currencies[Field.OUTPUT]?.symbol === 'QIE') {
        const resInput = await swapInfoApi.getTokenPairInfo([currencies[Field.INPUT]?.address]);
        const resOutput = await swapInfoApi.getTokenQIInfo();
        setInputTokenInfo(resInput.data[0]);
        setOutputTokenInfo(resOutput.data[0]);
      } else {
        const res = await swapInfoApi.getTokenPairInfo([
          currencies[Field.INPUT]?.address,
          currencies[Field.OUTPUT]?.address,
        ]);
        setInputTokenInfo(res.data[0]);
        setOutputTokenInfo(res.data[1]);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getTokenInfo();
  }, [currencies[Field.INPUT], currencies[Field.OUTPUT]]); // eslint-disable-line

  return (
    <>
      {account ? (
        <ContentLoggedIn
          inputValue={formattedAmounts[Field.INPUT]}
          outputValue={formattedAmounts[Field.OUTPUT]}
          inputHandler={(e) => handleTypeInput(e)}
          outputHandler={(e) => handleTypeOutput(e)}
          inputSelectHandler={handleInputSelect}
          outputSelectHandler={handleOutputSelect}
          selectInputValue={currencies[Field.INPUT]}
          selectOutputValue={currencies[Field.OUTPUT]}
          priceInverted={showInverted}
          setPriceInverted={() => setShowInverted(!showInverted)}
          trade={trade}
          isPriceVisible={!showWrap && Boolean(trade)}
          isTolleranceVisible={!showWrap && allowedSlippage !== INITIAL_ALLOWED_SLIPPAGE}
          tolleranceValue={allowedSlippage}
          switchToken={() => {
            setApprovalSubmitted(false); // reset 2 step UI for approvals
            onSwitchTokens();
            setShowInverted(false);
            setTokenInverted(!tokenInverted);
          }}
          handleMaxInput={handleMaxInput}
          balanceInput={
            !!currencies[Field.INPUT] && selectedCurrencyBalanceInput
              ? `Balance: ${selectedCurrencyBalanceInput?.toSignificant(6)}`
              : ' -'
          }
          balanceOutput={
            !!currencies[Field.OUTPUT] && selectedCurrencyBalanceOutput
              ? `Balance: ${selectedCurrencyBalanceOutput?.toSignificant(6)}`
              : ' -'
          }
          pairAddress={pair?.liquidityToken?.address}
          errorInput={errorInput}
          errorOutput={errorOutput}
          tokenInverted={tokenInverted}
          inputTokenInfo={inputTokenInfo}
          outputTokenInfo={outputTokenInfo}
        >
          {showWrap ? (
            <BtnGradient
              text={
                wrapInputError ?? (wrapType === WrapType.WRAP ? 'Wrap' : wrapType === WrapType.UNWRAP ? 'Unwrap' : null)
              }
              func={onWrap}
              disabled={Boolean(wrapInputError)}
            />
          ) : noRoute && userHasSpecifiedInputOutput ? (
            <BtnGradient text="Insufficient liquidity for this trade" disabled />
          ) : showApproveFlow ? (
            approvalSubmitted && approval === ApprovalState.APPROVED ? (
              <BtnGradient
                func={() => {
                  if (isExpertMode) {
                    handleSwap();
                  } else {
                    setSwapState({
                      tradeToConfirm: trade,
                      attemptingTxn: false,
                      swapErrorMessage: undefined,
                      showConfirm: true,
                      txHash: undefined,
                    });
                  }
                }}
                disabled={
                  !isValid ||
                  (priceImpactSeverity > 3 && !isExpertMode) ||
                  !!swapCallbackError ||
                  Number(inputTokenInfo?.status) === 3 ||
                  Number(outputTokenInfo?.status) === 3
                }
                text={
                  ((Number(inputTokenInfo?.status) === 3 || Number(outputTokenInfo?.status) === 3) &&
                    'Swap not allowed') ||
                  swapInputError ||
                  (priceImpactSeverity > 3 && !isExpertMode
                    ? `Price Impact Too High`
                    : `Swap${priceImpactSeverity > 2 ? ' Anyway' : ''}`)
                }
              />
            ) : (
              <div className={classes.button}>
                <BtnGradient
                  text={
                    approval === ApprovalState.PENDING
                      ? 'Approving'
                      : approvalSubmitted && approval === ApprovalState.APPROVED
                      ? 'Approved'
                      : `Approve`
                  }
                  func={approveCallback}
                  disabled={approval !== ApprovalState.NOT_APPROVED || approvalSubmitted}
                />
              </div>
            )
          ) : (
            <BtnGradient
              func={() => {
                if (isExpertMode) {
                  handleSwap();
                } else {
                  setSwapState({
                    tradeToConfirm: trade,
                    attemptingTxn: false,
                    swapErrorMessage: undefined,
                    showConfirm: true,
                    txHash: undefined,
                  });
                }
              }}
              disabled={
                !isValid ||
                (priceImpactSeverity > 3 && !isExpertMode) ||
                !!swapCallbackError ||
                Number(inputTokenInfo?.status) === 3 ||
                Number(outputTokenInfo?.status) === 3
              }
              text={
                ((Number(inputTokenInfo?.status) === 3 || Number(outputTokenInfo?.status) === 3) &&
                  'Swap not allowed') ||
                swapInputError ||
                (priceImpactSeverity > 3 && !isExpertMode
                  ? `Price Impact Too High`
                  : `Swap${priceImpactSeverity > 2 ? ' Anyway' : ''}`)
              }
            />
          )}
          {attemptingTxn ? (
            <ModalWrapper state={showConfirm} onClose={() => handleConfirmDismiss()} heading="Waiting for confirmation">
              <div className={classes.modalWrapper}>
                <p className={classes.headingText}>{`Swapping ${formatValue(trade?.inputAmount?.toSignificant(6))} ${
                  trade?.inputAmount?.currency?.symbol
                } for ${formatValue(trade?.outputAmount?.toSignificant(6))} ${
                  trade?.outputAmount?.currency?.symbol
                }`}</p>
                <p className={classes.headingText}>Confirm this transaction in your wallet</p>
              </div>
            </ModalWrapper>
          ) : txHash ? (
            <></>
          ) : (
            // <ModalWrapper
            //     state={showConfirm}
            //     onClose={() => handleConfirmDismiss()}
            //     heading='Transaction submitted'
            // >
            //     <div className={classes.modalWrapper}>
            //         <a className={classes.modalLink} href={getEtherscanLink(chainId, txHash, 'transaction')} target='_blank' rel='noreferrer'>View on QIcscan</a>
            //     </div>
            // </ModalWrapper>
            <ModalWrapper state={showConfirm} onClose={() => handleConfirmDismiss()} heading="Confirm Swap">
              <div className={classes.wrapperModal}>
                <div className={classes.contentWrapperModal}>
                  <div className={classes.valueWrapper}>
                    <h3 className={classes.headingText}>{trade?.inputAmount.currency.symbol}</h3>
                    <p className={classes.valueModal}>{formatValue(trade?.inputAmount.toSignificant(6))}</p>
                  </div>
                  <div className={classes.valueWrapper}>
                    <h3 className={classes.headingText}>{trade?.outputAmount.currency.symbol}</h3>
                    <p className={classes.valueModal}>{formatValue(trade?.outputAmount.toSignificant(6))}</p>
                  </div>
                </div>
                {trade?.tradeType === TradeType.EXACT_INPUT ? (
                  <p className={classes.value}>
                    <i>
                      Output is estimated. You will receive at least{' '}
                      {formatValue(slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(6))}{' '}
                      {trade.outputAmount.currency.symbol}or the transaction will revert.
                    </i>
                  </p>
                ) : (
                  <p className={classes.value}>
                    <i>
                      Input is estimated. You will sell at most{' '}
                      {formatValue(slippageAdjustedAmounts[Field.INPUT]?.toSignificant(6))}{' '}
                      {trade?.inputAmount.currency.symbol}or the transaction will revert.
                    </i>
                  </p>
                )}
                <div className={classes.contentWrapperModal}>
                  <div className={classes.valueWrapper}>
                    <h4 className={classes.headingText}>Price</h4>
                    <div className={classes.valueBtn}>
                      <p className={classes.value}>{formatValue(formatExecutionPrice(trade, showInverted))}</p>
                      <button type="button" onClick={() => setShowInverted(!showInverted)}>
                        <RefreshIcon />
                      </button>
                    </div>
                  </div>
                  <CheckoutBlockXs
                    heading={trade?.tradeType === TradeType.EXACT_INPUT ? 'Minimum received' : 'Maximum sold'}
                    tooltipText="Your transaction will revert if there is a large, unfavorable price movement before it is confirmed."
                    value={
                      trade?.tradeType === TradeType.EXACT_INPUT
                        ? `${formatValue(slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(4))} ${
                            trade.outputAmount.currency.symbol
                          }` ?? '-'
                        : `${formatValue(slippageAdjustedAmounts[Field.INPUT]?.toSignificant(4))} ${
                            trade?.inputAmount.currency.symbol
                          }` ?? '-'
                    }
                  />
                  <CheckoutBlockXs
                    heading="Price Impact"
                    tooltipText="The difference between the market price and estimated price due to trade size."
                    value={
                      trade
                        ? priceImpactWithoutFee
                          ? priceImpactWithoutFee.lessThan(ONE_BIPS)
                            ? '<0.01%'
                            : `${priceImpactWithoutFee.toFixed(2)}%`
                          : '-'
                        : '-'
                    }
                  />
                  <CheckoutBlockXs
                    heading="Liquidity Provider Fee"
                    tooltipText="For each trade a 0.3% fee is paid. 0.25% goes to liquidity providers and 0.05% goes to the Qidex treasury."
                    value={
                      trade
                        ? realizedLPFee
                          ? `${formatValue(realizedLPFee.toSignificant(4))} ${trade.inputAmount.currency.symbol}`
                          : '-'
                        : '-'
                    }
                  />
                </div>
                <div className={classes.buttonModal}>
                  <BtnGradient
                    text={severity > 2 ? 'Swap Anyway' : 'Confirm Swap'}
                    disabled={showAcceptChanges}
                    func={() => {
                      handleSwap();
                    }}
                  />
                </div>
              </div>
            </ModalWrapper>
          )}
        </ContentLoggedIn>
      ) : (
        <ContentLoggedOut
          inputValue={formattedAmounts[Field.INPUT]}
          outputValue={formattedAmounts[Field.OUTPUT]}
          inputHandler={(e) => handleTypeInput(e)}
          outputHandler={(e) => handleTypeOutput(e)}
          inputSelectHandler={handleInputSelect}
          outputSelectHandler={handleOutputSelect}
          selectInputValue={currencies[Field.INPUT]}
          selectOutputValue={currencies[Field.OUTPUT]}
        />
      )}
    </>
  );
};

export default SwapContent;
