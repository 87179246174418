const config = [
  {
    id: 0,
    dateOption: '1D',
  },
  {
    id: 1,
    dateOption: '7D',
  },
  {
    id: 2,
    dateOption: '1M',
  },
  {
    id: 3,
    dateOption: '3M',
  },
  {
    id: 4,
    dateOption: '1Y',
  },
];

export default config;
