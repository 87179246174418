import BtnGradient from 'components/btnGradient';
import React from 'react';
import { useAccount } from 'wagmi';
import WalletConnect from '../../../../components/walletConnect';
import formatValue from '../../../../utils/formatNumber';
import CardItem from './cardItem';
import classes from './styles.module.scss';

const CardList = ({ stakedValue, stakedRewards, claimFunc, claimDisabled, claimLogoutFunc }) => {
  const { isConnected } = useAccount();

  return (
    <div className={classes.wrapper}>
      <CardItem label="Staked" value={stakedValue ? formatValue(stakedValue) : 0} add="QIE" />
      <div className={classes.cardWrapper}>
        <h3 className={classes.cardHeading}>Claim Reward</h3>
        <div className={classes.buttonWrapper}>
          {isConnected ? (
            <BtnGradient text={'Claim'} func={claimFunc} disabled={claimDisabled} />
          ) : (
            <WalletConnect icon={null} />
          )}
        </div>
      </div>
      <CardItem label="Your rewards" value={stakedRewards ? formatValue(stakedRewards) : 0} add="QIDEX" />
    </div>
  );
};

export default CardList;
