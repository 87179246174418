// @ts-nocheck

import React from 'react';
import Select from 'react-select';

const SelectInput = ({ options, name, selectHandler }) => {
  return (
    <Select
      options={options}
      isClearable
      placeholder="Select"
      name={name}
      className="react-select-container"
      classNamePrefix="react-select"
      onChange={(e) => selectHandler(e, name)}
    />
  );
};

export default SelectInput;
