import React, { useState } from 'react';
import Modal from 'react-modal';
import BtnGradient from '../../../../components/btnGradient';
import classes from './styles.module.scss';

export default function InformationModal() {
  const storageKey = 'token-creation-modal';
  const storageValue = 'approved';
  const isDirty = localStorage.getItem(storageKey);
  const [isOpen, setIsOpen] = useState(isDirty !== storageValue);

  function handleClose() {
    localStorage.setItem(storageKey, storageValue);
    setIsOpen(false);
  }

  return (
    <Modal
      className={classes.modalWrapper}
      isOpen={isOpen}
      onRequestClose={handleClose}
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
      overlayClassName={classes.overlayCustom}
    >
      <div className={classes.wrapper}>
        <div className={classes.headingWrapper}>
          <h3 className={classes.heading}>Warning</h3>
        </div>
        <div className={classes.content}>
          <div className={classes.message}>
            <h5 className={classes.message_title}>Token creation is only possible through MetaMask.</h5>
            <p>
              If you have any questions or need assistance, please don't hesitate to contact us through our{' '}
              <a className={classes.message_link} href="https://t.me/HovRonQiblockchain" target="_blank">
                Telegram chat.
              </a>{' '}
              We are here to help you every step of the way.
            </p>
          </div>
          <div className={classes.action}>
            <BtnGradient text="Got it!" func={handleClose} />
          </div>
        </div>
      </div>
    </Modal>
  );
}
