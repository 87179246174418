import { Contract } from '@ethersproject/contracts';
import { ChainId, WETH } from '@pancakeswap-libs/sdk';
import abi from '@uniswap/v2-core/build/IUniswapV2Pair.json';
import { useMemo } from 'react';
import { useChainId } from 'wagmi';
import DexRouterABI from '../constants/abis/DexRouterABI.json';
import ENS_PUBLIC_RESOLVER_ABI from '../constants/abis/ens-public-resolver.json';
import ENS_ABI from '../constants/abis/ens-registrar.json';
import { ERC20_BYTES32_ABI } from '../constants/abis/erc20';
import ERC20_ABI from '../constants/abis/erc20.json';
import { MIGRATOR_ABI, MIGRATOR_ADDRESS } from '../constants/abis/migrator';
import UNISOCKS_ABI from '../constants/abis/unisocks.json';
import WETH_ABI from '../constants/abis/weth.json';
import { MULTICALL_ABI, MULTICALL_NETWORKS } from '../constants/multicall';
import { V1_EXCHANGE_ABI, V1_FACTORY_ABI, V1_FACTORY_ADDRESSES } from '../constants/v1';
import { getContractV2 } from '../utils/v2/getContracts';
import { useEthersSigner } from './v2/ethers-adapters';

const IUniswapV2PairABI = abi.abi;

// returns null on errors
// todo: remove withSignerIfPossible
function useContract(address: string | undefined, ABI: any, withSignerIfPossible?: boolean): Contract | null {
  const signer = useEthersSigner();

  return useMemo(() => {
    if (!address || !ABI || !signer) return null;
    try {
      return getContractV2(address, ABI, signer);
    } catch (error) {
      console.error('Failed to get contract', error);
      return null;
    }
  }, [address, ABI, signer]);
}

export function useV1FactoryContract(): Contract | null {
  const chainId = useChainId();
  return useContract(chainId && V1_FACTORY_ADDRESSES[chainId], V1_FACTORY_ABI);
}

export function useV2MigratorContract(): Contract | null {
  return useContract(MIGRATOR_ADDRESS, MIGRATOR_ABI);
}

export function useV1ExchangeContract(address?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(address, V1_EXCHANGE_ABI);
}

// todo: remove withSignerIfPossible
export function useTokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  // todo: replace v1
  // return useContractV2(tokenAddress, ERC20_ABI);
  return useContract(tokenAddress, ERC20_ABI);
}

export function useStakingContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  // return useContractV2(tokenAddress, DexRouterABI);
  // todo: remove
  return useContract(tokenAddress, DexRouterABI);
}

export function useWETHContract(withSignerIfPossible?: boolean): Contract | null {
  const chainId = useChainId();
  return useContract(chainId ? WETH[chainId].address : undefined, WETH_ABI);
}

export function useENSRegistrarContract(withSignerIfPossible?: boolean): Contract | null {
  const chainId = useChainId();
  let address: string | undefined;
  if (chainId) {
    switch (chainId) {
      case ChainId.MAINNET:
      case ChainId.BSCTESTNET:
    }
  }
  return useContract(address, ENS_ABI);
}

export function useENSResolverContract(address: string | undefined, withSignerIfPossible?: boolean): Contract | null {
  return useContract(address, ENS_PUBLIC_RESOLVER_ABI);
}

export function useBytes32TokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ERC20_BYTES32_ABI);
}

export function usePairContract(pairAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(pairAddress, IUniswapV2PairABI);
}

export function useMulticallContract(): Contract | null {
  const chainId = useChainId();
  return useContract(chainId && MULTICALL_NETWORKS[chainId], MULTICALL_ABI);
}

export function useSocksController(): Contract | null {
  const chainId = useChainId();
  return useContract(
    chainId === ChainId.MAINNET ? '0x65770b5283117639760beA3F867b69b3697a91dd' : undefined,
    UNISOCKS_ABI
  );
}
