import React from 'react';
import config from './config';
import NavItem from './navItem';
// @ts-ignore
import classes from './styles.module.scss';

const NavBlock = () => {
  return (
    <div className={classes.wrapper}>
      {config.map((item) => (
        <NavItem key={item.id} label={item.label} link={item.link} extLink={item.extLink} />
      ))}
    </div>
  );
};

export default NavBlock;
