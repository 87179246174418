import { configureStore, Middleware, getDefaultMiddleware } from '@reduxjs/toolkit';
import { save, load } from 'redux-localstorage-simple';
import { getThemeCache } from 'utils/theme';

import application from './application/reducer';
import { updateVersion } from './global/actions';
import user from './user/reducer';
import transactions from './transactions/reducer';
import swap from './swap/reducer';
import mint from './mint/reducer';
import lists from './lists/reducer';
import burn from './burn/reducer';
import multicall from './multicall/reducer';
import exchange from './exchange/slice';
import wallet from './wallet/slice';
import sideMenu from './sideMenu/slice';
import tokenCreator from './tokenCreator/slice';
import chartTimeSwitcher from './chartTimeSwitcher/slice';

type MergedState = {
  user: {
    [key: string]: any;
  };
  transactions: {
    [key: string]: any;
  };
};
const PERSISTED_KEYS: string[] = ['user', 'transactions'];
const loadedState = load({ states: PERSISTED_KEYS }) as MergedState;
if (loadedState.user) {
  loadedState.user.userDarkMode = getThemeCache();
}

const store = configureStore({
  reducer: {
    application,
    user,
    transactions,
    swap,
    mint,
    burn,
    multicall,
    lists,
    exchange,
    wallet,
    sideMenu,
    tokenCreator,
    chartTimeSwitcher,
  },
  middleware: [...getDefaultMiddleware({ thunk: false }), save({ states: PERSISTED_KEYS })] as Middleware[],
  // @ts-ignore
  preloadedState: loadedState,
});

store.dispatch(updateVersion());

export default store;

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
