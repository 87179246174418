import React from 'react';
import { ReactComponent as LogoIcon } from 'assets/images/logo-footer.svg';
import SocialBlock from './socialBlock';
import classes from './styles.module.scss';

const LogoBlock = () => {
  return (
    <div className={classes.wrapper}>
      <div className={classes.logoWrapper}>
        <div>
          <LogoIcon />
        </div>
        <div className={classes.text}>QIdex</div>
      </div>
      <SocialBlock />
    </div>
  );
};

export default LogoBlock;
