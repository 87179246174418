import React from 'react';
import classes from './styles.module.scss';

const PoolItem = ({ heading, value }) => {
  return (
    <div className={classes.wrapper}>
      <h6 className={classes.heading}>{heading}</h6>
      <p className={classes.value}>{value}</p>
    </div>
  );
};

export default PoolItem;
