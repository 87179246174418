import { AddressZero } from '@ethersproject/constants';
import { Contract } from '@ethersproject/contracts';
import { Signer } from 'ethers';
import { ROUTER_ADDRESS } from '../../constants';
import { isAddress, IUniswapV2Router02ABI } from '../index';

export function getContractV2(address: string, ABI: any, signer: Signer): Contract {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`);
  }

  return new Contract(address, ABI, signer);
}

export function getRouterContractV2(_: number, signer: Signer): Contract {
  return getContractV2(ROUTER_ADDRESS, IUniswapV2Router02ABI, signer);
}
