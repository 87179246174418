import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setIsHistoryModalActive } from 'state/exchange/slice';
import { useAccount } from 'wagmi';
import ModalSlideWrapper from '../../../modalSlideWrapper';
import ErrorField from '../../../errorField';
import HistoryList from './historyList';

type StateType = {
  exchange: {
    isHistoryModalActive: boolean;
  };
};

const HistoryModal = () => {
  const { isConnected } = useAccount();
  const isActive = useSelector((state: StateType) => state.exchange.isHistoryModalActive);
  const dispatch = useDispatch();

  const handleChange = () => dispatch(setIsHistoryModalActive(!isActive));

  return (
    <ModalSlideWrapper heading="History" state={isActive} setState={handleChange}>
      {isConnected ? <HistoryList /> : <ErrorField text="Connect to a wallet to view your recent transactions" />}
    </ModalSlideWrapper>
  );
};

export default HistoryModal;
