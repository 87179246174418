import React from 'react';
import classes from './styles.module.scss';
import CurrentValueBlock from './currentValueBlock';
import TimeFrameSwitcher from './timeFrameSwitcher';

const ChartHeader = ({
  data,
  setPriceInverted,
  priceInverted,
  priceValue,
  ticks,
  tradeValue,
  pairAddress,
  inputValue,
  outputValue,
  tokenInverted,
}) => {
  return (
    <div className={classes.wrapper}>
      <div className={classes.switcherWrapper}>
        <TimeFrameSwitcher />
      </div>
      <CurrentValueBlock
        data={data}
        ticks={ticks}
        priceInverted={priceInverted}
        tradeValue={tradeValue}
        pairAddress={pairAddress}
        inputValue={inputValue}
        outputValue={outputValue}
        setPriceInverted={setPriceInverted}
        tokenInverted={tokenInverted}
      />
    </div>
  );
};

export default ChartHeader;
