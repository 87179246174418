import React, { useState } from 'react';
import classNames from 'classnames';
import { ReactComponent as CaretRight } from 'assets/images/caret-right-icon.svg';
import { ReactComponent as CaretDown } from 'assets/images/caret-down-icon.svg';
import classes from '../styles.module.scss';

const AccordionPlatform = () => {
  const [isActive, setIsActive] = useState(false);

  const handleToggleVisibility = () => setIsActive(!isActive);

  const buttonClasses = () =>
    classNames({
      [classes.button]: true,
      [classes.buttonActive]: isActive,
    });

  const textClasses = () =>
    classNames({
      [classes.content]: true,
      [classes.contentActive]: isActive,
    });

  const breakLineClasses = () =>
    classNames({
      [classes.breakLine]: true,
      [classes.breakLineActive]: isActive,
    });

  return (
    <div>
      <button type="button" className={buttonClasses()} onClick={handleToggleVisibility}>
        <div className={classes.headingWrapper}>Features of Qidex Token generator platform</div>
        <div>{isActive ? <CaretDown /> : <CaretRight />}</div>
      </button>
      <p className={textClasses()}>- Easy to Use</p>
      <br className={breakLineClasses()} />
      <p className={textClasses()}>- Highly-Secure</p>
      <br className={breakLineClasses()} />
      <p className={textClasses()}>- Fully Responsive</p>
      <br className={breakLineClasses()} />
      <p className={textClasses()}>- No Using Limits</p>
      <br className={breakLineClasses()} />
      <p className={textClasses()}>- Advanced Traits</p>
      <br className={breakLineClasses()} />
      <p className={textClasses()}>- Global Access</p>
      <br className={breakLineClasses()} />
      <p className={textClasses()}>- Frequent Updates & more</p>
    </div>
  );
};

export default AccordionPlatform;
