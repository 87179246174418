import api from '../axios';

const swapInfoApi = {
  getChartData: (address, start, granularity) =>
    api.get(`api/chart?pairAddr=${address}&start=${start}&granularity=${granularity}`),
  getVolume: (address) => api.get(`api/chart/volume?pairAddr=${address}`),
  getTokenPairInfo: (tokenpair) => api.post(`/token-info/token`, tokenpair),
  getTokenQIInfo: () => api.post(`/token-info/coin`, ['QI Blockchain']),
};

export default swapInfoApi;
