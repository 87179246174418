import React from 'react';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import ButtonBorderGradient from 'components/ui/button/buttonBorderGradient';
import BtnGradient from '../btnGradient';

const WalletConnect = ({ icon }) => {
  return (
    <ConnectButton.Custom>
      {({ account, chain, openAccountModal, openChainModal, openConnectModal, authenticationStatus, mounted }) => {
        const connected = mounted && account && chain;

        return (
          <>
            {(() => {
              if (!connected) {
                return <BtnGradient func={openConnectModal} Icon={icon} text="Connect Wallet" />;
              }

              if (chain?.unsupported) {
                return <ButtonBorderGradient setState={openChainModal} text="Wrong network" />;
              }

              return (
                <div style={{ display: 'flex', gap: 12 }}>
                  <ButtonBorderGradient
                    setState={openAccountModal}
                    text={`${account?.displayName}
                    ${account?.displayBalance ? `(${account?.displayBalance})` : ''}`}
                  />
                </div>
              );
            })()}
          </>
        );
      }}
    </ConnectButton.Custom>
  );
};

export default WalletConnect;
