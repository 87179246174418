import React from 'react';
import { useSelector } from 'react-redux';
import SwapContent from './swapContent';
import LiquidityContent from './liquidityContent';

type StateType = {
  exchange: {
    activeIndex: number;
  };
};

const PageContent = () => {
  const activeIndex = useSelector((state: StateType) => state.exchange.activeIndex);

  return <>{activeIndex === 0 ? <SwapContent /> : <LiquidityContent />}</>;
};

export default PageContent;
