import React, { useCallback, useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { light, dark } from '@pancakeswap-libs/uikit';

const CACHE_KEY = 'IS_DARK';

export interface ThemeContextType {
  isDark: boolean;
  toggleTheme: () => void;
}

type ThemeProviderType = {
  children: React.ReactNode;
};

const ThemeContext = React.createContext<ThemeContextType>({ isDark: true, toggleTheme: () => null });

const ThemeContextProvider: React.FC<ThemeProviderType> = ({ children }) => {
  const [isDark, setIsDark] = useState(() => {
    const isDarkUserSetting = localStorage.getItem(CACHE_KEY);
    return isDarkUserSetting ? JSON.parse(isDarkUserSetting) : false;
  });

  const handleSetup = useCallback((event) => {
    if (event && event.data && typeof event.data === 'string' && event.data.startsWith('[iFrameSizer]message:')) {
      const dataStr = event.data.substring('[iFrameSizer]message:'.length);
      const data = JSON.parse(dataStr);
      // console.log('data.isDark', data.isDark)
      setIsDark(() => data.isDark);
    }
  }, []);
  useEffect(() => {
    window.addEventListener('message', handleSetup);
    return () => {
      window.removeEventListener('message', handleSetup);
    };
  }, [handleSetup]);

  const toggleTheme = () => {
    setIsDark((prevState: any) => {
      localStorage.setItem(CACHE_KEY, JSON.stringify(!prevState));
      return !prevState;
    });
  };
  const lightTheme = {
    ...light,
    button: {
      ...light.button,
      primary: {
        ...light.button.primary,
        background: '#6c65ad',
        backgroundActive: '#6c65ad',
        backgroundHover: '#6c65ad',
      },
      text: {
        ...light.button.text,
        color: '#6c65ad',
      },
      tertiary: {
        ...light.button.tertiary,
        color: '#b83280',
        background: '#fce7f4',
        backgroundActive: '#fce7f4',
        backgroundHover: '#fce7f4',
      },
      secondary: {
        ...light.button.secondary,
        color: '#ffffff',
        background: '#6c65ad',
        backgroundActive: '#6c65ad',
        backgroundHover: '#6c65ad',
        border: '2px solid #6c65ad',
        borderColorHover: '#6c65ad',
      },
      subtle: {
        ...light.button.subtle,
        background: '#6c65ad',
        backgroundActive: '#6c65ad',
        backgroundHover: '#6c65ad',
      },
    },
    colors: {
      ...light.colors,
      primary: '#b83280',
      primaryBright: '#6c65ad',
      primaryDark: '#6c65ad',
      textSubtle: '#b83280',
      secondary: '#6c65ad',
      text: '#b83280',
      input: '#fce7f4',
      tertiary: '#fce7f4',
    },
    nav: {
      ...light.nav,
      hover: '#fce7f4',
    },
  };
  const darkTheme = {
    ...dark,
    alert: { background: '#272c2c' },
    card: {
      ...dark.card,
      background: '#272c2c',
    },
    modal: { background: '#272c2c' },
    nav: { background: '#272c2c', hover: '#012b2c' },
    radio: { handleBackground: '#272c2c' },
    toggle: { handleBackground: '#272c2c' },
    button: {
      ...dark.button,
      primary: {
        ...dark.button.primary,
        background: '#2fb4f1',
        backgroundActive: '#1782b3',
        backgroundHover: '#1782b3',
      },
      text: {
        ...dark.button.text,
        color: '#2fb4f1',
        backgroundHover: '#1782b3',
      },
      tertiary: {
        ...dark.button.tertiary,
        color: '#ffffff',
        background: '#1782b3',
        backgroundActive: '#1782b3',
        backgroundHover: '#1782b3',
      },
      secondary: {
        ...dark.button.secondary,
        color: '#2fb4f1',
        border: '2px solid #2fb4f1',
        borderColorHover: '#2fb4f1',
      },
      subtle: {
        ...dark.button.subtle,
        background: '#2fb4f1',
        backgroundActive: '#2fb4f1',
        backgroundHover: '#2fb4f1',
      },
    },
    colors: {
      ...dark.colors,
      primary: '#ffffff',
      primaryBright: '#42E8E0',
      primaryDark: '#ffffff',
      // textSubtle: '#b83280',
      secondary: '#1782b3',
      // text: '#b83280',
      background: '#000000',
      card: '#272c2c',
      tertiary: '#1782b3',
      input: '#494e4e',
    },
  };
  console.log({ lightTheme, darkTheme });
  return (
    <ThemeContext.Provider value={{ isDark, toggleTheme }}>
      <ThemeProvider theme={isDark ? darkTheme : lightTheme}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};

export { ThemeContext, ThemeContextProvider };
