// @ts-nocheck

import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import { Link, Redirect } from 'react-router-dom';
import { BigNumber } from '@ethersproject/bignumber';
import { TransactionResponse } from '@ethersproject/providers';
import { Currency, currencyEquals, ETHER, Percent, WETH } from '@pancakeswap-libs/sdk';
import { Contract } from '@ethersproject/contracts';
import { splitSignature } from '@ethersproject/bytes';
import LiquidityWallet from 'components/liquidityWallet';
import BtnGradient from 'components/btnGradient';
import Heading from './heading';
import ContentCenterWrapper from './contentCenterWrapper';
import RangeSlider from './rangeSlider';
import InputCurrency from '../../Exchange/pageContent/inputBlock/inputCurrency';
import ModalWrapper from '../../../components/modalWrapper';
import { useCurrency } from '../../../hooks/Tokens';
import { useActiveWeb3React } from '../../../hooks';
import { wrappedCurrency } from '../../../utils/wrappedCurrency';
import { useBurnActionHandlers, useBurnState, useDerivedBurnInfo } from '../../../state/burn/hooks';
import { useUserDeadline, useUserSlippageTolerance } from '../../../state/user/hooks';
import { Field } from '../../../state/burn/actions';
import { usePairContract } from '../../../hooks/useContract';
import { ApprovalState, useApproveCallback } from '../../../hooks/useApproveCallback';
import { ROUTER_ADDRESS } from '../../../constants';
import { useTransactionAdder } from '../../../state/transactions/hooks';
import { calculateGasMargin, calculateSlippageAmount, getEtherscanLink, getRouterContract } from '../../../utils';
import useDebouncedChangeHandler from '../../../utils/useDebouncedChangeHandler';
import { currencyId } from '../../../utils/currencyId';
import ROUTES from '../../../constants/routes';
import classes from './styles.module.scss';
import BalanceWithButton from '../../Exchange/pageContent/balanceWithButton';
import { useCurrencyBalance } from '../../../state/wallet/hooks';
import formatValue from '../../../utils/formatNumber';
import { useEthersSigner } from "../../../hooks/v2/ethers-adapters";
import { ethers } from "ethers";

const PageContent = ({ history, currencyIdA, currencyIdB }) => {
  const [errorLiquidity, setErrorLiquidity] = useState('');
  const [errorCurrencyA, setErrorCurrencyA] = useState('');
  const [errorCurrencyB, setErrorCurrencyB] = useState('');
  const [isModalActive, setIsModalActive] = useState(false);
  const [currencyA, currencyB] = [useCurrency(currencyIdA) ?? undefined, useCurrency(currencyIdB) ?? undefined];
  const { account, chainId, library } = useActiveWeb3React();
  const [tokenA, tokenB] = useMemo(() => [wrappedCurrency(currencyA, chainId), wrappedCurrency(currencyB, chainId)], [
    currencyA,
    currencyB,
    chainId,
  ]);

  // burn state
  const { independentField, typedValue } = useBurnState();
  const { pair, parsedAmounts, error } = useDerivedBurnInfo(currencyA ?? undefined, currencyB ?? undefined);
  const { onUserInput: _onUserInput } = useBurnActionHandlers();
  const isValid = !error;

  // modal and loading
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [showDetailed, setShowDetailed] = useState<boolean>(false);
  const [attemptingTxn, setAttemptingTxn] = useState(false); // clicked confirm

  // txn values
  const [txHash, setTxHash] = useState<string>('');
  const [deadline] = useUserDeadline();
  const [allowedSlippage] = useUserSlippageTolerance();

  const formattedAmounts = {
    [Field.LIQUIDITY_PERCENT]: parsedAmounts[Field.LIQUIDITY_PERCENT].equalTo('0')
      ? '0'
      : parsedAmounts[Field.LIQUIDITY_PERCENT].lessThan(new Percent('1', '100'))
      ? '<1'
      : parsedAmounts[Field.LIQUIDITY_PERCENT].toFixed(0),
    [Field.LIQUIDITY]:
      independentField === Field.LIQUIDITY ? typedValue : parsedAmounts[Field.LIQUIDITY]?.toSignificant(6) ?? '',
    [Field.CURRENCY_A]:
      independentField === Field.CURRENCY_A ? typedValue : parsedAmounts[Field.CURRENCY_A]?.toSignificant(6) ?? '',
    [Field.CURRENCY_B]:
      independentField === Field.CURRENCY_B ? typedValue : parsedAmounts[Field.CURRENCY_B]?.toSignificant(6) ?? '',
  };

  const atMaxAmount = parsedAmounts[Field.LIQUIDITY_PERCENT]?.equalTo(new Percent('1'));

  // pair contract
  const pairContract: Contract | null = usePairContract(pair?.liquidityToken?.address);

  // allowance handling
  const [signatureData, setSignatureData] = useState<{ v: number; r: string; s: string; deadline: number } | null>(
    null
  );
  const [approval, approveCallback] = useApproveCallback(parsedAmounts[Field.LIQUIDITY], ROUTER_ADDRESS);
  const signer = useEthersSigner(5656)
  const onAttemptToApprove = async () => {
    if (!pairContract || !pair || !library) throw new Error('missing dependencies');

    const liquidityAmount = parsedAmounts[Field.LIQUIDITY];
    if (!liquidityAmount) throw new Error('missing liquidity amount');

    // Gather nonce and deadline
    const nonce = await pairContract.nonces(account);
    const deadlineForSignature = Math.ceil(Date.now() / 1000) + deadline;

    // EIP-712 domain structure
    const domain = {
      name: 'QI LPs',                  // Contract name
      version: '1',                    // Version
      chainId: chainId,                // Network chain ID
      verifyingContract: pair.liquidityToken.address // Liquidity token contract address
    };

    // The Permit type for EIP-712
    const types = {
      Permit: [
        { name: 'owner', type: 'address' },
        { name: 'spender', type: 'address' },
        { name: 'value', type: 'uint256' },
        { name: 'nonce', type: 'uint256' },
        { name: 'deadline', type: 'uint256' },
      ],
    };

    // The message that will be signed
    const message = {
      owner: account,
      spender: ROUTER_ADDRESS,                       // Address that will spend the tokens
      value: liquidityAmount.raw.toString(),         // Liquidity amount in raw format
      nonce: nonce.toString(),                       // Nonce for the permit
      deadline: deadlineForSignature,                // Deadline for the signature
    };

    try {
      // Signing the typed data
      const signature = await signer._signTypedData(domain, types, message);

      // Split the signature into its components (v, r, s)
      const splitSig = ethers.utils.splitSignature(signature);

      // Save the signature data
      setSignatureData({
        v: splitSig.v,
        r: splitSig.r,
        s: splitSig.s,
        deadline: deadlineForSignature,
      });
    } catch (e) {
      // Handle errors, including the user rejection (code 4001)
      if (e?.code !== 4001) {
        await approveCallback();  // Fallback to manual approve
      }
    }
  };


  // wrapped onUserInput to clear signatures
  const onUserInput = useCallback(
    (field: Field, val: string) => {
      setSignatureData(null);
      return _onUserInput(field, val);
    },
    [_onUserInput]
  );

  const onLiquidityInput = useCallback(
    (e): void => {
      const cursor = e.target?.selectionStart;
      const parsedValue = e.target.value.replace(/,/g, '');

      if (/^\d*\.?\d*$/.test(parsedValue)) {
        onUserInput(Field.LIQUIDITY, parsedValue);
      }

      if (selectedCurrencyBalanceLiquidity) {
        if (Number(parsedValue) === 0) {
          setErrorLiquidity('Enter correct amount');
        } else if (parsedValue > selectedCurrencyBalanceLiquidity?.toSignificant(6)) {
          setErrorLiquidity('Enter correct amount');
        } else {
          setErrorLiquidity('');
        }
      }

      e.target.selectionStart = cursor;
    },
    [onUserInput]
  ); //eslint-disable-line

  const onCurrencyAInput = useCallback(
    (e): void => {
      const cursor = e.target?.selectionStart;
      const parsedValue = e.target.value.replace(/,/g, '');

      if (/^\d*\.?\d*$/.test(parsedValue)) {
        onUserInput(Field.CURRENCY_A, parsedValue);
      }

      if (selectedCurrencyBalanceInput) {
        if (Number(parsedValue) === 0) {
          setErrorCurrencyA('Enter correct amount');
        } else if (parsedValue > selectedCurrencyBalanceInput?.toSignificant(6)) {
          setErrorCurrencyA('Enter correct amount');
        } else {
          setErrorCurrencyA('');
        }
      }

      e.target.selectionStart = cursor;
    },
    [onUserInput]
  ); //eslint-disable-line

  const onCurrencyBInput = useCallback(
    (e): void => {
      const cursor = e.target?.selectionStart;
      const parsedValue = e.target.value.replace(/,/g, '');

      if (/^\d*\.?\d*$/.test(parsedValue)) {
        onUserInput(Field.CURRENCY_B, parsedValue);
      }

      if (selectedCurrencyBalanceOutput) {
        if (Number(parsedValue) === 0) {
          setErrorCurrencyB('Enter correct amount');
        } else if (parsedValue > selectedCurrencyBalanceOutput?.toSignificant(6)) {
          setErrorCurrencyB('Enter correct amount');
        } else {
          setErrorCurrencyB('');
        }
      }

      e.target.selectionStart = cursor;
    },
    [onUserInput]
  ); //eslint-disable-line

  // tx sending
  const addTransaction = useTransactionAdder();
  const onRemove = async () => {
    if (!chainId || !library || !account) throw new Error('missing dependencies');
    const { [Field.CURRENCY_A]: currencyAmountA, [Field.CURRENCY_B]: currencyAmountB } = parsedAmounts;
    if (!currencyAmountA || !currencyAmountB) {
      throw new Error('missing currency amounts');
    }
    const router = getRouterContract(chainId, library, account, signer);

    const amountsMin = {
      [Field.CURRENCY_A]: calculateSlippageAmount(currencyAmountA, allowedSlippage)[0],
      [Field.CURRENCY_B]: calculateSlippageAmount(currencyAmountB, allowedSlippage)[0],
    };

    if (!currencyA || !currencyB) throw new Error('missing tokens');
    const liquidityAmount = parsedAmounts[Field.LIQUIDITY];
    if (!liquidityAmount) throw new Error('missing liquidity amount');

    const currencyBIsETH = currencyB === ETHER;
    const oneCurrencyIsETH = currencyA === ETHER || currencyBIsETH;
    const deadlineFromNow = Math.ceil(Date.now() / 1000) + deadline;

    if (!tokenA || !tokenB) throw new Error('could not wrap');

    let methodNames: string[];
    let args: Array<string | string[] | number | boolean>;
    // we have approval, use normal remove liquidity
    if (approval === ApprovalState.APPROVED) {
      // removeLiquidityETH
      if (oneCurrencyIsETH) {
        methodNames = ['removeLiquidityETH', 'removeLiquidityETHSupportingFeeOnTransferTokens'];
        args = [
          currencyBIsETH ? tokenA.address : tokenB.address,
          liquidityAmount.raw.toString(),
          amountsMin[currencyBIsETH ? Field.CURRENCY_A : Field.CURRENCY_B].toString(),
          amountsMin[currencyBIsETH ? Field.CURRENCY_B : Field.CURRENCY_A].toString(),
          account,
          deadlineFromNow,
        ];
      }
      // removeLiquidity
      else {
        methodNames = ['removeLiquidity'];
        args = [
          tokenA.address,
          tokenB.address,
          liquidityAmount.raw.toString(),
          amountsMin[Field.CURRENCY_A].toString(),
          amountsMin[Field.CURRENCY_B].toString(),
          account,
          deadlineFromNow,
        ];
      }
    }
    // we have a signataure, use permit versions of remove liquidity
    else if (signatureData !== null) {
      // removeLiquidityETHWithPermit
      if (oneCurrencyIsETH) {
        methodNames = ['removeLiquidityETHWithPermit', 'removeLiquidityETHWithPermitSupportingFeeOnTransferTokens'];
        args = [
          currencyBIsETH ? tokenA.address : tokenB.address,
          liquidityAmount.raw.toString(),
          amountsMin[currencyBIsETH ? Field.CURRENCY_A : Field.CURRENCY_B].toString(),
          amountsMin[currencyBIsETH ? Field.CURRENCY_B : Field.CURRENCY_A].toString(),
          account,
          signatureData.deadline,
          false,
          signatureData.v,
          signatureData.r,
          signatureData.s,
        ];
      }
      // removeLiquidityETHWithPermit
      else {
        methodNames = ['removeLiquidityWithPermit'];
        args = [
          tokenA.address,
          tokenB.address,
          liquidityAmount.raw.toString(),
          amountsMin[Field.CURRENCY_A].toString(),
          amountsMin[Field.CURRENCY_B].toString(),
          account,
          signatureData.deadline,
          false,
          signatureData.v,
          signatureData.r,
          signatureData.s,
        ];
      }
    } else {
      throw new Error('Attempting to confirm without approval or a signature. Please contact support.');
    }

    const safeGasEstimates: (BigNumber | undefined)[] = await Promise.all(
      methodNames.map((methodName, index) =>
        router.estimateGas[methodName](...args)
          .then(calculateGasMargin)
          .catch((e) => {
            console.error(`estimateGas failed`, index, methodName, args, e);
            return undefined;
          })
      )
    );

    const indexOfSuccessfulEstimation = safeGasEstimates.findIndex((safeGasEstimate) =>
      BigNumber.isBigNumber(safeGasEstimate)
    );

    // all estimations failed...
    if (indexOfSuccessfulEstimation === -1) {
      console.error('This transaction would fail. Please contact support.');
    } else {
      const methodName = methodNames[indexOfSuccessfulEstimation];
      const safeGasEstimate = safeGasEstimates[indexOfSuccessfulEstimation];

      setAttemptingTxn(true);
      await router[methodName](...args, {
        gasLimit: safeGasEstimate,
      })
        .then((response: TransactionResponse) => {
          setAttemptingTxn(false);

          addTransaction(response, {
            summary: `Remove ${parsedAmounts[Field.CURRENCY_A]?.toSignificant(3)} ${
              currencyA?.symbol
            } and ${parsedAmounts[Field.CURRENCY_B]?.toSignificant(3)} ${currencyB?.symbol}`,
          });

          setTxHash(response.hash);
        })
        .catch((e: Error) => {
          setAttemptingTxn(false);
          // we only care if the error is something _other_ than the user rejected the tx
          console.error(e);
        });
    }
  };

  const pendingText = `Removing ${parsedAmounts[Field.CURRENCY_A]?.toSignificant(6)} ${
    currencyA?.symbol
  } and ${parsedAmounts[Field.CURRENCY_B]?.toSignificant(6)} ${currencyB?.symbol}`;

  const liquidityPercentChangeCallback = useCallback(
    (value: number) => {
      onUserInput(Field.LIQUIDITY_PERCENT, value.toString());
    },
    [onUserInput]
  );

  const oneCurrencyIsETH = currencyA === ETHER || currencyB === ETHER;
  const oneCurrencyIsWETH = Boolean(
    chainId &&
      ((currencyA && currencyEquals(WETH[chainId], currencyA)) ||
        (currencyB && currencyEquals(WETH[chainId], currencyB)))
  );
  const handleSelectCurrencyA = useCallback(
    (currency: Currency) => {
      if (currencyIdB && currencyId(currency) === currencyIdB) {
        history.push(`${ROUTES.LIQUIDITY_REMOVE}/${currencyId(currency)}/${currencyIdA}`);
      } else {
        history.push(`${ROUTES.LIQUIDITY_REMOVE}/${currencyId(currency)}/${currencyIdB}`);
      }
    },
    [currencyIdA, currencyIdB, history]
  );
  const handleSelectCurrencyB = useCallback(
    (currency: Currency) => {
      if (currencyIdA && currencyId(currency) === currencyIdA) {
        history.push(`${ROUTES.LIQUIDITY_REMOVE}/${currencyIdB}/${currencyId(currency)}`);
      } else {
        history.push(`${ROUTES.LIQUIDITY_REMOVE}/${currencyIdA}/${currencyId(currency)}`);
      }
    },
    [currencyIdA, currencyIdB, history]
  );

  const handleDismissConfirmation = useCallback(() => {
    setShowConfirm(false);
    setIsModalActive(false);
    setSignatureData(null); // important that we clear signature data to avoid bad sigs
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onUserInput(Field.LIQUIDITY_PERCENT, '0');
    }
    setTxHash('');
  }, [onUserInput, txHash]);

  const [innerLiquidityPercentage, setInnerLiquidityPercentage] = useDebouncedChangeHandler(
    Number.parseInt(parsedAmounts[Field.LIQUIDITY_PERCENT].toFixed(0)),
    liquidityPercentChangeCallback
  );

  const selectedCurrencyBalanceInput = useCurrencyBalance(account ?? undefined, currencyA ?? undefined);
  const selectedCurrencyBalanceOutput = useCurrencyBalance(account ?? undefined, currencyB ?? undefined);
  const selectedCurrencyBalanceLiquidity = useCurrencyBalance(account ?? undefined, pair?.liquidityToken ?? undefined);

  return (
    <ContentCenterWrapper>
      <Heading />
      <div className={classes.wrapper}>
        <div className={classes.contentWrapper}>
          <div className={classes.headingWrapper}>
            <h4 className={classes.headingText}>Amount</h4>
            <button className={classes.headingText} type="button" onClick={() => setShowDetailed(!showDetailed)}>
              {showDetailed ? 'Simple' : 'Detailed'}
            </button>
          </div>
          <p className={classes.valueSlider}>{formattedAmounts[Field.LIQUIDITY_PERCENT]}%</p>
          {!showDetailed ? (
            <>
              <RangeSlider
                rangeSliderValue={innerLiquidityPercentage}
                setRangeSliderValue={setInnerLiquidityPercentage}
              />
              <div className={classes.sliderButtonWrapper}>
                <button
                  type="button"
                  className={classes.sliderButton}
                  onClick={() => onUserInput(Field.LIQUIDITY_PERCENT, '25')}
                >
                  25%
                </button>
                <button
                  type="button"
                  className={classes.sliderButton}
                  onClick={() => onUserInput(Field.LIQUIDITY_PERCENT, '50')}
                >
                  50%
                </button>
                <button
                  type="button"
                  className={classes.sliderButton}
                  onClick={() => onUserInput(Field.LIQUIDITY_PERCENT, '75')}
                >
                  75%
                </button>
                <button
                  type="button"
                  className={classes.sliderButton}
                  onClick={() => onUserInput(Field.LIQUIDITY_PERCENT, '100')}
                >
                  MAX
                </button>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
        {!showDetailed ? (
          <div className={classes.contentWrapper}>
            <div className={classes.valueWrapper}>
              <h4 className={classes.headingText}>{currencyA?.symbol}</h4>
              <p className={classes.value}>{formatValue(formattedAmounts[Field.CURRENCY_A]) || '-'}</p>
            </div>
            <div className={classes.valueWrapper}>
              <h4 className={classes.headingText}>{currencyB?.symbol}</h4>
              <p className={classes.value}>{formatValue(formattedAmounts[Field.CURRENCY_B]) || '-'}</p>
            </div>
            {oneCurrencyIsETH ? (
              <Link
                className={classes.receiveButton}
                to={`${ROUTES.LIQUIDITY_REMOVE}/${currencyA === ETHER ? WETH[chainId].address : currencyIdA}/${
                  currencyB === ETHER ? WETH[chainId].address : currencyIdB
                }`}
              >
                Receive WQIE
              </Link>
            ) : oneCurrencyIsWETH ? (
              <Link
                className={classes.receiveButton}
                to={`${ROUTES.LIQUIDITY_REMOVE}/${
                  currencyA && currencyEquals(currencyA, WETH[chainId]) ? 'ETH' : currencyIdA
                }/${currencyB && currencyEquals(currencyB, WETH[chainId]) ? 'ETH' : currencyIdB}`}
              >
                Receive QIE
              </Link>
            ) : null}
          </div>
        ) : (
          <div className={classes.inputBlock}>
            <InputCurrency
              value={formattedAmounts[Field.LIQUIDITY]}
              setInputValue={(e) => onLiquidityInput(e)}
              selectValue={pair}
              error={errorLiquidity}
            >
              {errorLiquidity ? (
                <p className={classes.error}>{errorLiquidity}</p>
              ) : (
                <BalanceWithButton
                  balance={
                    !!pair?.liquidityToken && selectedCurrencyBalanceLiquidity
                      ? `Balance: ${selectedCurrencyBalanceLiquidity?.toSignificant(6)}`
                      : ' -'
                  }
                  onMax={() => {
                    onUserInput(Field.LIQUIDITY_PERCENT, '100');
                  }}
                />
              )}
            </InputCurrency>
            <div className={classes.inputWrapper}>
              <h6 className={classes.inputHeading}>Output</h6>
              <InputCurrency
                onCurrencySelect={handleSelectCurrencyA}
                value={formattedAmounts[Field.CURRENCY_A]}
                setInputValue={(e) => onCurrencyAInput(e)}
                selectValue={currencyA}
                error={errorCurrencyA}
              >
                {errorCurrencyA ? (
                  <p className={classes.error}>{errorCurrencyA}</p>
                ) : (
                  <BalanceWithButton
                    balance={
                      !!currencyA && selectedCurrencyBalanceInput
                        ? `Balance: ${selectedCurrencyBalanceInput?.toSignificant(6)}`
                        : ' -'
                    }
                    onMax={() => {
                      onUserInput(Field.LIQUIDITY_PERCENT, '100');
                    }}
                  />
                )}
              </InputCurrency>
            </div>
            <div className={classes.inputWrapper}>
              <h6 className={classes.inputHeading}>Output</h6>
              <InputCurrency
                onCurrencySelect={handleSelectCurrencyB}
                value={formattedAmounts[Field.CURRENCY_B]}
                setInputValue={(e) => onCurrencyBInput(e)}
                selectValue={currencyB}
                error={errorCurrencyB}
              >
                {errorCurrencyB ? (
                  <p className={classes.error}>{errorCurrencyB}</p>
                ) : (
                  <BalanceWithButton
                    balance={
                      !!currencyB && selectedCurrencyBalanceOutput
                        ? `Balance: ${selectedCurrencyBalanceOutput?.toSignificant(6)}`
                        : ' -'
                    }
                    onMax={() => {
                      onUserInput(Field.LIQUIDITY_PERCENT, '100');
                    }}
                  />
                )}
              </InputCurrency>
            </div>
          </div>
        )}
        <div className={classes.contentWrapper}>
          <div className={classes.valueWrapper}>
            <h4 className={classes.headingText}>Price:</h4>
            <div>
              <p className={classes.value}>
                {pair && (
                  <>
                    1 {currencyA?.symbol} = {tokenA ? formatValue(pair.priceOf(tokenA).toSignificant(6)) : '-'}{' '}
                    {currencyB?.symbol}{' '}
                  </>
                )}
              </p>
              <p className={classes.value}>
                {pair && (
                  <>
                    1 {currencyB?.symbol} = {tokenB ? formatValue(pair.priceOf(tokenB).toSignificant(6)) : '-'}{' '}
                    {currencyA?.symbol}{' '}
                  </>
                )}
              </p>
            </div>
          </div>
        </div>
        <div className={classes.contentWrapper}>
          <div className={classes.buttonWrapper}>
            <div className={classes.button}>
              <BtnGradient
                text="Approve"
                disabled={approval !== ApprovalState.NOT_APPROVED || signatureData !== null}
                func={() => onAttemptToApprove()}
              />
            </div>
            <div className={classes.button}>
              <BtnGradient
                text="Remove"
                disabled={!isValid || (signatureData === null && approval !== ApprovalState.APPROVED)}
                func={() => setIsModalActive(true)}
              />
            </div>
          </div>
        </div>
        {pair && <LiquidityWallet pair={pair} />}
      </div>
      {attemptingTxn ? (
        <ModalWrapper
          state={isModalActive}
          onClose={() => handleDismissConfirmation()}
          heading="Waiting for confirmation"
        >
          <div className={classes.modalWrapper}>
            <p className={classes.headingText}>{`Removing ${formatValue(
              parsedAmounts[Field.CURRENCY_A]?.toSignificant(6)
            )} ${currencyA?.symbol} and ${formatValue(parsedAmounts[Field.CURRENCY_B]?.toSignificant(6))} ${
              currencyB?.symbol
            }`}</p>
            <p className={classes.headingText}>Confirm this transaction in your wallet</p>
          </div>
        </ModalWrapper>
      ) : txHash ? (
        <></>
      ) : (
        // <ModalWrapper
        //     state={isModalActive}
        //     onClose={() => handleDismissConfirmation()}
        //     heading='Transaction submitted'
        // >
        //     <div className={classes.modalWrapper}>
        //         <a className={classes.modalLink} href={getEtherscanLink(chainId, txHash, 'transaction')} target='_blank' rel='noreferrer'>View on QIcscan</a>
        //     </div>
        // </ModalWrapper>
        <ModalWrapper state={isModalActive} onClose={() => handleDismissConfirmation()} heading="You will receive">
          <div className={classes.wrapperModal}>
            <div className={classes.contentWrapperModal}>
              <div className={classes.valueWrapper}>
                <h3 className={classes.headingText}>{currencyA?.symbol}</h3>
                <p className={classes.valueModal}>{formatValue(parsedAmounts[Field.CURRENCY_A]?.toSignificant(6))}</p>
              </div>
              <div className={classes.valueWrapper}>
                <h3 className={classes.headingText}>{currencyB?.symbol}</h3>
                <p className={classes.valueModal}>{formatValue(parsedAmounts[Field.CURRENCY_B]?.toSignificant(6))}</p>
              </div>
            </div>
            <p className={classes.value}>
              <i>
                Output is estimated. If the price changes by more than {allowedSlippage / 100}% your transaction will
                revert.
              </i>
            </p>
            <div className={classes.contentWrapperModal}>
              <div className={classes.valueWrapper}>
                <h3 className={classes.headingText}>{`LP ${currencyA?.symbol}/${currencyB?.symbol}`} Burned</h3>
                <p className={classes.valueModal}>{formatValue(parsedAmounts[Field.LIQUIDITY]?.toSignificant(6))}</p>
              </div>
              <div className={classes.valueWrapper}>
                <h4 className={classes.headingText}>Price:</h4>
                <div>
                  <p className={classes.value}>
                    {pair && (
                      <>
                        1 {currencyA?.symbol} = {tokenA ? formatValue(pair.priceOf(tokenA).toSignificant(6)) : '-'}{' '}
                        {currencyB?.symbol}{' '}
                      </>
                    )}
                  </p>
                  <p className={classes.value}>
                    {pair && (
                      <>
                        1 {currencyB?.symbol} = {tokenB ? formatValue(pair.priceOf(tokenB).toSignificant(6)) : '-'}{' '}
                        {currencyA?.symbol}{' '}
                      </>
                    )}
                  </p>
                </div>
              </div>
            </div>
            <div className={classes.buttonModal}>
              <BtnGradient
                text="Confirm"
                disabled={!(approval === ApprovalState.APPROVED || signatureData !== null)}
                func={() => {
                  onRemove();
                }}
              />
            </div>
          </div>
        </ModalWrapper>
      )}
    </ContentCenterWrapper>
  );
};

export default PageContent;
