import ROUTES from 'constants/routes';

const config = [
  {
    id: 0,
    label: 'Swap history',
    link: ROUTES.ALL_SWAPS,
  },
  {
    id: 1,
    label: 'Terms and conditions',
    link: ROUTES.TERMS,
  },
  {
    id: 2,
    label: 'Privacy Policy',
    link: ROUTES.POLICY,
  },
  {
    id: 3,
    label: 'Disclaimer',
    link: ROUTES.DISCLAIMER,
  },
  {
    id: 4,
    label: 'Docs',
    link: ROUTES.DOCS,
    extLink: true,
  },
  {
    id: 5,
    label: 'FAQ',
    link: ROUTES.FAQ,
  },
];

export default config;
