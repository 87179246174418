import { ReactComponent as CopyIcon } from 'assets/images/copy-icon.svg';
import { ReactComponent as XIcon } from 'assets/images/XCircle-icon.svg';
import classnames from 'classnames';
import React from 'react';
import { useAccount, useDisconnect } from 'wagmi';
import classes from './styles.module.scss';

const Wallet = () => {
  const { address } = useAccount();
  const { disconnect } = useDisconnect();

  const copyButtonClass = () =>
    classnames({
      [classes.icon]: true,
    });

  const singOut = () => {
    disconnect();
    window.localStorage.removeItem('accountStatus');
  };

  const saveToClipboard = () => {
    navigator.clipboard.writeText(address as string);
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.walletId}>{address}</div>
      <div className={classes.iconsWrapper}>
        <button type="button" className={copyButtonClass()} onClick={saveToClipboard} aria-label="copied to clipboard">
          <CopyIcon />
        </button>
        <button type="button" className={classes.icon} onClick={singOut}>
          <XIcon />
        </button>
      </div>
    </div>
  );
};

export default Wallet;
