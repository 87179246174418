const config = {
  supply: [
    { value: '10K', label: '10K' },
    { value: 'fixed', label: 'Fixed' },
    { value: 'unlimited', label: 'Unlimited' },
    { value: 'capped', label: 'Capped' },
  ],
  access: [
    { value: 'ownable', label: 'Ownable' },
    { value: 'roleBased', label: 'Role Based' },
  ],
};

export default config;
