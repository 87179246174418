import React from 'react';
import { ReactComponent as DollarIcon } from 'assets/images/dollar-icon.svg';
import classes from './styles.module.scss';

type Props = {
  Icon?: any;
  text?: string;
  onClick: () => void;
};

const BuyButton = ({ onClick }) => {
  return (
    <div className={classes.wrapper}>
      <button type="button" className={classes.button} onClick={onClick}>
        <div className={classes.icon}>
          <DollarIcon />
        </div>
        <div className={classes.text}>Buy Now</div>
      </button>
    </div>
  );
};

export default BuyButton;
