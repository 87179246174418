const LINKS = {
  TERMS: '#',
  TELEGRAM: 'https://t.me/HovRonQiblockchain',
  FACEBOOK: 'https://web.facebook.com/profile.php?id=100087150605472&_rdc=1&_rdr',
  TWITTER: 'https://twitter.com/dex_qi',
  DISCORD: 'https://discord.gg/Uwxd8prK',
  INSTAGRAM: 'https://www.instagram.com/qidex.site/',
};

export default LINKS;
