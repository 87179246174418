import React from 'react';
import { Link } from 'react-router-dom';
// @ts-ignore
import classes from './styles.module.scss';

type PropsType = {
  label: string;
  link: string;
  extLink?: boolean;
};

const NavItem = ({ label, link, extLink }: PropsType) => {
  return (
    <>
      {extLink ? (
        <a href={link} target="_blank" className={classes.link} rel="noreferrer">
          {label}
        </a>
      ) : (
        <Link to={link} className={classes.link}>
          {label}
        </Link>
      )}
    </>
  );
};

export default NavItem;
