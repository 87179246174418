import React from 'react';
import CardContent from 'components/cardContent';
import classes from './styles.module.scss';

type Props = {
  label: string;
  value: string;
  add?: string;
};

const CardItem = ({ label, value, add }: Props) => {
  return (
    <div className={classes.wrapper}>
      <CardContent label={label} value={value} add={add} />
    </div>
  );
};

export default CardItem;
