import React, { useState } from 'react';
import classNames from 'classnames';
import { ReactComponent as CaretRight } from 'assets/images/caret-right-icon.svg';
import { ReactComponent as CaretDown } from 'assets/images/caret-down-icon.svg';
import classes from '../styles.module.scss';

const AccordionReflection = () => {
  const [isActive, setIsActive] = useState(false);

  const handleToggleVisibility = () => setIsActive(!isActive);

  const buttonClasses = () =>
    classNames({
      [classes.button]: true,
      [classes.buttonActive]: isActive,
    });

  const textClasses = () =>
    classNames({
      [classes.content]: true,
      [classes.contentActive]: isActive,
    });

  const breakLineClasses = () =>
    classNames({
      [classes.breakLine]: true,
      [classes.breakLineActive]: isActive,
    });

  return (
    <div>
      <button type="button" className={buttonClasses()} onClick={handleToggleVisibility}>
        <div className={classes.headingWrapper}>What are reflection tokens?</div>
        <div>{isActive ? <CaretDown /> : <CaretRight />}</div>
      </button>
      <p className={textClasses()}>
        Reflection tokens (sometimes called ‘rewards’ tokens’) refer to any crypto-asset that rewards holders by adding
        new crypto to their wallets. Earning extra crypto is the end goal of other DeFi investment mechanisms like
        staking and yield farming. However, reflection tokens pay coinholders without them having to move any money,
        sign up to any staking pool, or even having to check their crypto wallet.
      </p>
      <br className={breakLineClasses()} />
      <br className={breakLineClasses()} />
      <p className={textClasses()}>
        Reflections are usually financed by a percentage tax on any transaction in the native token. The tax is
        redistributed instantly to coin holders, most often according to the size of their holding.
      </p>
      <br className={breakLineClasses()} />
      <br className={breakLineClasses()} />
      <p className={textClasses()}>
        Aside from passive income generation advantages, reflection tokens also help to prevent large price drops. This
        is both because taxes discourage whales (individuals holding a large amount of a specific coin) from selling up
        their positions and encourage investors to hold onto tokens for greater reflection in the future. Reflection
        tokens keep investors loyal to a project.
      </p>
      <br className={breakLineClasses()} />
      <br className={breakLineClasses()} />
      <p className={textClasses()}>
        Reflection mechanisms employ smart contracts that automatically distribute tokens across holders’ wallets (often
        also a liquidity pool and/or a burning wallet). Blockchain technology makes the process instant, transparent,
        and publicly audited.
      </p>
    </div>
  );
};

export default AccordionReflection;
