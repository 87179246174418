import React from 'react';
import classes from './styles.module.scss';

const InputHeadingWrapper = ({ name, children }) => {
  return (
    <div className={classes.wrapper}>
      {children}
      <h6 className={classes.heading}>{name}</h6>
    </div>
  );
};

export default InputHeadingWrapper;
