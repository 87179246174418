import getWeb3Obj from '../getWeb30bj';
import { Default_RPC_URL } from '../../constants';

const getWeb3ContractObject = async (abi, contractAddress, RPC_URL = Default_RPC_URL) => {
  const web3 = await getWeb3Obj(RPC_URL);
  const contract = await new web3.eth.Contract(abi, contractAddress);
  return contract;
};

export default getWeb3ContractObject;
