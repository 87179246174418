import React from 'react';
import SpecList from './specList';
import classes from './styles.module.scss';

const Heading = () => {
  return (
    <div className={classes.wrapper}>
      <h3 className={classes.heading}>Token specs</h3>
      <SpecList />
    </div>
  );
};

export default Heading;
