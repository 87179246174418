// @ts-nocheck
import React from 'react';
import CardItem from './cardItem';
import classes from './styles.module.scss';

const HeaderCards = ({ apy, stakes, reward, tvl }) => {
  return (
    <div className={classes.wrapper}>
      <CardItem label="Current APY" value={apy} add="%" />
      <CardItem label="Number of Stakers" value={stakes} />
      <CardItem label="TVL" value={tvl} add="QIE" />
      <CardItem label="Total Rewards" value={reward} add="QIDEX" />
    </div>
  );
};

export default HeaderCards;
