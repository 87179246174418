import React, { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { useDispatch } from 'react-redux';
import SideMenu from '../sideMenu';
import PageHeader from './pageHeader';
import PageHeaderMobile from './pageHeaderMobile';
import PageFooter from '../pageFooter';
import { setActiveIndex } from '../../state/exchange/slice';
// @ts-ignore
import classes from './styles.module.scss';

const PageWrapper = ({ heading, children }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setActiveIndex(0));
  }, [dispatch]);

  return (
    <div className={classes.wrapper}>
      <PageHeaderMobile />
      <div className={classes.contentWrapper}>
        <SideMenu />
        <div className={classes.mainContent}>
          <PageHeader heading={heading} />
          <div className={classes.pageContent}>{children}</div>
        </div>
      </div>
      <PageFooter />
      <ToastContainer />
    </div>
  );
};

export default PageWrapper;
