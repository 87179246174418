import React from 'react';
import { useSelector } from 'react-redux';
import { setActiveIndex } from 'state/exchange/slice';
import CardToolbar from './cardToolbar';
import classes from './styles.module.scss';
import PageNav from '../pageNav';
import ROUTES from '../../constants/routes';

type StateType = {
  exchange: {
    activeIndex: number;
  };
};

const CardNavNew = () => {
  const activeIndex = useSelector((state: StateType) => state.exchange.activeIndex);

  return (
    <div className={classes.wrapper}>
      <div className={classes.navWrapper}>
        <PageNav
          activeIndex={activeIndex}
          tabs={['Swap', 'Liquidity']}
          setState={setActiveIndex}
          route={ROUTES.TRADE}
        />
      </div>
      <CardToolbar />
    </div>
  );
};

export default CardNavNew;
