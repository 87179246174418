import { createSlice } from '@reduxjs/toolkit';

const sideMenuSlice = createSlice({
  name: 'sideMenu',
  initialState: {
    isActive: true,
  },
  reducers: {
    setIsActive(state, action) {
      state.isActive = action.payload;
    },
  },
});

export default sideMenuSlice.reducer;
export const { setIsActive } = sideMenuSlice.actions;
