import React from 'react';
import { Currency } from '@pancakeswap-libs/sdk';
import CurrencyLogo from 'components/CurrencyLogo';
import QIicon from 'assets/images/qi-icon.png';
import classes from './styles.module.scss';

type Props = {
  token?: Currency;
  label: string | undefined;
  func: (currency) => void;
};

const ItemFav = ({ token, label, func }: Props) => {
  return (
    <button type="button" className={classes.wrapper} onClick={func}>
      {token ? <CurrencyLogo currency={token} size="24px" /> : <img src={QIicon} alt={`${label} icon`} />}
      <div className={classes.label}>{label}</div>
    </button>
  );
};

export default ItemFav;
