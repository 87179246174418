import { Interface } from '@ethersproject/abi';
import getWeb3ContractObject from '../getWeb3ContractObject';
import MultiCallAbi from '../../constants/abis/Multicall.json';
import { mulltiCallAddressQi, Default_RPC_URL } from '../../constants';

const multicall = async (abi, calls) => {
  const multi = await getWeb3ContractObject(
    MultiCallAbi,
    mulltiCallAddressQi, //   "0x32F8647b2ff89B454170A25037D7643d219d6A19";
    Default_RPC_URL
  );
  const itf = new Interface(abi);
  const calldata = calls.map((call) => [call.address.toLowerCase(), itf.encodeFunctionData(call.name, call.params)]);
  const { returnData } = await multi.methods.aggregate(calldata).call();
  const res = returnData.map((call, i) => itf.decodeFunctionResult(calls[i].name, call));
  return res;
};

export default multicall;
