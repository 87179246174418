// @ts-nocheck

import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import swapInfoApi from 'services/endpoints';
import formatValue from 'utils/formatNumber';
import CoinSwitcher from './coinSwitcher';
import classes from './styles.module.scss';

const CurrentValueBlock = ({
  data,
  priceInverted,
  tradeValue,
  pairAddress,
  inputValue,
  outputValue,
  setPriceInverted,
}) => {
  const [volumeData, setVolumeData] = useState();
  const [values, setValues] = useState({ value: 0, difference: 0 });
  const [volumeString, setVolumeString] = useState('');

  const getVolumeData = async () => {
    if (pairAddress) {
      try {
        const res = await swapInfoApi.getVolume(pairAddress);

        // @ts-ignore
        setVolumeData(Object.values(res.data)[0]);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const countValues = () => {
    if (data.length) {
      setValues({
        value: data[data.length - 1].toFixed(4),
        difference: priceInverted
          ? (((1 / tradeValue) * 100) / data[0] - 100).toFixed(2)
          : ((tradeValue * 100) / data[0] - 100).toFixed(2),
      });
    }
  };

  useEffect(() => {
    getVolumeData();
  }, [priceInverted, pairAddress]); // eslint-disable-line

  useEffect(() => {
    countValues();
  }, [data]); // eslint-disable-line

  useEffect(() => {
    const interval = setInterval(() => {
      getVolumeData();
    }, 60000);
    return () => clearInterval(interval);
  }, []); // eslint-disable-line

  // @ts-ignore
  const getVolumeString = () => {
    if (volumeData?.base_volume) {
      if (!priceInverted) {
        setVolumeString(`${formatValue(Number(volumeData?.base_volume).toFixed(4))} ${volumeData?.base_symbol}`);
      } else {
        setVolumeString(`${formatValue(Number(volumeData?.quote_volume).toFixed(4))} ${volumeData?.quote_symbol}`);
      }
    }
  };

  useEffect(() => {
    getVolumeString();
  }, [volumeData]); // eslint-disable-line

  const percentClass = () =>
    classnames({
      [classes.percent]: true,
      [classes.percentPos]: values?.difference >= 0,
      [classes.percentNeg]: values?.difference < 0,
    });

  return (
    <div className={classes.wrapper}>
      <div className={classes.valueWrapper}>
        <div className={classes.numbersWrapper}>
          <p className={classes.value}>{formatValue(values?.value)}</p>
          <p className={percentClass()}>
            {values?.difference > 0
              ? `+${values?.difference}`
              : values?.difference === 0
              ? values?.difference
              : `${values?.difference}`}
            %
          </p>
        </div>
        <div className={classes.numbersWrapper}>
          <p className={classes.valueSmall}>
            <b>
              {priceInverted ? outputValue?.symbol : inputValue?.symbol}/
              {priceInverted ? inputValue?.symbol : outputValue?.symbol}
            </b>
          </p>
          <CoinSwitcher setPriceInverted={setPriceInverted} />
        </div>
      </div>
      <p className={classes.date}>24h volume: {volumeString}</p>
    </div>
  );
};

export default CurrentValueBlock;
