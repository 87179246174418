import React from 'react';
import { useHistory } from 'react-router-dom';
import BtnGradient from 'components/btnGradient';
import ButtonBorderGradient from 'components/ui/button/buttonBorderGradient';
import ROUTES from 'constants/routes';
import { ReactComponent as LogoIcon } from 'assets/images/logo.svg';
import classes from './styles.module.scss';

const Heading = ({ setModalState }) => {
  const history = useHistory();

  const handleClick = () => history.push(ROUTES.TRADE);

  return (
    <div className={classes.wrapper}>
      <div className={classes.logoBlock}>
        <div className={classes.icon}>
          <LogoIcon />
        </div>
        <h1 className={classes.logoText}>QIDex</h1>
      </div>
      <div className={classes.btnBlock}>
        <div className={classes.btnWrapper}>
          <ButtonBorderGradient text="Explainer Video" setState={() => setModalState(true)} />
        </div>
        <div className={classes.btnWrapper}>
          <BtnGradient text="Launch App" func={handleClick} />
        </div>
      </div>
    </div>
  );
};

export default Heading;
