const fromWeiDecimals = (balanceOfWei, decimals) => {
  if (balanceOfWei) {
    const balanceOfFromWei = Number(balanceOfWei.toString()) / 10 ** Number(decimals);

    return balanceOfFromWei;
  }

  return balanceOfWei;
};

export default fromWeiDecimals;
