import Web3 from 'web3';
import { Default_RPC_URL } from '../constants';

const getWeb3Obj = async (RPC_URL = Default_RPC_URL) => {
  const httpProvider = new Web3.providers.HttpProvider(RPC_URL);
  const web3 = await new Web3(httpProvider);
  return web3;
};

export default getWeb3Obj;
