import React, { useState } from 'react';
import classNames from 'classnames';
import { ReactComponent as CaretRight } from 'assets/images/caret-right-icon.svg';
import { ReactComponent as CaretDown } from 'assets/images/caret-down-icon.svg';
import classes from '../styles.module.scss';

const AccordionDifference = () => {
  const [isActive, setIsActive] = useState(false);

  const handleToggleVisibility = () => setIsActive(!isActive);

  const buttonClasses = () =>
    classNames({
      [classes.button]: true,
      [classes.buttonActive]: isActive,
    });

  const textClasses = () =>
    classNames({
      [classes.content]: true,
      [classes.contentActive]: isActive,
    });

  const breakLineClasses = () =>
    classNames({
      [classes.breakLine]: true,
      [classes.breakLineActive]: isActive,
    });

  return (
    <div>
      <button type="button" className={buttonClasses()} onClick={handleToggleVisibility}>
        <div className={classes.headingWrapper}>Difference between Adding Liquidity and Staking?</div>
        <div>{isActive ? <CaretDown /> : <CaretRight />}</div>
      </button>
      <p className={textClasses()}>
        Staking: Earn qidex token by depositing QIE. No minimum required. Staking is the process of locking up crypto
        holdings in order to obtain rewards which is the qidex token.
      </p>
      <br className={breakLineClasses()} />
      <br className={breakLineClasses()} />
      <p className={textClasses()}>
        Liquidity: Create liquidity for new token pairs listing on qidex exchange. Minimum of 20,000 QIE tokens need to
        be staked. The ease of which a digital token can be converted into another digital token without affecting the
        price.
      </p>
      <br className={breakLineClasses()} />
      <br className={breakLineClasses()} />
      <p className={textClasses()}>Users received lower trading fee when staking 30,000 QIE or more.</p>
      <br className={breakLineClasses()} />
      <br className={breakLineClasses()} />
      <p className={textClasses()}>
        Minimum staking period is 365 days. NB! So, USERS need to stake 20,000 QIE in order to list a token for trading.
        A measure to protect other liquidity providers.
      </p>
    </div>
  );
};

export default AccordionDifference;
