import React from 'react';
import { NavLink } from 'react-router-dom';
import classes from './styles.module.scss';

const NavItem = ({ Icon, label, link, isFull, extlink }) => {
  return (
    <>
      {extlink ? (
        <a href={link} target="_blank" rel="noreferrer" className={classes.link}>
          <div className={classes.wrapper}>
            <div className={classes.icon}>{!!Icon && <Icon />}</div>
            {isFull && <div className={classes.label}>{label}</div>}
          </div>
        </a>
      ) : (
        <NavLink to={link} className={classes.link} activeClassName={classes.activeLink}>
          <div className={classes.wrapper}>
            <div className={classes.icon}>{!!Icon && <Icon />}</div>
            {isFull && <div className={classes.label}>{label}</div>}
          </div>
        </NavLink>
      )}
    </>
  );
};

export default NavItem;
