import React, { useRef } from 'react';

import Modal from 'react-modal';
import SlidingPane from 'react-sliding-pane';
import { RemoveScroll } from 'react-remove-scroll';
import { ReactComponent as CloseIcon } from 'assets/images/closeIcon.svg';
import { ReactComponent as WalletIcon } from 'assets/images/wallet-icon.svg';
import { useActiveWeb3React } from '../../../../hooks';
import NavBlock from './navBlock';
import classes from './styles.module.scss';
import Wallet from '../../../wallet';
import WalletConnect from '../../../walletConnect';
import PageFooter from '../../../pageFooter';
import BuyButton from '../../../ui/button/buyButton';

const paymentSettings = {
  apiKey: '0a2236fc-af87-4939-bb34-9eb3f0280e77',
  colors: {
    main: '#453fd6',
    background: '#100f0f',
  },
};

const paymentStyles = {
  content: {
    width: 'fit-content',
    height: 'fit-content',
  },
};

const BurgerMenu = ({ state, setState, isLogged }) => {
  const [modalIsOpen, setIsOpen] = React.useState(false);

  const ref = useRef();

  const { account } = useActiveWeb3React();

  const defaultValues = {
    targetAddress: {
      value: account,
      editable: true,
    },
  };

  const openModal = () => setIsOpen(true);

  const closeModal = () => setIsOpen(false);

  const openPaymentForm = async () => {
    await openModal();

    const widget = new window.SwipeluxWidget(ref.current, { ...paymentSettings, defaultValues });
    widget.init();
  };

  return (
    <>
      <SlidingPane
        from="left"
        className="modal-burger"
        overlayClassName="overlay-side-burger"
        isOpen={state}
        onRequestClose={() => setState(!state)}
        hideHeader
      >
        <div className={classes.burgerMenu}>
          <div className={classes.wrapper}>
            <button type="button" onClick={() => setState(!state)}>
              <CloseIcon />
            </button>
            <RemoveScroll className={classes.contentWrapper}>
              <NavBlock />
              <div className={classes.buttonsBlock}>
                <div className={classes.buyBtn}>
                  <BuyButton onClick={openPaymentForm} />
                </div>
                {isLogged ? (
                  <Wallet />
                ) : (
                  <div className={classes.btnWrapper}>
                    <WalletConnect icon={WalletIcon} />
                  </div>
                )}
              </div>
            </RemoveScroll>
          </div>
          <div>
            <PageFooter />
          </div>
        </div>
      </SlidingPane>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        style={paymentStyles}
        className="paymentModal"
        ariaHideApp={false}
      >
        <div ref={ref} className={classes.payment} />
      </Modal>
    </>
  );
};

export default BurgerMenu;
