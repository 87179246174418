import React from 'react';
import LogoIcon from 'assets/images/logo.svg';
import classes from './styles.module.scss';

const LogoBlock = ({ isFull }) => {
  return (
    <div className={classes.wrapper}>
      <div className={classes.logoIcon}>
        <img src={LogoIcon} alt="logo icon" />
      </div>
      {isFull && <div className={classes.logoText}>QIdex</div>}
    </div>
  );
};

export default LogoBlock;
