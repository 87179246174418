import React from 'react';
import Header from './header';
import TokenCard from './tokenCard';
import classes from './styles.module.scss';

const TokenSwapInfo = ({ inputTokenInfo, outputTokenInfo }) => {
  return (
    <div className={classes.wrapper}>
      <Header />
      <div className={classes.cardWrapper}>
        <TokenCard
          tokenName={inputTokenInfo?.symbol}
          tokenDescription={inputTokenInfo?.name}
          releaseTime=""
          issuePrice=""
          circulationValue=""
          supplyValue={inputTokenInfo?.totalSupply}
          website={inputTokenInfo?.website}
          whitepaper={inputTokenInfo?.whitePaper}
          text={inputTokenInfo?.description}
          decimal={inputTokenInfo?.decimals}
          status={inputTokenInfo?.status}
        />
        <TokenCard
          tokenName={outputTokenInfo?.symbol}
          tokenDescription={outputTokenInfo?.name}
          releaseTime=""
          issuePrice=""
          circulationValue=""
          supplyValue={outputTokenInfo?.totalSupply}
          website={outputTokenInfo?.website}
          whitepaper={outputTokenInfo?.whitePaper}
          text={outputTokenInfo?.description}
          decimal={outputTokenInfo?.decimals}
          status={outputTokenInfo?.status}
        />
      </div>
    </div>
  );
};

export default TokenSwapInfo;
