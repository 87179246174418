import React from 'react';
import classnames from 'classnames';
import { ReactComponent as ArrowIcon } from 'assets/images/ArrowLeft.svg';
import classes from './styles.module.scss';

const MenuCtrl = ({ isFull, setState }) => {
  const arrowClass = () =>
    classnames({
      [classes.arrow]: !isFull,
    });

  return (
    <button type="button" className={classes.wrapper} onClick={setState}>
      <div className={arrowClass()}>
        <ArrowIcon />
      </div>
      {isFull && <div className={classes.text}>Close menu</div>}
    </button>
  );
};

export default MenuCtrl;
