import React from 'react';
import { Link } from 'react-router-dom';
import InputHeadingWrapper from 'components/inputHeadingWrapper';
import Checkbox from 'components/ui/checkbox';
import ROUTES from 'constants/routes';
import Heading from '../heading';
import BlockWrapper from '../blockWrapper';
import SelectInput from './selectInput';
import config from './config';
import classes from './styles.module.scss';

const FeatureInput = ({ selectHandler, checkboxMintValue, checkboxBurnValue, checkboxTermsValue, checkBoxHandler }) => {
  return (
    <BlockWrapper>
      <Heading text="Token Features" />
      <div className={classes.wrapper}>
        <InputHeadingWrapper name="Supply Type">
          <SelectInput options={config.supply} name="supplyType" selectHandler={selectHandler} />
        </InputHeadingWrapper>
        <InputHeadingWrapper name="Access Type">
          <SelectInput options={config.access} name="accessType" selectHandler={selectHandler} />
        </InputHeadingWrapper>
        <div className={classes.checkboxBlock}>
          <div className={classes.checkboxWrapper}>
            <Checkbox
              id="token-feature-option-burn"
              name="burnable"
              checkStatus={checkboxBurnValue}
              value="burnable"
              checkBoxHandler={checkBoxHandler}
            >
              <div className={classes.textWrapper}>
                <p className={classes.text}>Burnable</p>
                <p className={classes.subtext}>Your Token can be burnt</p>
              </div>
            </Checkbox>
          </div>
          <div className={classes.checkboxWrapper}>
            <Checkbox
              id="token-feature-option-mint"
              name="mintable"
              checkStatus={checkboxMintValue}
              value="burnable"
              checkBoxHandler={checkBoxHandler}
            >
              <div className={classes.textWrapper}>
                <p className={classes.text}>Mintable</p>
                <p className={classes.subtext}>You will be able to generate tokens by minting them</p>
              </div>
            </Checkbox>
          </div>
          <div className={classes.checkboxWrapper}>
            <Checkbox
              id="token-feature-option-terms"
              name="defaultCheck"
              checkStatus={checkboxTermsValue}
              value="burnable"
              checkBoxHandler={checkBoxHandler}
            >
              <div className={classes.textWrapper}>
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                <p className={classes.text}>
                  I have read, understood and agreed to Token Generator's{' '}
                  <Link className={classes.link} to={ROUTES.TERMS}>
                    Terms of Use.
                  </Link>
                </p>
              </div>
            </Checkbox>
          </div>
        </div>
      </div>
    </BlockWrapper>
  );
};

export default FeatureInput;
