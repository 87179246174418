import React from 'react';
import { ReactComponent as SearchIcon } from 'assets/images/search-icon.svg';
import classesCommon from '../styles.module.scss';
import classes from './styles.module.scss';

const InputText = ({ placeholder, handler }) => {
  return (
    <div className={classesCommon.wrapper}>
      <input className={classesCommon.input} type="text" placeholder={placeholder} onChange={handler} />
      <div className={classes.icon}>
        <SearchIcon />
      </div>
    </div>
  );
};

export default InputText;
