import { ChainId, JSBI, Percent, Token, WETH } from '@pancakeswap-libs/sdk';

// import { bsc, fortmatic, injected, portis, walletconnect, walletlink } from '../connectors'
// TODO
export const ROUTER_ADDRESS = '0x1a556530bb46c8eC4cdf8c750925952CF89d8E90';

// a list of tokens by chain
type ChainTokenList = {
  readonly [chainId in ChainId]: Token[];
};

export const DAI = new Token(
  ChainId.MAINNET,
  '0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3',
  18,
  'DAI',
  'Dai Stablecoin'
);
export const BUSD = new Token(ChainId.MAINNET, '0xe9e7cea3dedca5984780bafc599bd69add087d56', 18, 'BUSD', 'Binance USD');
export const USDT = new Token(ChainId.MAINNET, '0x55d398326f99059ff775485246999027b3197955', 18, 'USDT', 'Tether USD');
export const EOS = new Token(ChainId.MAINNET, '0x56b6fb708fc5732dec1afc8d8556423a2edccbd6', 18, 'EOS', 'EOS Token');
export const DOT = new Token(
  ChainId.MAINNET,
  '0x7083609fce4d1d8dc0c979aab8c869ea2c873402',
  18,
  'DOT',
  'Polkadot Token'
);
export const ETH = new Token(
  ChainId.MAINNET,
  '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
  18,
  'ETH',
  'Ethereum Token'
);

const WETH_ONLY: ChainTokenList = {
  [ChainId.MAINNET]: [WETH[ChainId.MAINNET]],
  [ChainId.BSCTESTNET]: [WETH[ChainId.BSCTESTNET]],
};

// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.MAINNET]: [...WETH_ONLY[ChainId.MAINNET], DAI, BUSD, USDT, EOS, DOT],
};

/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 */
export const CUSTOM_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {
  [ChainId.MAINNET]: {
    [ETH.address]: [DAI, WETH[ChainId.MAINNET]],
  },
};

// used for display in the default list when adding liquidity
export const SUGGESTED_BASES: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.MAINNET]: [...WETH_ONLY[ChainId.MAINNET], DAI, BUSD, USDT],
};

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.MAINNET]: [...WETH_ONLY[ChainId.MAINNET], DAI, BUSD, USDT],
};

export const PINNED_PAIRS: { readonly [chainId in ChainId]?: [Token, Token][] } = {
  [ChainId.MAINNET]: [
    [
      new Token(ChainId.MAINNET, '0xf75b23a09c6e126a2526e497517612825fd2a53c', 18, 'CAKE', 'PancakeSwap Token'),
      new Token(ChainId.MAINNET, '0x3aF492C875829B69a0803f4688C54fb867C193DF', 18, 'WBNB', 'Wrapped QIE'),
    ],
    [BUSD, USDT],
    [DAI, USDT],
  ],
};

// export interface WalletInfo {
//   connector?: AbstractConnector;
//   name: string;
//   iconName: string;
//   description: string;
//   href: string | null;
//   color: string;
//   primary?: true;
//   mobile?: true;
//   mobileOnly?: true;
// }

/*export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
  BSC: {
    connector: bsc,
    name: 'Binance Chain Wallet',
    iconName: 'binance.svg',
    description: 'Easy-to-use browser extension.',
    href: null,
    color: '#E8831D',
  },
  INJECTED: {
    connector: injected,
    name: 'Injected',
    iconName: 'arrow-right.svg',
    description: 'Injected web3 provider.',
    href: null,
    color: '#010101',
    primary: true,
  },
  METAMASK: {
    connector: injected,
    name: 'MetaMask',
    iconName: 'metamask.png',
    description: 'Easy-to-use browser extension.',
    href: null,
    color: '#E8831D',
  },
  // WALLET_CONNECT: {
  //   connector: walletconnect,
  //   name: 'WalletConnect',
  //   iconName: 'walletConnectIcon.svg',
  //   description: 'Connect to Trust Wallet, Rainbow Wallet and more...',
  //   href: null,
  //   color: '#4196FC',
  //   mobile: true
  // },
  // WALLET_LINK: {
  //   connector: walletlink,
  //   name: 'Coinbase Wallet',
  //   iconName: 'coinbaseWalletIcon.svg',
  //   description: 'Use Coinbase Wallet app on mobile device',
  //   href: null,
  //   color: '#315CF5'
  // },
  // COINBASE_LINK: {
  //   name: 'Open in Coinbase Wallet',
  //   iconName: 'coinbaseWalletIcon.svg',
  //   description: 'Open in Coinbase Wallet app.',
  //   href: 'https://go.cb-w.com/mtUDhEZPy1',
  //   color: '#315CF5',
  //   mobile: true,
  //   mobileOnly: true
  // },
  // FORTMATIC: {
  //   connector: fortmatic,
  //   name: 'Fortmatic',
  //   iconName: 'fortmaticIcon.png',
  //   description: 'Login using Fortmatic hosted wallet',
  //   href: null,
  //   color: '#6748FF',
  //   mobile: true
  // },
  // Portis: {
  //   connector: portis,
  //   name: 'Portis',
  //   iconName: 'portisIcon.png',
  //   description: 'Login using Portis hosted wallet',
  //   href: null,
  //   color: '#4A6C9B',
  //   mobile: true
  // }
};*/

export const NetworkContextName = 'NETWORK';

// default allowed slippage, in bips
export const INITIAL_ALLOWED_SLIPPAGE = 80;
// 20 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 20;

// one basis point
export const ONE_BIPS = new Percent(JSBI.BigInt(1), JSBI.BigInt(10000));
export const BIPS_BASE = JSBI.BigInt(10000);
// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(JSBI.BigInt(100), BIPS_BASE); // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(JSBI.BigInt(300), BIPS_BASE); // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(JSBI.BigInt(500), BIPS_BASE); // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(JSBI.BigInt(1000), BIPS_BASE); // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(JSBI.BigInt(1500), BIPS_BASE); // 15%

// used to ensure the user doesn't send so much ETH so they end up with <.01
export const MIN_ETH: JSBI = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(16)); // .01 ETH
export const BETTER_TRADE_LINK_THRESHOLD = new Percent(JSBI.BigInt(75), JSBI.BigInt(10000));

export const Default_RPC_URL = 'https://rpc-main2.qiblockchain.online';
export const ShieldexStakingContracAddress = '0x5163c4C24cEb5EBF75795986f8E8181AAfc5E38b';
export const mulltiCallAddressQi = '0x8304E6187ceABc5028C7Fc990B3342fEC239Df8E';
export const ACTIVE_NETWORK = 5656;
export const factoryContractAdress = '0xadfB415e03dB95f92041096A1e4aa6B80f42178c';
export const explorerURL = 'https://mainnet.qiblockchain.online';
export const NetworkDetails = [
  {
    chainId: '0x6c4',
    chainName: 'qi-blockchain',
    nativeCurrency: {
      name: 'qi-blockchain',
      symbol: 'qi',
      decimals: 18,
    },

    rpcUrls: [Default_RPC_URL],
    blockExplorerUrls: [explorerURL],
  },
];
export const AdminOwner = '0x3187ECa49599e4bE074a56AcB9396aECB9D3Ab1e';
export const pancakeRouter = '0x1a556530bb46c8eC4cdf8c750925952CF89d8E90'; // Need to change for main net
export const DividentTokenAddress = '0x33bb21418489754FE2023C6ad8561699dDCba4DB'; // Need to change for main net
export const ammFactory = '0xadfB415e03dB95f92041096A1e4aa6B80f42178c';
export const wbnbAddress = '0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd';
export const deadAddress = '0x0000000000000000000000000000000000000000';
export const deadAddressDead = '0x000000000000000000000000000000000000dEaD';
