import React from 'react';
import config from 'routes/config';
import NavItem from './navItem';
import classes from './styles.module.scss';

const NavBlock = () => {
  return (
    <div className={classes.wrapper}>
      {config.map((navItem) => (
        <NavItem
          key={navItem.id}
          label={navItem.label}
          Icon={navItem.icon}
          link={navItem.href}
          extlink={navItem.extlink}
        />
      ))}
    </div>
  );
};

export default NavBlock;
