import React from 'react';
import InputQI from 'components/inputQI';
import classes from './styles.module.scss';
import BtnGradient from '../../../../../components/btnGradient';
import formatValue from '../../../../../utils/formatNumber';

const InputStake = ({ heading, valueAvailable, value, setValue, maxFunc, disabled, btnFunc, stakeError }) => {
  return (
    <div className={classes.wrapper}>
      <div className={classes.inputWrapper}>
        <div className={classes.headingWrapper}>
          <h6 className={classes.heading}>Stake QIE20</h6>
          <div className={classes.valueWrapper}>
            <p>
              Available for <span>{heading}</span>:&nbsp;
            </p>
            <span className={classes.value}>{formatValue(valueAvailable)}</span>
            <p>&nbsp;QIE</p>
          </div>
        </div>
        <InputQI value={value} setValue={setValue} error={stakeError}>
          {stakeError ? (
            <p className={classes.error}>{stakeError}</p>
          ) : (
            <div className={classes.buttonWrapper}>
              <button type="button" className={classes.button} onClick={maxFunc}>
                Max
              </button>
            </div>
          )}
        </InputQI>
      </div>
      <div className={classes.buttonGradientWrapper}>
        <BtnGradient text={heading} disabled={disabled} func={btnFunc} />
      </div>
    </div>
  );
};

export default InputStake;
