// @ts-nocheck

import { CurrencyAmount, currencyEquals, JSBI, Trade } from '@pancakeswap-libs/sdk';
import axios from 'axios';
import BigNumber from 'bignumber.js';
import PageWrapper from 'components/pageWrapper';
import IERC20ABI from 'constants/abis/IERC20ABI.json';
import KingShibaStakingABI from 'constants/abis/KingShibaStakingABI.json';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAccount } from 'wagmi';
import Web3 from 'web3';
import { Default_RPC_URL, ShieldexStakingContracAddress } from '../../constants';
import { usePair } from '../../data/Reserves';
import { useAllTokens, useCurrency } from '../../hooks/Tokens';
import { ApprovalState, useApproveCallbackFromTrade } from '../../hooks/useApproveCallback';
import { useSwapCallback } from '../../hooks/useSwapCallback';
import useToggledVersion, { Version } from '../../hooks/useToggledVersion';
import useWrapCallback, { WrapType } from '../../hooks/useWrapCallback';
import { useEthersSigner, useProvider } from '../../hooks/v2/ethers-adapters';
import { Field } from '../../state/swap/actions';
import {
  useDefaultsFromURLSearch,
  useDerivedSwapInfo,
  useSwapActionHandlers,
  useSwapState,
} from '../../state/swap/hooks';
import { usePairAdder, useUserDeadline, useUserSlippageTolerance } from '../../state/user/hooks';
import { useCurrencyBalance } from '../../state/wallet/hooks';
import formatValue from '../../utils/formatNumber';
import fromWeiDecimals from '../../utils/fromWeiDecimals';
import getWeb3Obj from '../../utils/getWeb30bj';
import getWeb3ContractObject from '../../utils/getWeb3ContractObject';
import maxAmountSpend from '../../utils/maxAmountSpend';
import multicall from '../../utils/multicall';
import { computeSlippageAdjustedAmounts, computeTradePriceBreakdown, warningSeverity } from '../../utils/prices';
import useDebouncedChangeHandler from '../../utils/useDebouncedChangeHandler';
import { getContractV2 } from '../../utils/v2/getContracts';
import Content from './content';
import HeaderCards from './headerCards';
import classes from './styles.module.scss';
import ErrorField from "../../components/errorField";

const Stake = () => {
  const { address: account } = useAccount();
  const signer = useEthersSigner();
  const [stakedAmountMin, setstakedAmountMin] = useState('');
  const [APRYearlyS, setAPRYearlyS] = useState();
  const [unstakedAmountMin, setUnstakedAmountMin] = useState('');
  const [stakeloader2, setstakeloader2] = useState();
  const [stakeloader, setstakeloader] = useState();
  const [stakeloader1, setstakeloader1] = useState();
  const [pendingRewards, setPendingRewards] = useState();
  const [rewad, setrewad] = useState();
  const [rewardsPerBlock, setRewardsPerBlock] = useState(0);
  const [balanceStakeToken, setBalanceStakeToken] = useState(0);
  const [rewardSymbolStakeToken, setRewardSymbolStakeToken] = useState();
  const [getStakedData, setGetStakedData] = useState();
  const [userInfos, setUserInfos] = useState('');
  const [chackbalanceun, setChackbalanceun] = useState('');
  const [rewardPerBlockToken, setRewardPerBlockToken] = useState('');
  const [rewardTokenAddress, setrewardTokenAddress] = useState('');
  const [stakeTokenAddress, setstakeTokenAddress] = useState('');
  const [rewardPerBlockAPY, setRewardPerBlockAPY] = useState('');
  const [availableRewardTokens, setAvailableRewardTokens] = useState('');
  const [balanceValue, setBalanceValue] = useState(0);
  const [errorStake, setErrorStake] = useState('');
  const [errorUnstake, setErrorUnstake] = useState('');
  const [errorCalculator, setErrorCalculator] = useState('');
  const loadedUrlParams = useDefaultsFromURLSearch();
  const allTokens = useAllTokens();
  const provider = useProvider();

  // token warning stuff
  const [loadedInputCurrency, loadedOutputCurrency] = [
    useCurrency(loadedUrlParams?.inputCurrencyId),
    useCurrency(loadedUrlParams?.outputCurrencyId),
  ];

  // get custom setting values for user
  const [deadline] = useUserDeadline();
  const [allowedSlippage] = useUserSlippageTolerance();

  // swap state
  const { independentField, typedValue, recipient } = useSwapState();
  const {
    v1Trade,
    v2Trade,
    currencyBalances,
    parsedAmount,
    currencies,
    inputError: swapInputError,
  } = useDerivedSwapInfo();
  const { wrapType, execute: onWrap, inputError: wrapInputError } = useWrapCallback(
    currencies[Field.INPUT],
    currencies[Field.OUTPUT],
    typedValue
  );
  const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE;
  //   const { address: recipientAddress } = useENSAddress(recipient)
  const toggledVersion = useToggledVersion();
  const trade = showWrap
    ? undefined
    : {
        [Version.v1]: v1Trade,
        [Version.v2]: v2Trade,
      }[toggledVersion];

  const parsedAmounts = showWrap
    ? {
        [Field.INPUT]: parsedAmount,
        [Field.OUTPUT]: parsedAmount,
      }
    : {
        [Field.INPUT]: independentField === Field.INPUT ? parsedAmount : trade?.inputAmount,
        [Field.OUTPUT]: independentField === Field.OUTPUT ? parsedAmount : trade?.outputAmount,
      };

  const { onSwitchTokens, onCurrencySelection, onUserInput, onChangeRecipient } = useSwapActionHandlers();
  const isValid = !swapInputError;
  const dependentField: Field = independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT;
  const [pairState, pair] = usePair(currencies[Field.INPUT] ?? undefined, currencies[Field.OUTPUT] ?? undefined);
  const addPair = usePairAdder();

  // modal and loading
  const [{ showConfirm, tradeToConfirm, swapErrorMessage, attemptingTxn, txHash }, setSwapState] = useState<{
    showConfirm: boolean;
    tradeToConfirm: Trade | undefined;
    attemptingTxn: boolean;
    swapErrorMessage: string | undefined;
    txHash: string | undefined;
  }>({
    showConfirm: false,
    tradeToConfirm: undefined,
    attemptingTxn: false,
    swapErrorMessage: undefined,
    txHash: undefined,
  });

  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: showWrap
      ? parsedAmounts[independentField]?.toExact() ?? ''
      : parsedAmounts[dependentField]?.toSignificant(6) ?? '',
  };

  const route = trade?.route;
  const userHasSpecifiedInputOutput = Boolean(
    currencies[Field.INPUT] && currencies[Field.OUTPUT] && parsedAmounts[independentField]?.greaterThan(JSBI.BigInt(0))
  );

  // check whether the user has approved the router on the input token
  const [approval, approveCallback] = useApproveCallbackFromTrade(trade, allowedSlippage);

  // check if user has gone through approval process, used to show two step buttons, reset on token change
  const [approvalSubmitted, setApprovalSubmitted] = useState<boolean>(false);

  // mark when a user has submitted an approval, reset onTokenSelection for input field
  useEffect(() => {
    if (approval === ApprovalState.PENDING) {
      setApprovalSubmitted(true);
    }
  }, [approval, approvalSubmitted]);

  const maxAmountInput: CurrencyAmount | undefined = maxAmountSpend(currencyBalances[Field.INPUT]);

  // the callback to execute the swap
  const { callback: swapCallback, error: swapCallbackError } = useSwapCallback(
    trade,
    allowedSlippage,
    deadline,
    recipient
  );

  const { priceImpactWithoutFee, realizedLPFee } = computeTradePriceBreakdown(trade);

  const handleOutputSelect = useCallback(
    (outputCurrency) => {
      onCurrencySelection(Field.OUTPUT, outputCurrency);
    },
    [onCurrencySelection]
  );

  const slippageAdjustedAmounts = useMemo(() => computeSlippageAdjustedAmounts(trade, allowedSlippage), [
    trade,
    allowedSlippage,
  ]);

  const severity = warningSeverity(priceImpactWithoutFee);

  function tradeMeaningfullyDiffers(tradeA: Trade, tradeB: Trade): boolean {
    return (
      tradeA.tradeType !== tradeB.tradeType ||
      !currencyEquals(tradeA.inputAmount.currency, tradeB.inputAmount.currency) ||
      !tradeA.inputAmount.equalTo(tradeB.inputAmount) ||
      !currencyEquals(tradeA.outputAmount.currency, tradeB.outputAmount.currency) ||
      !tradeA.outputAmount.equalTo(tradeB.outputAmount)
    );
  }

  const showAcceptChanges = useMemo(
    () => Boolean(trade && tradeToConfirm && tradeMeaningfullyDiffers(trade, tradeToConfirm)),
    [tradeToConfirm, trade]
  );
  const selectedCurrencyBalanceInput = useCurrencyBalance(account ?? undefined, currencies[Field.INPUT] ?? undefined);
  const selectedCurrencyBalanceOutput = useCurrencyBalance(account ?? undefined, currencies[Field.OUTPUT] ?? undefined);

  useEffect(() => {
    handleOutputSelect(Object.values(allTokens)[0]);
  }, [allTokens, handleOutputSelect]);

  useEffect(() => {
    if (pair) {
      addPair(pair);
    }
  }, [currencies]); // eslint-disable-line

  const handleTypeInput = useCallback(
    (e) => {
      if (e?.target) {
        const cursor = e.target?.selectionStart;
        const parsedValue = e.target.value.replace(/,/g, '');

        if (/^\d*\.?\d*$/.test(parsedValue)) {
          onUserInput(Field.INPUT, parsedValue);
        }

        e.target.selectionStart = cursor;
      } else {
        onUserInput(Field.INPUT, e);
      }
    },
    [onUserInput, selectedCurrencyBalanceInput] // eslint-disable-line
  );

  useEffect(() => handleTypeInput('1'), []); // eslint-disable-line

  const stakingdexsymbole = async () => {
    const contractObj = await getWeb3ContractObject(
      KingShibaStakingABI,
      ShieldexStakingContracAddress,
      Default_RPC_URL
    );
    const rewaredtoken = await contractObj.methods.rewardToken().call();
    const contractObjsymbole = await getWeb3ContractObject(IERC20ABI, rewaredtoken, Default_RPC_URL);
    const rewaredtokensymbole = await contractObjsymbole.methods.symbol().call();
  };

  useEffect(() => {
    stakingdexsymbole();
  }, []);

  const GetBalance = async () => {
    try {
      const value = await web3.eth.getBalance(account);
      setBalanceValue(web3.utils.fromWei(value));
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (account) {
      GetBalance();
    }
  }, [account]);

  const httpProvider = new Web3.providers.HttpProvider(Default_RPC_URL);
  const web3 = new Web3(httpProvider);
  const getCoinBalanceAccount = async () => {
    try {
      const calls = [
        {
          address: ShieldexStakingContracAddress,
          name: 'owner',
        },
        {
          address: ShieldexStakingContracAddress,
          name: 'rewardPerBlock',
        },

        // {
        //   address: ShieldexStakingContracAddress,
        //   name: "pool",
        // },
        {
          address: ShieldexStakingContracAddress,
          name: 'totalUsers',
        },
        {
          address: ShieldexStakingContracAddress,
          name: 'totalStaked',
        },
      ];
      const [
        Owner,
        RewardPerBlock,
        // Pool,
        totalUsers,
        totalStaked,
        // usersLength,
      ] = await multicall(KingShibaStakingABI, calls);

      setrewardTokenAddress();
      // setstakeTokenAddress(Pool.stake);
      console.log('totalUsers++++', totalUsers[0].toString());
      const obj = {
        Owner,
        RewardPerBlock: web3.utils.fromWei(RewardPerBlock[0].toString()),
        totalUsers: totalUsers[0].toString(),
        totalStaked: web3.utils.fromWei(totalStaked[0].toString()),
      };
      console.log('obj++++', obj);
      setGetStakedData(obj);
      const contractObj = await getWeb3ContractObject(
        KingShibaStakingABI,
        ShieldexStakingContracAddress,
        Default_RPC_URL
      );
      console.log('contractObj+++12', contractObj);
      if (account) {
        // const balanceOf = await ercContract.methods.balanceOf(account).call();
        const BalanceStakeeToken = await web3.eth.getBalance(account);
        const datareward = await contractObj.methods.availableRewardTokens().call();
        console.log('datareward2', web3.utils.fromWei(datareward.toString()));
        // const datareward = web3.utils.fromWei(datareward.toString())

        setAvailableRewardTokens(web3.utils.fromWei(datareward.toString()));

        const userInfo = await contractObj.methods.userInfo(account).call();
        setUserInfos(userInfo);
        // setbalanceOf(web3.utils.fromWei(balanceOf.toString()));
        setBalanceStakeToken(web3.utils.fromWei(BalanceStakeeToken.toString()));

        setChackbalanceun(web3.utils.fromWei(userInfo?.amount.toString()));
      }

      const datareward = await contractObj.methods.availableRewardTokens().call();
      // console.log("datareward2", web3.utils.fromWei(datareward.toString()));
      // const datareward = web3.utils.fromWei(datareward.toString())

      setAvailableRewardTokens(web3.utils.fromWei(datareward.toString()));
    } catch (error) {
      console.log('ERROR', error);
    }
  };

  useEffect(() => {
    // console.log(user.isLogin);
    if (account) {
      getCoinBalanceAccount();
      getPoolStakeHandler();
    }
  }, [account]);

  useEffect(() => {
    getCoinBalanceAccount();
    getPoolStakeHandler();
  }, []);

  const getPoolStakeHandler = async () => {
    try {
      const web3 = await getWeb3Obj(Default_RPC_URL);

      const calls = [
        {
          address: ShieldexStakingContracAddress,
          name: 'rewardPerBlock',
        },
        {
          address: ShieldexStakingContracAddress,
          name: 'totalStaked',
        },
      ];

      const [rewardPerBlock, totalStaked] = await multicall(KingShibaStakingABI, calls);

      const contract = await new web3.eth.Contract(KingShibaStakingABI, ShieldexStakingContracAddress);
      const rewads = web3.utils.fromWei(rewardPerBlock.toString());
      setRewardsPerBlock(rewads);
      console.log('rew per block', rewardPerBlock.toString(), totalStaked.toString());
      let APYs = new BigNumber(rewads, 10);
      let qidexApy = APYs.multipliedBy(7200, 10)
        .multipliedBy(365, 10)
        .div(web3.utils.fromWei(totalStaked[0].toString()))
        .multipliedBy(100, 10)
        .toString();

      // @ts-ignore
      setrewad(qidexApy);
      if (account) {
        const pendingRewards = await contract.methods.pendingReward(account).call();
        const pendingRewardsETH = web3.utils.fromWei(pendingRewards.toString());
        console.log('+++++++For-TVL++++++', pendingRewardsETH);
        setPendingRewards(pendingRewardsETH);
        setPendingRewards12(pendingRewards);
        const userInfo = await contract.methods.userInfo(account).call();
        setUserStakedTokens(web3.utils.fromWei(userInfo.amount.toString()));
        setnextHarvestUntil(userInfo.nextHarvestUntil.toString());
        setnextWithdrawUntil(userInfo.nextWithdrawUntil.toString());
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getStakingDataHandler = async () => {
    try {
      setstakeloader(true);
      if (account) {
        const web3 = (window.web3 = new Web3(provider));
        // const contract = getContract(ShieldexStakingContracAddress, KingShibaStakingABI, library, account);
        const contract = getContractV2(ShieldexStakingContracAddress, KingShibaStakingABI, signer);

        const amountToSend = web3.utils.toWei(stakedAmountMin.toString());

        if (Number(balanceValue) > stakedAmountMin) {
          const ownerFun = await contract.deposit({
            value: amountToSend,
          });
          await ownerFun.wait();
          // user.GetTotalStakedFun();
          toast.success('Successfully Staked');
        } else {
          setstakeloader(false);
          toast.error('Low balance');
        }
      } else {
        toast.error('Please connect your wallet first!');
      }
      getPoolStakeHandler();
      setstakeloader(false);
    } catch (error) {
      setstakeloader(false);

      toast.error(error.message);
      console.log('error????', error);
    }

    getCoinBalanceAccount();
  };

  const getUnStakingDataHandler = async () => {
    const web3 = await getWeb3Obj();
    if (
      unstakedAmountMin &&
      unstakedAmountMin != '' &&
      Number(unstakedAmountMin) > 0 &&
      Number(unstakedAmountMin) <= chackbalanceun
    ) {
      try {
        if (account) {
          setstakeloader1(true);
          const contract = getContractV2(ShieldexStakingContracAddress, KingShibaStakingABI, signer);
          const amountToSend = web3.utils.toWei(unstakedAmountMin.toString());

          const ownerFun = await contract.withdraw(amountToSend);
          await ownerFun.wait();

          toast.success('Successfully Unstake');

          getPoolStakeHandler();
          setstakeloader1(false);
        } else {
          getPoolStakeHandler();
          setstakeloader1(false);
          toast.error('Please connect your wallet first!');
        }
        getPoolStakeHandler();
        setstakeloader1(false);
      } catch (error) {
        setstakeloader1(false);

        console.log(error);
        if (error.data) {
          toast.error(error.data.message);
        } else {
          toast.error(error.message);
        }
      }
    } else {
      toast.error('Please Enter valid data');
    }

    getCoinBalanceAccount();
  };

  const getClaimDataHandler = async () => {
    if (pendingRewards && Number(pendingRewards) > 0) {
      try {
        setstakeloader2(true);
        if (account) {
          const contract = getContractV2(ShieldexStakingContracAddress, KingShibaStakingABI, signer);

          const ownerFun = await contract.claimReward();

          await ownerFun.wait();
          toast.success('Rewards Claimed');
          setstakeloader2(false);
        } else {
          toast.error('Please connect your wallet first!');
          setstakeloader2(false);
        }
        getPoolStakeHandler();
        setstakeloader2(false);
      } catch (error) {
        setstakeloader2(false);
        console.log(error);
        if (error.data) {
          toast.error(error.data.message);
        } else {
          toast.error(error.message);
        }
      }
    } else {
      setstakeloader2(false);
      toast.error('Not enough balance to claim');
    }
    getCoinBalanceAccount();
    setstakeloader2(false);
  };

  const [lockPriceByPer, setLockPriceByPer] = useState('0');
  const [sliderPercent, setSliderPercent] = useState(0);

  const sliderPercentChangeCallback = useCallback(
    (value) => {
      console.log({ value });
      setSliderPercent(value);
      setErrorCalculator('');
    },
    [setSliderPercent]
  );

  const [formData, setFormData] = useDebouncedChangeHandler(
    Number.parseFloat(sliderPercent),
    sliderPercentChangeCallback
  );

  useEffect(() => {
    setLockPriceByPer(Number((parseFloat(balanceStakeToken) * parseFloat(formData)) / 100));
  }, [sliderPercent, balanceStakeToken]);

  /******************************************************** */
  /******************************************************** */
  useEffect(() => {
    if (rewardTokenAddress !== '') {
      myBNBpriceUSD(rewardTokenAddress);
    }
  }, [rewardTokenAddress, account]);
  const [stakeTokenPriceINUsd, setstakeTokenPrice] = useState(0);
  const myBNBpriceUSD = async (stakeTokenAddressId) => {
    axios({
      method: 'GET',
      url: `https://api.pancakeswap.info/api/v2/tokens/${stakeTokenAddressId}`,
    })
      .then(async (res) => {
        if (res.status === 200) {
          setstakeTokenPrice(res.data.data.price);

          // setState(1);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  useEffect(() => {
    if (stakeTokenAddress !== '') {
      myRewardTokenPrice(stakeTokenAddress);
    }
  }, [stakeTokenAddress, account]);

  const [rewardTokenPriceINUsd, setrewardTokenPrice] = useState(0);
  const myRewardTokenPrice = async (rewardTokenAddressId) => {
    axios({
      method: 'GET',
      url: `https://api.pancakeswap.info/api/v2/tokens/${rewardTokenAddressId}`,
    })
      .then(async (res) => {
        if (res.status === 200) {
          setrewardTokenPrice(res.data.data.price);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  /******************************************************** */
  /******************************************************** */
  const ROICalculateor = async () => {
    try {
      let totalStaked = Number(getStakedData?.totalStaked);
      // token price on pancake swap
      let stakeokenPrice = stakeTokenPriceINUsd ? stakeTokenPriceINUsd : 0.00001;
      let rewardTokenPrice = rewardTokenPriceINUsd ? rewardTokenPriceINUsd : 0.00001;
      //
      let rewardPerBlockTokens = Number(rewardsPerBlock) * 7200;
      setRewardPerBlockToken((rewardPerBlockTokens / totalStaked) * lockPriceByPer);
      setRewardPerBlockAPY(rewardPerBlockTokens / totalStaked);
      // Total value of reward token
      let totalvalueRewardToken = (Number(rewardsPerBlock) * 2600) / Number(totalStaked);
      // Total value of staked token
      let totalValueStakedToken = Number(totalStaked) * Number(stakeokenPrice);

      // APR for One year
      let APRYearly = (rewardPerBlockTokens * 365 * lockPriceByPer) / 10000;
      // ((Number(totalvalueRewardToken) * 365) / Number(totalValueStakedToken)) *
      // 100;
      let newAccToken = setAPRYearlyS(APRYearly);
      let LockAccToken = Number(lockPriceByPer.toString()) * totalvalueRewardToken;

      let devIdeed = Number(LockAccToken) / 1000000;

      let addSub = devIdeed - fromWeiDecimals(userInfos?.rewardDebt?.toString(), 18) + pendingRewards;
      let adaf =
        (Number(lockPriceByPer.toString()) * fromWeiDecimals(getStakedData?.accTokenPerShare1?.toString(), 18)) /
          fromWeiDecimals('1000000000000000000000000', 18) -
        (fromWeiDecimals(userInfos?.rewardDebt?.toString(), 18) + pendingRewards);
    } catch (error) {
      console.log(error);
    }
  };
  /******************************************************** */
  useEffect(() => {
    ROICalculateor(lockPriceByPer);
  }, [
    lockPriceByPer,
    getStakedData?.totalStaked,
    rewardsPerBlock,
    stakeTokenPriceINUsd,
    rewardTokenPriceINUsd,
    account,
  ]);

  console.log({ lockPriceByPer });
  console.log(Number(formattedAmounts[Field.OUTPUT]));
  console.log({ rewardPerBlockToken });
  console.log({ rewardPerBlockAPY });

  return (
    <>
      <Helmet>
        <title>Stake</title>
      </Helmet>
      <PageWrapper heading="Stake">
        <div className={classes.wrapper}>
          <HeaderCards
            apy={rewad ? parseFloat(rewad / formattedAmounts[Field.OUTPUT]).toFixed(4) : 0}
            stakes={getStakedData?.totalUsers ? getStakedData?.totalUsers : 0}
            reward={availableRewardTokens ? formatValue(availableRewardTokens) : 0}
            tvl={getStakedData ? formatValue(getStakedData?.totalStaked) : 0}
          />
          <ErrorField text="We are allocating 10% of the entry fees from the Pawsome game to support staking on Qidex" />
          <Content
            apy={rewad ? parseFloat(rewad).toFixed(4) : 0}
            stakeNumber={getStakedData?.totalUsers ? getStakedData?.totalUsers : 0}
            tvl={account ? (getStakedData ? parseFloat(getStakedData?.totalStaked).toFixed(4) : 0) : 0}
            daily={
              APRYearlyS
                ? parseFloat(Number(rewardPerBlockToken) * Number(formattedAmounts[Field.INPUT])).toFixed(4)
                : 0
            }
            dateMeasure={rewardSymbolStakeToken}
            monthly={
              APRYearlyS
                ? parseFloat(Number(rewardPerBlockToken) * 30 * Number(formattedAmounts[Field.INPUT])).toFixed(4)
                : 0
            }
            yearly={
              APRYearlyS
                ? parseFloat(Number(rewardPerBlockToken) * 365 * Number(formattedAmounts[Field.INPUT])).toFixed(4)
                : 0
            }
            stakeAvailable={account ? parseFloat(balanceStakeToken).toFixed(4) : '0'}
            unstakeAvailable={
              account
                ? userInfos
                  ? parseFloat(web3.utils.fromWei(userInfos?.available.toString())).toFixed(4)
                  : '0'
                : '0'
            }
            stakeValue={stakedAmountMin}
            setStakeValue={(e) => {
              const cursor = e.target?.selectionStart;
              const parsedValue = e.target.value.replace(/,/g, '');

              if (/^\d*\.?\d*$/.test(parsedValue)) {
                if (parsedValue[0] === '0' && parsedValue[1] === '0') {
                  setstakedAmountMin('0');
                } else if (parsedValue[0] === '0' && parsedValue[1] !== '.' && parsedValue.length > 1) {
                  setstakedAmountMin(parsedValue.replace(/^0+/, ''));
                } else {
                  setstakedAmountMin(parsedValue);
                }
              }

              if (
                parsedValue == 0 ||
                parsedValue == undefined ||
                parsedValue == '' ||
                parsedValue < 0.000001 ||
                parsedValue > 100000000000000000000 ||
                parseFloat(balanceStakeToken).toFixed(4) < parsedValue
              ) {
                setErrorStake('Enter correct amount');
              } else {
                setErrorStake('');
              }

              e.target.selectionStart = cursor;
            }}
            unstakeValue={unstakedAmountMin}
            setUnstakeValue={(e) => {
              const cursor = e.target?.selectionStart;
              const parsedValue = e.target.value.replace(/,/g, '');

              if (/^\d*\.?\d*$/.test(parsedValue)) {
                if (parsedValue[0] === '0' && parsedValue[1] === '0') {
                  setUnstakedAmountMin('0');
                } else if (parsedValue[0] === '0' && parsedValue[1] !== '.' && parsedValue.length > 1) {
                  setUnstakedAmountMin(parsedValue.replace(/^0+/, ''));
                } else {
                  setUnstakedAmountMin(parsedValue);
                }
              }

              if (
                parsedValue == 0 ||
                parsedValue == undefined ||
                parsedValue == '' ||
                parsedValue < 0.000001 ||
                parsedValue > 100000000000000000000 ||
                parsedValue > web3.utils.fromWei(userInfos?.available.toString())
              ) {
                setErrorUnstake('Enter correct amount');
              } else {
                setErrorUnstake('');
              }

              e.target.selectionStart = cursor;
            }}
            stakeMaxFunc={() => setstakedAmountMin(balanceStakeToken)}
            unstakeMaxFunc={() => setUnstakedAmountMin(web3.utils.fromWei(userInfos?.available.toString()))}
            disabledStake={
              stakedAmountMin == 0 ||
              stakedAmountMin == undefined ||
              stakedAmountMin == '' ||
              stakeloader ||
              stakeloader2 ||
              stakeloader1 ||
              stakedAmountMin < 0.000001 ||
              stakedAmountMin > 100000000000000000000 ||
              stakedAmountMin > parseFloat(balanceStakeToken).toFixed(4) ||
              parseFloat(balanceStakeToken).toFixed(4) === 0
            }
            stakeError={errorStake}
            disabledUnstake={
              userInfos?.available == 0 ||
              stakeloader1 ||
              stakeloader ||
              stakeloader2 ||
              unstakedAmountMin == '' ||
              unstakedAmountMin == 0 ||
              unstakedAmountMin == undefined ||
              unstakedAmountMin < 0.000001 ||
              unstakedAmountMin > 100000000000000000000 ||
              //              userInfos?.available > unstakedAmountMin
              userInfos?.available < unstakedAmountMin
            }
            unstakeError={errorUnstake}
            stakeSubmit={getStakingDataHandler}
            unstakeSubmit={getUnStakingDataHandler}
            calculatorValue={lockPriceByPer}
            setCalculatorValue={(e) => {
              const cursor = e.target?.selectionStart;
              const parsedValue = e.target.value.replace(/,/g, '');

              if (/^\d*\.?\d*$/.test(parsedValue)) {
                if (parsedValue) {
                  if (parsedValue[0] === '0' && parsedValue[1] === '0') {
                    setLockPriceByPer('0');
                  } else if (parsedValue[0] === '0' && parsedValue[1] !== '.' && parsedValue.length > 1) {
                    setLockPriceByPer(parsedValue.replace(/^0+/, ''));
                  } else {
                    setLockPriceByPer(parsedValue);
                  }

                  if (Number(parsedValue) > parseFloat(balanceStakeToken)) {
                    setErrorCalculator('Enter correct amount');
                  } else if (Number(parsedValue) === 0) {
                    setErrorCalculator('Enter correct amount');
                  } else {
                    setErrorCalculator('');
                    setFormData(Number.parseFloat((parseFloat(parsedValue) * 100) / parseFloat(balanceStakeToken)));
                  }
                } else {
                  setLockPriceByPer('0');
                  setFormData(0);
                }
              }

              e.target.selectionStart = cursor;
            }}
            errorCalculator={errorCalculator}
            rangeSliderValue={formData}
            setRangeSliderValue={setFormData}
            stakedValue={
              account ? (userInfos ? parseFloat(web3.utils.fromWei(userInfos?.amount.toString())).toFixed(4) : 0) : 0
            }
            stakedRewards={account ? (pendingRewards ? parseFloat(pendingRewards).toFixed(8) : 0) : 0}
            claimFunc={getClaimDataHandler}
            claimDisabled={pendingRewards == 0 || stakeloader || stakeloader2 || stakeloader1}
            // claimLogoutFunc={connectWallet}
          />
        </div>
      </PageWrapper>
    </>
  );
};

export default Stake;
