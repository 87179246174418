// @ts-nocheck

const formatValue = (value) => {
  if (!Number.isNaN(Number(value))) {
    if (typeof value !== 'number') {
      return Number(value).toLocaleString('en-US', { maximumFractionDigits: 4 });
    }
  }

  return '';
};

export default formatValue;
