import React, { useState } from 'react';
import classNames from 'classnames';
import { ReactComponent as CaretRight } from 'assets/images/caret-right-icon.svg';
import { ReactComponent as CaretDown } from 'assets/images/caret-down-icon.svg';
import classes from '../styles.module.scss';

const AccordionProsCons = () => {
  const [isActive, setIsActive] = useState(false);

  const handleToggleVisibility = () => setIsActive(!isActive);

  const buttonClasses = () =>
    classNames({
      [classes.button]: true,
      [classes.buttonActive]: isActive,
    });

  const textClasses = () =>
    classNames({
      [classes.content]: true,
      [classes.contentActive]: isActive,
    });

  const breakLineClasses = () =>
    classNames({
      [classes.breakLine]: true,
      [classes.breakLineActive]: isActive,
    });

  const listClasses = () =>
    classNames({
      [classes.list]: true,
      [classes.listActive]: isActive,
    });

  return (
    <div>
      <button type="button" className={buttonClasses()} onClick={handleToggleVisibility}>
        <div className={classes.headingWrapper}>Pros and Cons of Yield Farming?</div>
        <div>{isActive ? <CaretDown /> : <CaretRight />}</div>
      </button>
      <p className={textClasses()}>
        <b>Pros Explained</b>
      </p>
      <br className={breakLineClasses()} />
      <br className={breakLineClasses()} />
      <ul className={listClasses()}>
        <li className={classes.listItem}>
          <p className={textClasses()}>
            <b>- Potential to earn high interest rates online: </b>
            Yield farms may come with a potential to earn returns over 100% APY.
          </p>
        </li>
        <li className={classes.listItem}>
          <p className={textClasses()}>
            <b>- Managed by smart contracts: </b>
            Smart contracts take out middlemen and allow anyone to participate if they have a compatible cryptocurrency
            wallet.
          </p>
        </li>
        <li className={classes.listItem}>
          <p className={textClasses()}>
            <b>- Part of the global DeFi system: </b>
            New decentralized finance applications enable innovative financial products across international borders.
          </p>
        </li>
      </ul>
      <br className={breakLineClasses()} />
      <br className={breakLineClasses()} />
      <p className={textClasses()}>
        <b>Cons Explained</b>
      </p>
      <br className={breakLineClasses()} />
      <br className={breakLineClasses()} />
      <ul className={listClasses()}>
        <li className={classes.listItem}>
          <p className={textClasses()}>
            <b>- Risks of impermanent losses: </b>
            If a cryptocurrency goes down in value while you have it locked in a yield farm, your losses are called
            impermanent losses.
          </p>
        </li>
        <li className={classes.listItem}>
          <p className={textClasses()}>
            <b>- Scams and fraud: </b>
            As with other parts of the cryptocurrency ecosystem, bad actors are out to steal funds through fraudulent
            yield farms and other scams.{' '}
          </p>
        </li>
        <li className={classes.listItem}>
          <p className={textClasses()}>
            <b>- Tax reporting challenges: </b>
            If a cryptocurrency goes down in value while you have it locked in a yield farm, your losses are called
            impermanent losses.
          </p>
        </li>
      </ul>
    </div>
  );
};

export default AccordionProsCons;
