const config = {
  step: {
    value: 1,
    min: 0,
    max: 100,
  },
  labels: [
    {
      id: 0,
      value: '0',
    },
    {
      id: 1,
      value: '25%',
    },
    {
      id: 2,
      value: '50%',
    },
    {
      id: 3,
      value: '75%',
    },
    {
      id: 4,
      value: '100%',
    },
  ],
};

export default config;
