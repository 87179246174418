import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import classes from './styles.module.scss';

const PageNavItem = ({ text, index, indexItem, setActiveTab, route }) => {
  const dispatch = useDispatch();

  const wrapperClass = () =>
    classNames({
      [classes.wrapper]: true,
      [classes.wrapperActive]: index === indexItem,
    });

  const buttonClass = () =>
    classNames({
      [classes.button]: true,
      [classes.buttonActive]: index === indexItem,
    });

  const handleChange = () => dispatch(setActiveTab(indexItem));

  return (
    <div className={wrapperClass()}>
      <Link to={route} className={buttonClass()} onClick={handleChange}>
        {text}
      </Link>
    </div>
  );
};

export default PageNavItem;
