import { ReactComponent as HouseIcon } from 'assets/images/house-icon.svg';
import { ReactComponent as CrownIcon } from 'assets/images/crown-icon.svg';
import { ReactComponent as BadgeIcon } from 'assets/images/badge-icon.svg';
import { ReactComponent as LetterIcon } from 'assets/images/letter-icon.svg';
import { ReactComponent as ChipIcon } from 'assets/images/chip-icon.svg';

const config = [
  {
    id: 0,
    text: 'No mint function',
    Icon: HouseIcon,
  },
  {
    id: 1,
    text: 'No owner / admin functions',
    Icon: CrownIcon,
  },
  {
    id: 2,
    text: 'No unsafe code in the token contract itself',
    Icon: BadgeIcon,
  },
  {
    id: 3,
    text: 'Fully QIE20 compliant',
    Icon: LetterIcon,
  },
  {
    id: 4,
    text: 'Fully decentralised',
    Icon: ChipIcon,
  },
];

export default config;
