import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import PageFooter from 'components/pageFooter';
import Heading from './heading';
import MainBlock from './main';
import classes from './styles.module.scss';
import ModalVideo from './modalVideo';

const Home = () => {
  const [isModalActive, setIsModalActive] = useState(false);

  return (
    <>
      <Helmet>
        <title>QI Dex</title>
      </Helmet>
      <div className={classes.wrapper}>
        <Heading setModalState={setIsModalActive} />
        <MainBlock setModalState={setIsModalActive} />
        <PageFooter />
        <ModalVideo state={isModalActive} setState={setIsModalActive} />
      </div>
    </>
  );
};

export default Home;
