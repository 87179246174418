import React from 'react';
import ReactModal from 'react-modal';
import { ReactComponent as CloseIcon } from 'assets/images/closeIcon.svg';
import classes from './styles.module.scss';

const ModalWrapper = ({ state, children, heading, onClose }) => {
  return (
    <ReactModal
      isOpen={state}
      contentLabel="Modal"
      overlayClassName="overlay-custom"
      className="content-custom"
      onRequestClose={onClose}
      shouldCloseOnOverlayClick
      ariaHideApp={false}
    >
      <div className={classes.wrapper}>
        <div className={classes.headingWrapper}>
          <div className={classes.headingText}>{heading}</div>
          <button type="button" aria-label="Close" className={classes.closeIcon} onClick={onClose}>
            <CloseIcon />
          </button>
        </div>
        <>{children}</>
      </div>
    </ReactModal>
  );
};

export default ModalWrapper;
