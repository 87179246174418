import React from 'react';
import {useSelector} from 'react-redux';
import classNames from 'classnames';
import ErrorField from 'components/errorField';
import StakeBlock from './stakeBlock';
import CalculatorBlock from './calculatorBlock';
import CardList from './cardList';
import classes from './styles.module.scss';

type State = {
  sideMenu: {
    isActive: boolean;
  };
};

const Content = ({
                   apy,
                   stakeNumber,
                   tvl,
                   daily,
                   dateMeasure,
                   monthly,
                   yearly,
                   stakeAvailable,
                   unstakeAvailable,
                   stakeValue,
                   setStakeValue,
                   unstakeValue,
                   setUnstakeValue,
                   stakeMaxFunc,
                   unstakeMaxFunc,
                   disabledStake,
                   disabledUnstake,
                   stakeSubmit,
                   unstakeSubmit,
                   calculatorValue,
                   setCalculatorValue,
                   rangeSliderValue,
                   setRangeSliderValue,
                   stakedRewards,
                   stakedValue,
                   claimDisabled,
                   claimFunc,
                   claimLogoutFunc,
                   stakeError,
                   unstakeError,
                   errorCalculator,
                 }) => {
  const isFull = useSelector((state: State) => state.sideMenu.isActive);

  const wrapperClass = () =>
    classNames({
      [classes.contentWrapper]: true,
      [classes.widthSmall]: isFull,
      [classes.widthFull]: !isFull,
    });

  return (
    <div className={classes.wrapper}>
      <div className={wrapperClass()}>
        <div className={classes.inputsBlock}>
          <StakeBlock
            stakeAvailable={stakeAvailable}
            unstakeAvailable={unstakeAvailable}
            stakeValue={stakeValue}
            setStakeValue={setStakeValue}
            unstakeValue={unstakeValue}
            setUnstakeValue={setUnstakeValue}
            stakeMaxFunc={stakeMaxFunc}
            unstakeMaxFunc={unstakeMaxFunc}
            disabledStake={disabledStake}
            disabledUnstake={disabledUnstake}
            stakeSubmit={stakeSubmit}
            unstakeSubmit={unstakeSubmit}
            stakeError={stakeError}
            unstakeError={unstakeError}
          />
          <div className={classes.divider}>
            <CalculatorBlock
              daily={daily}
              dateMeasure={dateMeasure}
              monthly={monthly}
              yearly={yearly}
              calculatorValue={calculatorValue}
              setCalculatorValue={setCalculatorValue}
              rangeSliderValue={rangeSliderValue}
              setRangeSliderValue={setRangeSliderValue}
              errorCalculator={errorCalculator}
            />
          </div>
        </div>
        <CardList
          stakedRewards={stakedRewards}
          stakedValue={stakedValue}
          claimDisabled={claimDisabled}
          claimFunc={claimFunc}
          // claimLogoutFunc={claimLogoutFunc}
        />
        <ErrorField text="Funds will be locked for 365 days"/>
        <ErrorField text="You can check rewards from Pawsome: https://www.pawsome.host/stakeholder-rewards"/>
      </div>
    </div>
  );
};

export default Content;
