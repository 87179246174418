import React, { useState } from 'react';
import classnames from 'classnames';
import { ReactComponent as LogoIcon } from 'assets/images/logo.svg';
import { ReactComponent as BurgerMenuIcon } from 'assets/images/burger-menu-icon.svg';
import { ReactComponent as PlugIcon } from 'assets/images/plug-icon.svg';
import { ReactComponent as WalletIcon } from 'assets/images/wallet-icon.svg';
import { useAccount } from 'wagmi';
import Wallet from '../../wallet';
import BurgerMenu from './burgerMenu';
import classes from './styles.module.scss';

const PageHeaderMobile = () => {
  const [isWalletActive, setIsWalletActive] = useState(false);
  const [isMenuActive, setIsMenuActive] = useState(false);
  const { isConnected } = useAccount();

  const walletIcon = () =>
    classnames({
      [classes.iconActive]: isWalletActive,
    });

  return (
    <div className={classes.wrapper}>
      <div className={classes.headerWrapper}>
        <button type="button" onClick={() => setIsMenuActive(!isMenuActive)}>
          <BurgerMenuIcon />
        </button>
        <div className={classes.logoWrapper}>
          <div className={classes.logoIcon}>
            <LogoIcon />
          </div>
          <h1 className={classes.logoText}>QIdex</h1>
        </div>
        {isConnected ? (
          <button className={walletIcon()} type="button" onClick={() => setIsWalletActive(!isWalletActive)}>
            <WalletIcon />
          </button>
        ) : (
          <div>
            <PlugIcon />
          </div>
        )}
      </div>
      {isConnected && isWalletActive && (
        <div className={classes.walletWrapper}>
          <Wallet />
        </div>
      )}
      {isMenuActive && <BurgerMenu state={isMenuActive} setState={setIsMenuActive} isLogged={isConnected} />}
    </div>
  );
};

export default PageHeaderMobile;
