import React from 'react';
import { Currency } from '@pancakeswap-libs/sdk';
import CurrencyLogo from 'components/CurrencyLogo';
import QIicon from 'assets/images/logo-input.png';
import classes from './styles.module.scss';

type Props = {
  token?: Currency;
  label: string | undefined;
  description: string | undefined;
  func: (currency) => void;
};

const ListItem = ({ token, label, description, func }: Props) => {
  return (
    <button type="button" className={classes.wrapper} onClick={func}>
      {token?.name === 'QIE' ? (
        <img src={QIicon} alt={`${description} icon`} />
      ) : (
        <CurrencyLogo currency={token} size="24px" />
      )}
      <div>
        <div className={classes.label}>{label}</div>
        <div className={classes.description}>{description}</div>
      </div>
    </button>
  );
};

export default ListItem;
