import React from 'react';
import classnames from 'classnames';
import classesCommon from '../styles.module.scss';
import classes from './styles.module.scss';

type Props = {
  value?: number;
  children?: any;
  setValue?: any;
  minValue?: number;
  error?: any;
};

const InputNum = ({ value, minValue, children, setValue, error }: Props) => {
  const inputClass = () =>
    classnames({
      [classesCommon.input]: true,
      [classes.input]: true,
      [classesCommon.inputError]: error,
    });

  return (
    <div className={classesCommon.wrapper}>
      {children && <div className={classes.inputChildren}>{children}</div>}
      <input
        className={inputClass()}
        min={minValue}
        value={value}
        step={0.1}
        type="text"
        inputMode="decimal"
        placeholder="0.00"
        // pattern="^[0-9]*[.,]?[0-9]*$"
        onChange={setValue}
      />
    </div>
  );
};

export default InputNum;
