import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Helmet } from 'react-helmet';
import PageWrapper from 'components/pageWrapper';
import CardNavNew from 'components/CardNavNew';
import HistoryModal from 'components/CardNavNew/cardToolbar/historyModal';
import SettingsModal from 'components/CardNavNew/cardToolbar/settingsModal';
import { setActiveIndex } from 'state/exchange/slice';
import PageContent from './pageContent';
// @ts-ignore
import classes from './styles.module.scss';

const LiquidityRemove = ({
  history,
  match: {
    params: { currencyIdA, currencyIdB },
  },
}: RouteComponentProps<{ currencyIdA: string; currencyIdB: string }>) => {
  console.log("currs", currencyIdA, currencyIdB)
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setActiveIndex(1));
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <title>Trade</title>
      </Helmet>
      <PageWrapper heading="Trade">
        <div className={classes.wrapper}>
          <CardNavNew />
          <div className={classes.contentWrapper}>
            <PageContent history={history} currencyIdA={currencyIdA} currencyIdB={currencyIdB} />
          </div>
        </div>
        <SettingsModal />
        <HistoryModal />
      </PageWrapper>
    </>
  );
};

export default LiquidityRemove;
