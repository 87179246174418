import React, { useState } from 'react';
import InputRadio from 'components/ui/input/inputRadio';
import StandartContent from './standartContent';
import GeneratorContent from './generatorContent';
import BabyContent from './babyContent';
import BaybackContent from './baybackContent';
import classes from './styles.module.scss';

const ReflectionContent = () => {
  const [activeRadio, setActiveRadio] = useState(0);

  return (
    <div className={classes.wrapper}>
      <div className={classes.radioWrapper}>
        <InputRadio
          label="Standard token"
          id="standart-token"
          name="token-type"
          value={0}
          state={activeRadio}
          setState={setActiveRadio}
        />
        <InputRadio
          label="liquidity generator token"
          id="generator-token"
          name="token-type"
          value={1}
          state={activeRadio}
          setState={setActiveRadio}
        />
        <InputRadio
          label="baby token"
          id="baby-token"
          name="token-type"
          value={2}
          state={activeRadio}
          setState={setActiveRadio}
        />
        <InputRadio
          label="buyback baby token"
          id="bayback-token"
          name="token-type"
          value={3}
          state={activeRadio}
          setState={setActiveRadio}
        />
      </div>
      {activeRadio === 0 ? <StandartContent /> : <></>}
      {activeRadio === 1 ? <GeneratorContent /> : <></>}
      {activeRadio === 2 ? <BabyContent /> : <></>}
      {activeRadio === 3 ? <BaybackContent /> : <></>}
    </div>
  );
};

export default ReflectionContent;
