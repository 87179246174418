import React from 'react';
import { TradeType } from '@pancakeswap-libs/sdk';
import ContentHeading from '../../../../../components/contentHeading';
import InputBlock from '../../inputBlock';
import CheckoutBlockLg from './checkoutBlockLg';
import CheckoutBlockXs from './checkoutBlockXs';
import Chart from './chart';
import { computeSlippageAdjustedAmounts, computeTradePriceBreakdown } from '../../../../../utils/prices';
import { useUserSlippageTolerance } from '../../../../../state/user/hooks';
import { Field } from '../../../../../state/swap/actions';
import { ONE_BIPS } from '../../../../../constants';
import { useLastTruthy } from '../../../../../hooks/useLast';
// @ts-ignore
import classes from './styles.module.scss';
import formatValue from '../../../../../utils/formatNumber';

const ContentLoggedIn = ({
  inputValue,
  outputValue,
  inputHandler,
  outputHandler,
  inputSelectHandler,
  outputSelectHandler,
  selectInputValue,
  selectOutputValue,
  priceInverted,
  setPriceInverted,
  trade,
  isPriceVisible,
  isTolleranceVisible,
  tolleranceValue,
  children,
  switchToken,
  handleMaxInput,
  balanceInput,
  balanceOutput,
  pairAddress,
  errorInput,
  errorOutput,
  tokenInverted,
  inputTokenInfo,
  outputTokenInfo,
}) => {
  const lastTrade = useLastTruthy(trade);
  const tradeToUse = trade ?? lastTrade ?? undefined;
  const [allowedSlippage] = useUserSlippageTolerance();

  const formattedPrice = trade?.executionPrice?.toSignificant(6);
  const formattedPriceInvert = trade?.executionPrice?.invert().toSignificant(6);

  const show = Boolean(trade?.executionPrice?.baseCurrency && trade?.executionPrice?.quoteCurrency);
  const label = `${trade?.executionPrice?.quoteCurrency?.symbol} per ${trade?.executionPrice?.baseCurrency?.symbol}`;

  const { priceImpactWithoutFee, realizedLPFee } = computeTradePriceBreakdown(tradeToUse);
  const isExactIn = tradeToUse?.tradeType === TradeType.EXACT_INPUT;
  const slippageAdjustedAmounts = computeSlippageAdjustedAmounts(tradeToUse, allowedSlippage);

  return (
    <div className={classes.wrapper}>
      <div className={classes.inputBlock}>
        <div className={classes.headingWrapper}>
          <ContentHeading text="Trade tokens in an instant" />
        </div>
        <InputBlock
          inputValue={inputValue}
          outputValue={outputValue}
          inputHandler={inputHandler}
          outputHandler={outputHandler}
          inputSelectHandler={inputSelectHandler}
          outputSelectHandler={outputSelectHandler}
          selectInputValue={selectInputValue}
          selectOutputValue={selectOutputValue}
          switchToken={switchToken}
          balanceInput={balanceInput}
          balanceOutput={balanceOutput}
          handleMaxInput={handleMaxInput}
          errorInput={errorInput}
          errorOutput={errorOutput}
        />
      </div>
      <div className={classes.checkoutBlock}>
        <div className={classes.checkoutWrapper}>
          <div className={classes.flexGap}>
            <CheckoutBlockLg
              heading="Average Price"
              value={
                isPriceVisible
                  ? `${show ? formatValue(priceInverted ? formattedPriceInvert : formattedPrice) ?? '-' : '-'} ${label}`
                  : '-'
              }
              func={setPriceInverted}
            />
            <CheckoutBlockLg
              heading="Slippage Tolerance"
              value={isTolleranceVisible ? `${tolleranceValue / 100}%` : `-`}
            />
          </div>
          <div className={classes.btnWrapper}>{children}</div>
          <div className={classes.flexGap}>
            <CheckoutBlockXs
              heading={trade ? (isExactIn ? 'Minimum received' : 'Maximum sold') : 'Minimum received'}
              tooltipText="Your transaction will revert if there is a large, unfavorable price movement before it is confirmed."
              value={
                trade
                  ? isExactIn
                    ? `${formatValue(slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(4))} ${
                        trade.outputAmount.currency.symbol
                      }` ?? '-'
                    : `${formatValue(slippageAdjustedAmounts[Field.INPUT]?.toSignificant(4))} ${
                        trade.inputAmount.currency.symbol
                      }` ?? '-'
                  : '-'
              }
            />
            <CheckoutBlockXs
              heading="Price Impact"
              tooltipText="The difference between the market price and estimated price due to trade size."
              value={
                trade
                  ? priceImpactWithoutFee
                    ? priceImpactWithoutFee.lessThan(ONE_BIPS)
                      ? '<0.01%'
                      : `${priceImpactWithoutFee.toFixed(2)}%`
                    : '-'
                  : '-'
              }
            />
            <CheckoutBlockXs
              heading="Liquidity Provider Fee"
              tooltipText="For each trade a 0.3% fee is paid. 0.25% goes to liquidity providers and 0.05% goes to the Qidex treasury."
              value={
                trade
                  ? realizedLPFee
                    ? `${formatValue(realizedLPFee.toSignificant(4))} ${trade.inputAmount.currency.symbol}`
                    : '-'
                  : '-'
              }
            />
          </div>
        </div>
      </div>
      <Chart
        inputValue={selectInputValue}
        outputValue={selectOutputValue}
        trade={formattedPrice}
        setPriceInverted={setPriceInverted}
        priceInverted={priceInverted}
        priceValue={formattedPrice}
        selectInputValue={selectInputValue}
        pairAddress={pairAddress}
        tokenInverted={tokenInverted}
        inputTokenInfo={inputTokenInfo}
        outputTokenInfo={outputTokenInfo}
      />
    </div>
  );
};

export default ContentLoggedIn;
