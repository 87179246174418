import { createSlice } from '@reduxjs/toolkit';

const walletSlice = createSlice({
  name: 'wallet',
  initialState: {
    isLogged: false,
    walletId: '',
  },
  reducers: {
    logIn(state) {
      state.isLogged = true;
      state.walletId = '0x58aBc268D754C92383fC0Dada9c98168a0d70908';
    },
    logOut(state) {
      state.isLogged = false;
      state.walletId = '';
    },
  },
});

export default walletSlice.reducer;
export const { logIn, logOut } = walletSlice.actions;
