import React from 'react';
import dayjs from 'dayjs';
import { useActiveWeb3React } from '../../../../../../hooks';
import { getEtherscanLink } from '../../../../../../utils';
import classes from './styles.module.scss';

const HistoryItem = ({ date, amountFrom, amountTo, from, to, iconFrom, iconTo, hash }) => {
  const { chainId } = useActiveWeb3React();

  return (
    <a
      href={getEtherscanLink(chainId, hash, 'transaction')}
      target="_blank"
      rel="noreferrer"
      className={classes.wrapper}
    >
      <div className={classes.date}>
        {dayjs(date).format('DD.MM.YYYY')} {dayjs(date).format('HH:mm')}
      </div>
      <div className={classes.contentWrapper}>
        <div className={classes.exchangeWrapper}>
          {/* <div className={classes.iconWrapper}> */}
          {/*     <img src={iconFrom} alt={`${from} icon`} /> */}
          {/*     <img src={iconTo} alt={`${to} icon`} /> */}
          {/* </div> */}
          <div className={classes.exchangeWrapper}>
            <div className={classes.name}>{from}</div>
            <div className={classes.name}>/</div>
            <div className={classes.name}>{to}</div>
          </div>
        </div>
        <div className={classes.amountWrapper}>
          <div className={classes.amount}>{amountFrom}</div>
          <div className={classes.amount}>/</div>
          <div className={classes.amount}>{amountTo}</div>
        </div>
      </div>
    </a>
  );
};

export default HistoryItem;
