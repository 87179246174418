import React from 'react';
import classes from './styles.module.scss';

type Props = {
  label: string;
  value: string;
  add?: string;
};

const CardContent = ({ label, value, add }: Props) => {
  return (
    <>
      <h3 className={classes.label}>{label}</h3>
      <div className={classes.valueWrapper}>
        <p className={classes.value}>{value}</p>
        {add && <p className={classes.addInfo}>{add}</p>}
      </div>
    </>
  );
};

export default CardContent;
