import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import Heading from './heading';
import InformationModal from './informationModal';
import TextBlock from './textBlock';
import TokenCreation from './tokenCreation';
import classes from './styles.module.scss';

type State = {
  sideMenu: {
    isActive: boolean;
  };
};

const Content = () => {
  const isFull = useSelector((state: State) => state.sideMenu.isActive);

  const wrapperClass = () =>
    classNames({
      [classes.contentWrapper]: true,
      [classes.widthSmall]: isFull,
      [classes.widthFull]: !isFull,
    });

  return (
    <div className={classes.wrapper}>
      <div className={wrapperClass()}>
        <Heading />
        <TextBlock />
        <TokenCreation />
        <InformationModal />
      </div>
    </div>
  );
};

export default Content;
