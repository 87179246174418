import React from 'react';
// @ts-ignore
import { ReactComponent as CoinIcon } from 'assets/images/coin-horizontal-icon.svg';
import ContentHeading from '../../../../../components/contentHeading';
import WalletConnect from '../../../../../components/walletConnect';
import InputBlock from '../../inputBlock';
import ContentWrapperLoggedOut from '../../contentCenterWrapper';
import BalanceWithButton from '../../balanceWithButton';
// @ts-ignore
import classes from './styles.module.scss';

const ContentLoggedOut = ({
  inputValue,
  outputValue,
  inputHandler,
  outputHandler,
  inputSelectHandler,
  outputSelectHandler,
  selectInputValue,
  selectOutputValue,
}) => {
  return (
    <ContentWrapperLoggedOut>
      <div className={classes.headingWrapper}>
        <ContentHeading text="Trade tokens in an instant" />
      </div>
      <InputBlock
        inputValue={inputValue}
        outputValue={outputValue}
        inputHandler={inputHandler}
        outputHandler={outputHandler}
        inputSelectHandler={inputSelectHandler}
        outputSelectHandler={outputSelectHandler}
        selectInputValue={selectInputValue}
        selectOutputValue={selectOutputValue}
      />
      {/*     <BalanceWithButton/> */}
      {/* </InputBlock> */}
      <div className={classes.btnWrapper}>
        <WalletConnect icon={CoinIcon} />
      </div>
    </ContentWrapperLoggedOut>
  );
};

export default ContentLoggedOut;
