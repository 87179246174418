import React from 'react';
import ROUTES from '../../../constants/routes';
import classes from './styles.module.scss';

const CopyrightBlock = () => {
  return (
    <a href={ROUTES.QI_BLOCKCHAIN} rel="noreferrer" target="_blank" className={classes.text}>
      © 2022 QiBlockchain. All Rights Reserved
    </a>
  );
};

export default CopyrightBlock;
