import React from 'react';
import AccordionItem from './accordionItem';
import AccordionLinkWithDescription from './accordionItem/accordionLinkWithDescription';
import AccordionSecurityToken from './accordionItem/accordionSecurityToken';
import AccordionDefi from './accordionItem/accordionDefi';
import AccordionReflection from './accordionItem/accordionReflection';
import AccordionProsCons from './accordionItem/accordionProsCons';
import AccordionDifference from './accordionItem/accordionDifference';
import AccordionMetamask from './accordionItem/accordionMetamask';
import AccordionPlatform from './accordionItem/accordionPlatform';
import classes from './styles.module.scss';
import AccordionPlatformGenerator from './accordionItem/accordionPlatformGenerator';

const Content = () => {
  return (
    <div className={classes.wrapper}>
      <div className={classes.contentWrapper}>
        <AccordionMetamask />
        <AccordionItem
          heading="Why create a token?"
          text="Generally speaking, most exchange tokens are designed to increase an exchange's liquidity, incentivize trading activity, right to vote or facilitate an exchange's community governance process. The most common use case for creating a crypto token is to incentivize users to participate in your DeFi or another decentralized app more actively and allowing it as the only closed community way to transact for in-gaming or in-app purchases. Blockchain is known for creating a transparent ledger with a record of all transactions making it easy for a global audience to capitalize on this great technology for new opportunities by streamlining services in supply management (logistics), traditional banking and investments. Smart contract functionality can be very powerful as it allows contracts to be executed without third parties. Think of how much can be achieved by sending and verifying information without third parties. Utility tokens are a popular class of crypto tokens that act as a store of value and grant users access to a specific service or product in the blockchain ecosystem. Tokens can be transferred globally with very low fees and in real-time allowing a feasible way to solve gaming or global ecosystems issues compared to paying via eft/fiat which may not be feasible in the other corner of the world."
        />
        <AccordionItem
          heading="What are utility tokens?"
          text="Utility tokens are exactly what their namesake implies – they are utility carriers within blockchain systems. Each utility token is endowed with a smart contract that entails an “if-then” application scenario. Such tokens act as access keys to various functions within the framework of projects. Essentially acting as a premium currency, the utility token allows its holder to use the functionality the project provides."
        />
        <AccordionItem
          heading="What is defi?"
          text="Short for decentralized finance, DeFi is an umbrella term for peer-to-peer financial services on public blockchains. Smart contracts have the power to replace the banking sector as we know it today by connecting the lender and borrower in a loan transaction and cutting out the middlemen by way of an example. Automated market making, lending, borrowing etc. have been the most disruptive in this space."
        />
        <AccordionSecurityToken />
        <AccordionDefi />
        <AccordionItem
          heading="What is TVL?"
          text="Total value locked (TVL) includes all the coins deposited in all the functions that DeFi protocols offer, including staking, lending, and liquidity pools. The total value locked in the crypto market reached $160 billion in mid-April 2022"
        />
        <AccordionItem
          heading="What is wrapped QIE?"
          text="Wrapped crypto tokens such as wrapped bitcoin (wBTC) are smart contracts representing locked collateral of the original asset (i.e., BTC) on a separate blockchain. They are used to provide liquidity to a growing DeFi ecosystem. Think of wrapped bitcoin or wrapped QIE in this case like an IOU briefcase. Wrapped QIE is used for utilising inside the decentralised exchange. To wrap a token, typically a user “locks” the original token in a smart contract, which then mints an equivalent number of wrapped tokens. This is analogous to a traditional structured note, issued by a DeFi robot. To unlock your original tokens, you simply trade your wrapped tokens back to the smart contract."
        />
        <AccordionItem
          heading="Why QIE?"
          text="Most people don`t realize how much slippage there are with most blockchains in terms of transaction(gas) fees and also it can be slow. QIE Blockchain represented by QIE cryptocurrency follows a secure consensus methodology and almost real-time transacting at very low fees! At time of print QIE transaction fees are more than 100 times lower than Ethereum! QIE blockchain is believed to be the most sophisticated blockchain ever created and designed for Finance."
        />
        <AccordionItem
          heading="Recommended staking period?"
          text='How long should I lock my liquidity pool tokens for? To provide the necessary confidence to the investors, a minimum of one year and ideally a three or five-year lock period is recommended. If liquidity is unlocked, then the token developers can do what is infamously known as "rugpull"'
        />
        <AccordionLinkWithDescription
          heading="What are liquidity pools?"
          text="Please watch this video to understand the concept better:"
          url="https://policies.google.com/technologies/partner-sites"
        />
        <AccordionReflection />
        <AccordionProsCons />
        <AccordionItem
          heading="How is APY calculated?"
          text="APY is calculated using this formula: APY= (1 + r/n )n – 1, where “r” is the stated annual interest rate and “n” is the number of compounding periods each year."
        />
        <AccordionDifference />
        <AccordionItem
          heading="Qidex has no relationship to QIswap?"
          text="Qiswap runs on Qtum blockchain and Qidex runs on QIE Blockchain.  Qtum blockchain is known for its confirmation time of 32 seconds per block and a transaction rate of 70 transactions per second (TPS) compared to 10 seconds per block on QIE Blockchain and around 2000 transactions per second. These are two completely different projects."
        />
        <AccordionItem
          heading="What is difference between a coin and a token?"
          text="The crypto industry has said that the key distinction between coins and tokens is that crypto coins are the native asset of a Blockchain like Bitcoin or QIE, whereas crypto tokens are created by platforms and applications that are built on top of an existing Blockchain."
        />
        <AccordionItem
          heading="Why set QIE-20 standard token?"
          text="QIE-20 is the technical standard for fungible tokens created using the Qi blockchain. A fungible token is one that is interchangeable with another token—where the well-known non-fungible tokens (NFTs) are not interchangeable. QIE-20 allows different smart-contract enabled tokens a way to be exchanged.  QIE-20 standard defines the functions balanceOf , totalSupply , transfer , transferFrom , approve , and allowance . It also has a few optional fields like the token name, symbol, and the number of decimal places with which it will be measured.  QIE-20 Tokens do not have their own blockchain: they reside on top of the QI Blockchain"
        />
        <AccordionItem
          heading="How do QIE-20 tokens gain value?"
          text="After the public has your token, and they start trading it, the price becomes set by what the next buyer is willing to pay. If the next buyer is willing to pay more, then the price will go up. If the next buyer is willing to pay less, then the price will go down.  You can stimulate this value proposition by ensuring real usage of your token with real utility.  By forcing people to use your token as the only in-game payment method or voting mechanism for your app it offers value to users for example."
        />
        <AccordionPlatformGenerator />
        <AccordionPlatform />
        <AccordionItem
          heading="What are Qidex fees?"
          text="Qidex low transaction fee of 0.3% is charged and is distributed to liquidity providers via the platform token called Qidex built on Qi Blockchain.  Users pay lower trading fee of only 0.2% if a certain amount of QIE is staked to the protocol.  At time of writing 20,000 QIE is required for staking and a lower trading fee is obtained when minimum of 30,000 QIE is staked."
        />
      </div>
    </div>
  );
};

export default Content;
