import React, { useState } from 'react';
import { JSBI, Pair } from '@pancakeswap-libs/sdk';
import { useActiveWeb3React } from '../../hooks';
import { unwrappedToken } from '../../utils/wrappedCurrency';
import { useTokenBalance } from '../../state/wallet/hooks';
import { useTotalSupply } from '../../data/TotalSupply';
// @ts-ignore
import classes from './styles.module.scss';
import formatValue from '../../utils/formatNumber';

interface PositionCardProps {
  pair: Pair;
  // eslint-disable-next-line react/no-unused-prop-types
  showUnwrapped?: boolean;
}

const LiquidityWallet = ({ pair, showUnwrapped = false }: PositionCardProps) => {
  const { account } = useActiveWeb3React();

  const currency0 = showUnwrapped ? pair.token0 : unwrappedToken(pair.token0);
  const currency1 = showUnwrapped ? pair.token1 : unwrappedToken(pair.token1);

  const [showMore, setShowMore] = useState(false);

  const userPoolBalance = useTokenBalance(account ?? undefined, pair.liquidityToken);
  const totalPoolTokens = useTotalSupply(pair.liquidityToken);

  const [token0Deposited, token1Deposited] =
    !!pair &&
    !!totalPoolTokens &&
    !!userPoolBalance &&
    // this condition is a short-circuit in the case where useTokenBalance updates sooner than useTotalSupply
    JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? [
          pair.getLiquidityValue(pair.token0, totalPoolTokens, userPoolBalance, false),
          pair.getLiquidityValue(pair.token1, totalPoolTokens, userPoolBalance, false),
        ]
      : [undefined, undefined];

  return (
    <div className={classes.wrapper}>
      <h4 className={classes.heading}>LP TOKENS IN YOUR WALLET</h4>
      <div className={classes.valueWrapper}>
        <h5 className={classes.valueHeading}>
          {currency0.symbol}/{currency1.symbol}
        </h5>
        <p className={classes.value}>{userPoolBalance ? formatValue(userPoolBalance.toSignificant(4)) : '-'}</p>
      </div>
      <div className={classes.valueWrapper}>
        <h5 className={classes.valueHeading}>{currency0.symbol}:</h5>
        {token0Deposited ? <p className={classes.value}>{formatValue(token0Deposited?.toSignificant(6))}</p> : '-'}
      </div>
      <div className={classes.valueWrapper}>
        <h5 className={classes.valueHeading}>{currency1.symbol}:</h5>
        {token1Deposited ? <p className={classes.value}>{formatValue(token1Deposited?.toSignificant(6))}</p> : '-'}
      </div>
    </div>
  );
};

export default LiquidityWallet;
