import React from 'react';
import classes from './styles.module.scss';

const ContentCenterWrapper = ({ children }) => {
  return (
    <div className={classes.contentCenter}>
      <div className={classes.wrapper}>{children}</div>
    </div>
  );
};

export default ContentCenterWrapper;
