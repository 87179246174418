import React from 'react';
import classes from './styles.module.scss';
import TooltipBtn from '../tooltipBtn';

const HeadingWithTooltip = ({ heading, tooltipText }) => {
  return (
    <div className={classes.wrapper}>
      <h5 className={classes.heading}>{heading}</h5>
      <TooltipBtn text={tooltipText} />
    </div>
  );
};

export default HeadingWithTooltip;
