import React from 'react';
import BtnGradient from 'components/btnGradient';
import { ReactComponent as CoinIcon } from 'assets/images/coin-horizontal-icon.svg';
import BlockWrapper from '../blockWrapper';
import Heading from '../heading';
import TotalNum from '../../../totalNum';
import classes from './styles.module.scss';

const TransactionBlock = ({ disabled, btnText, submitHandler, comissionFee }) => {
  return (
    <BlockWrapper>
      <Heading text="Transaction" />
      <div className={classes.wrapper}>
        <div className={classes.totalList}>
          <TotalNum label="Commission Fee" value={comissionFee} measure="QIE" />
          <TotalNum label="Gas Fee" value="Variable" />
        </div>
        <div className={classes.btnWrapper}>
          <BtnGradient text={btnText} Icon={CoinIcon} disabled={disabled} func={submitHandler} />
        </div>
      </div>
    </BlockWrapper>
  );
};

export default TransactionBlock;
