import React from 'react';
import classes from './styles.module.scss';

type Props = {
  text?: any;
  Icon?: any;
  func?: any;
  disabled?: boolean;
};

const BtnGradient = ({ text, Icon, func, disabled }: Props) => {
  return (
    <button type="button" className={classes.button} onClick={func} disabled={disabled}>
      {text && <div className={classes.text}>{text}</div>}
      {Icon && (
        <div>
          <Icon />
        </div>
      )}
    </button>
  );
};

export default BtnGradient;
