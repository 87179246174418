import { useMemo } from 'react';
import { useChainId, usePublicClient, useWalletClient } from 'wagmi';
import { publicClientToProvider, walletClientToProvider, walletClientToSigner } from '../../utils/v2/ethers-adapters';

/** Hook to convert a viem Wallet Client to an ethers.js Signer. */
export function useEthersSigner({ chainId }: { chainId?: number } = {}) {
  const chainIdCurrent = useChainId();
  const { data: walletClient } = useWalletClient({ chainId: chainId || chainIdCurrent });
  return useMemo(() => (walletClient ? walletClientToSigner(walletClient) : undefined), [walletClient]);
}
export function useProvider({ chainId }: { chainId?: number } = {}) {
  const chainIdCurrent = useChainId();
  const { data: walletClient } = useWalletClient({ chainId: chainId || chainIdCurrent });
  return useMemo(() => (walletClient ? walletClientToProvider(walletClient) : undefined), [walletClient]);
}

/** Hook to convert a viem Public Client to an ethers.js Provider. */
export function useEthersProvider({ chainId }: { chainId?: number } = {}) {
  const chainIdCurrent = useChainId();
  const publicClient = usePublicClient({ chainId: chainId || chainIdCurrent });
  return useMemo(() => publicClientToProvider(publicClient), [publicClient]);
}
