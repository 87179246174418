import React, { useState } from 'react';
import ContentLoggedIn from './contentLoggedIn';
import ContentLoggedOut from './contentLoggedOut';

const LiquidityContent = () => {
  const [isActive, setIsActive] = useState(false);

  return (
    <>
      {isActive ? (
        <ContentLoggedIn setIsActive={() => setIsActive(!isActive)} />
      ) : (
        <ContentLoggedOut setIsActive={() => setIsActive(!isActive)} />
      )}
    </>
  );
};

export default LiquidityContent;
