import { Chain, connectorsForWallets, darkTheme } from '@rainbow-me/rainbowkit';
import { coinbaseWallet, metaMaskWallet, trustWallet, walletConnectWallet } from '@rainbow-me/rainbowkit/wallets';
import { configureChains, createConfig } from 'wagmi';
import { publicProvider } from 'wagmi/providers/public';

const qiChain: Chain = {
  id: 5656,
  name: 'QI Blockchain - Mainnet',
  network: 'qi network',
  iconUrl: 'https://example.com/icon.svg',
  iconBackground: '#fff',
  nativeCurrency: {
    decimals: 18,
    name: 'Qi Blockchain',
    symbol: 'QIE',
  },
  rpcUrls: {
    public: {
      http: ['https://rpc-main2.qiblockchain.online'],
      webSocket: ['wss://rpc-main2.qiblockchain.online'],
    },
    default: {
      http: ['https://rpc-main2.qiblockchain.online'],
      webSocket: ['wss://rpc-main2.qiblockchain.online'],
    },
  },
  blockExplorers: {
    default: {
      name: 'QIcscan',
      url: 'https://mainnet.qiblockchain.online/',
    },
  },
  testnet: false,
  contracts: {
    multicall3: {
      address: '0x8304E6187ceABc5028C7Fc990B3342fEC239Df8E',
    },
  },
};

const { chains, publicClient, webSocketPublicClient } = configureChains([qiChain], [publicProvider()]);

const projectId = process.env.REACT_APP_PROJECT_ID || '';

export const demoAppInfo = {
  appName: 'QI Platfrom',
};

const connectors = connectorsForWallets([
  {
    groupName: 'Recommended',
    wallets: [
      metaMaskWallet({ projectId, chains }),
      trustWallet({ projectId, chains, shimDisconnect: true }),
      walletConnectWallet({ projectId, chains }),
      coinbaseWallet({ appName: 'Qi Platform', chains }),
    ],
  },
]);

export const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
  webSocketPublicClient,
});

export const themeDark = {
  ...darkTheme(),
  colors: {
    ...darkTheme().colors,
    modalBackground: '#100f0f',
    accentColor: 'rgb(150,59,159)',
    accentColorForeground: 'white',
  },
};

export { chains };
