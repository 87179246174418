import { ReactComponent as ExchangeIcon } from 'assets/images/exchange-icon.svg';
import { ReactComponent as DollarIcon } from 'assets/images/dollar-icon.svg';
import { ReactComponent as CoinIcon } from 'assets/images/coin-icon.svg';
import { ReactComponent as InfoIcon } from 'assets/images/faq-icon.svg';
import { ReactComponent as DocIcon } from 'assets/images/doc-icon.svg';
import ROUTES from 'constants/routes';

const config = [
  {
    id: 0,
    label: 'Trade',
    icon: ExchangeIcon,
    href: ROUTES.TRADE,
  },
  {
    id: 1,
    label: 'Stake',
    icon: DollarIcon,
    href: ROUTES.STAKE,
  },
  {
    id: 2,
    label: 'Token Creator',
    icon: CoinIcon,
    href: ROUTES.TOKEN_CREATOR,
  },
  {
    id: 3,
    label: 'FAQ',
    icon: InfoIcon,
    href: ROUTES.FAQ,
  },
  {
    id: 4,
    label: 'Docs',
    icon: DocIcon,
    href: ROUTES.DOCS,
    extlink: true,
  },
];

export default config;
