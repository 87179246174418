import React from 'react';
import { Link } from 'react-router-dom';
import ROUTES from 'constants/routes';
import classes from './styles.module.scss';

const Content = () => {
  return (
    <div className={classes.wrapper}>
      <div className={classes.contentWrapper}>
        <p className={classes.text}>
          Welcome,{' '}
          <a className={classes.link} href="https://qidex.site" target="_blank" rel="noreferrer">
            https://qidex.site
          </a>{' '}
          a website-hosted user interface (the &quot;Interface&quot; or &quot;App&quot;) provided by Qidex Labs
          (&quot;we&quot;, &quot;our&quot;, or &quot;us&quot;). The Interface provides access to a decentralized
          protocol on the Qi blockchain that allows users to trade certain digital assets (&quot;the Qidex
          protocol&quot; or the &quot;Protocol&quot;). The Interface is one, but not the exclusive, means of accessing
          the Protocol.
        </p>
        <br />
        <p className={classes.text}>
          This Terms of Service Agreement (the &quot;Agreement&quot;) explains the terms and conditions by which you may
          access and use the Interface. You must read this Agreement carefully. By accessing or using the Interface, you
          signify that you have read, understand, and agree to be bound by this Agreement in its entirety. If you do not
          agree, you are not authorized to access or use the Interface and should not use the Interface.
        </p>
        <br />
        <p className={classes.text}>
          <b>
            NOTICE : Please read this Agreement carefully as it governs your use of the Interface. This Agreement
            contains important information, including a binding arbitration provision and a class action waiver, both of
            which impact your rights as to how disputes are resolved. The Interface is only available to you — and you
            should only access the Interface — if you agree completely with these terms.
          </b>
        </p>
        <br />
        <h3 className={classes.heading}>Modification of this Agreement</h3>
        <br />
        <p className={classes.text}>
          We reserve the right, in our sole discretion, to modify this Agreement from time to time. If we make any
          modifications, we will notify you by updating the date at the top of the Agreement and by maintaining a
          current version of the Agreement at{' '}
          <Link className={classes.link} to={ROUTES.TERMS}>
            https://qidex.site{ROUTES.TERMS}
          </Link>
          . All modifications will be effective when they are posted, and your continued accessing or use of the
          Interface will serve as confirmation of your acceptance of those modifications. If you do not agree with any
          modifications to this Agreement, you must immediately stop accessing and using the Interface.
        </p>
        <br />
        <h3 className={classes.heading}>Eligibility</h3>
        <br />
        <p className={classes.text}>
          Visit{' '}
          <a
            className={classes.link}
            href="https://policies.google.com/technologies/partner-sites"
            target="_blank"
            rel="noreferrer"
          >
            https://policies.google.com/technologies/partner-sites
          </a>{' '}
          to learn more about how Google uses data. By accessing and using the Interface, you understand and consent to
          our data practices and our service providers treatment of your information. All modifications will be
          effective when they are posted, and your continued accessing or use of the Interface will serve as
          confirmation of your acceptance of those modifications. If you do not agree with any modifications to this
          Agreement, you must immediately stop accessing and using the Interface.
        </p>
        <br />
        <p className={classes.text}>
          We use the information we collect to detect, prevent, and mitigate financial crime and other illicit or
          harmful activities on the Interface. For these purposes, we may share the information we collect with
          blockchain analytics providers. We share information with these service providers only so that they can help
          us promote the safety, security, and integrity of the Interface. We do not retain the information we collect
          any longer than necessary for these purposes.
        </p>
        <br />
        <p className={classes.text}>
          Please note that when you use the Interface, you are interacting with the Qi blockchain, which provides
          transparency into your transactions. Qidex Labs does not control and is not responsible for any information
          you make public on the Qi blockchain by taking actions through the Interface.
        </p>
        <br />
        <h3 className={classes.heading}>Access</h3>
        <br />
        <p className={classes.text}>
          To access or use the Interface, you must be able to form a legally binding contract with us. Accordingly, you
          represent that you are at least the age of majority in your jurisdiction (e.g., eighteen years old) and have
          the full right, power, and authority to enter into and comply with the terms and conditions of this Agreement
          on behalf of yourself and any company or legal entity for which you may access or use the Interface.
        </p>
        <br />
        <p className={classes.text}>You further represent that you are not</p>
        <p className={classes.text}>
          - the subject of economic or trade sanctions administered or enforced by any governmental authority or
          otherwise designated on any list of prohibited or restricted parties (including but not limited to the list
          maintained by the Office of Foreign Assets Control of the U.S. Department of the Treasury) or
        </p>
        <p className={classes.text}>
          - a citizen, resident, or organized in a jurisdiction or territory that is the subject of comprehensive
          country-wide, territory-wide, or regional economic sanctions by the United States. Finally, you represent that
          your access and use of the Interface will fully comply with all applicable laws and regulations, and that you
          will not access or use the Interface to conduct, promote, or otherwise facilitate any illegal activity. You
          furthermore confirm that your token is not a security under any laws.
        </p>
        <br />
        <h3 className={classes.heading}>Proprietary Rights</h3>
        <br />
        <p className={classes.text}>
          We own all intellectual property and other rights in the Interface and its contents, including (but not
          limited to) software, text, images, trademarks, service marks, copyrights, patents, and designs. This
          intellectual property is available under the terms of our copyright licenses and our Trademark Guidelines. The
          Qidex protocol is comprised entirely of open-source or source-available software running on the public Qi
          blockchain.
        </p>
        <br />
        <h3 className={classes.heading}>Additional Rights</h3>
        <br />
        <p className={classes.text}>
          We reserve the following rights, which do not constitute obligations of ours: (a) with or without notice to
          you, to modify, substitute, eliminate or add to the Interface; (b) to review, modify, filter, disable, delete
          and remove any and all content and information from the Interface; and (c) to cooperate with any law
          enforcement, court or government investigation or order or third party requesting or directing that we
          disclose information or content or information that you provide.
        </p>
        <br />
        <h3 className={classes.heading}>Privacy</h3>
        <br />
        <p className={classes.text}>
          When you use the Interface, the only information we collect from you is your blockchain wallet address,
          completed transaction hashes, and the token names, symbols, or other blockchain identifiers of the tokens that
          you swap. We do not collect any personal information from you (e.g., your name or other identifiers that can
          be linked to you). We do, however, use third-party service providers, like Infura, Cloudflare, and Google
          Analytics, which may receive or independently obtain your personal information from publicly-available
          sources. We do not control how these third parties handle your data and you should review their privacy
          policies to understand how they collect, use, and share your personal information. In particular, please visit{' '}
          <a
            className={classes.link}
            href="https://policies.google.com/technologies/partner-sites"
            target="_blank"
            rel="noreferrer"
          >
            https://policies.google.com/technologies/partner-sites
          </a>{' '}
          to learn more about how Google uses data. By accessing and using the Interface, you understand and consent to
          our data practices and our service providers&apos; treatment of your information.
        </p>
        <br />
        <p className={classes.text}>
          We use the information we collect to detect, prevent, and mitigate financial crime and other illicit or
          harmful activities on the Interface. For these purposes, we may share the information we collect with
          blockchain analytics providers. We share information with these service providers only so that they can help
          us promote the safety, security, and integrity of the Interface. We do not retain the information we collect
          any longer than necessary for these purposes.
        </p>
        <br />
        <p className={classes.text}>
          Please note that when you use the Interface, you are interacting with the Qi blockchain, which provides
          transparency into your transactions. Qidex Labs does not control and is not responsible for any information
          you make public on the Qi blockchain by taking actions through the Interface.
        </p>
        <br />
        <h3 className={classes.heading}>Prohibited Activity</h3>
        <br />
        <p className={classes.text}>
          You agree not to engage in, or attempt to engage in, any of the following categories of prohibited activity in
          relation to your access and use of the Interface:
        </p>
        <br />
        <p className={classes.text}>
          - Intellectual Property Infringement. Activity that infringes on or violates any copyright, trademark, service
          mark, patent, right of publicity, right of privacy, or other proprietary or intellectual property rights under
          the law.
        </p>
        <p className={classes.text}>
          - oCyberattack. Activity that seeks to interfere with or compromise the integrity, security, or proper
          functioning of any computer, server, network, personal device, or other information technology system,
          including (but not limited to) the deployment of viruses and denial of service attacks.
        </p>
        <p className={classes.text}>
          - Fraud and Misrepresentation. Activity that seeks to defraud us or any other person or entity, including (but
          not limited to) providing any false, inaccurate, or misleading information in order to unlawfully obtain the
          property of another.
        </p>
        <p className={classes.text}>
          - Market Manipulation. Activity that violates any applicable law, rule, or regulation concerning the integrity
          of trading markets, including (but not limited to) the manipulative tactics commonly known as spoofing and
          wash trading.
        </p>
        <p className={classes.text}>
          - Securities and Derivatives Violations. Activity that violates any applicable law, rule, or regulation
          concerning the trading of securities or derivatives.
        </p>
        <p className={classes.text}>
          - Any Other Unlawful Conduct. Activity that violates any applicable law, rule, or regulation of the United
          States or another relevant jurisdiction, including (but not limited to) the restrictions and regulatory
          requirements imposed by U.S. law.
        </p>
        <br />
        <h3 className={classes.heading}>Not Registered with the SEC or Any Other Agency</h3>
        <br />
        <p className={classes.text}>
          We are not registered with the U.S. Securities and Exchange Commission as a national securities exchange or in
          any other capacity. You understand and acknowledge that we do not broker trading orders on your behalf nor do
          we collect or earn fees from your trades on the Protocol. We also do not facilitate the execution or
          settlement of your trades, which occur entirely on the public distributed Qi blockchain.
        </p>
        <br />
        <h3 className={classes.heading}>Non-Solicitation; No Investment Advice</h3>
        <br />
        <p className={classes.text}>
          You agree and understand that all trades you submit through the Interface are considered unsolicited, which
          means that you have not received any investment advice from us in connection with any trades, including those
          you place via our Auto Router API, and that we do not conduct a suitability review of any trades you submit.
        </p>
        <br />
        <p className={classes.text}>
          All information provided by the Interface is for informational purposes only and should not be construed as
          investment advice. You should not take, or refrain from taking, any action based on any information contained
          in the Interface. We do not make any investment recommendations to you or opine on the merits of any
          investment transaction or opportunity. You alone are responsible for determining whether any investment,
          investment strategy or related transaction is appropriate for you based on your personal investment
          objectives, financial circumstances, and risk tolerance.
        </p>
        <br />
        <h3 className={classes.heading}>No Warranties</h3>
        <br />
        <p className={classes.text}>
          The Interface is provided on an &quot;AS IS&quot; and &quot;AS AVAILABLE&quot; basis. To the fullest extent
          permitted by law, we disclaim any representations and warranties of any kind, whether express, implied, or
          statutory, including (but not limited to) the warranties of merchantability and fitness for a particular
          purpose. You acknowledge and agree that your use of the Interface is at your own risk. We do not represent or
          warrant that access to the Interface will be continuous, uninterrupted, timely, or secure; that the
          information contained in the Interface will be accurate, reliable, complete, or current; or that the Interface
          will be free from errors, defects, viruses, or other harmful elements. No advice, information, or statement
          that we make should be treated as creating any warranty concerning the Interface. We do not endorse,
          guarantee, or assume responsibility for any advertisements, offers, or statements made by third parties
          concerning the Interface.
        </p>
        <br />
        <h3 className={classes.heading}>Non-Custodial and No Fiduciary Duties</h3>
        <br />
        <p className={classes.text}>
          The Interface is a purely non-custodial application, meaning you are solely responsible for the custody of the
          cryptographic private keys to the digital asset wallets you hold. This Agreement is not intended to, and does
          not, create or impose any fiduciary duties on us. To the fullest extent permitted by law, you acknowledge and
          agree that we owe no fiduciary duties or liabilities to you or any other party, and that to the extent any
          such duties or liabilities may exist at law or in equity, those duties and liabilities are hereby irrevocably
          disclaimed, waived, and eliminated. You further agree that the only duties and obligations that we owe you are
          those set out expressly in this Agreement.
        </p>
        <br />
        <h3 className={classes.heading}>Compliance Obligations</h3>
        <br />
        <p className={classes.text}>
          The Interface is operated from facilities within the United States. The Interface may not be available or
          appropriate for use in other jurisdictions. By accessing or using the Interface, you agree that you are solely
          and entirely responsible for compliance with all laws and regulations that may apply to you.
        </p>
        <br />
        <h3 className={classes.heading}>Assumption of Risk</h3>
        <br />
        <p className={classes.text}>
          By accessing and using the Interface, you represent that you are financially and technically sophisticated
          enough to understand the inherent risks associated with using cryptographic and blockchain-based systems, and
          that you have a working knowledge of the usage and intricacies of digital assets such as bitcoin (BTC), ether
          (ETH), QIE and other digital tokens such as those following the Qi Token Standard (QIE-20). In particular, you
          understand that blockchain-based transactions are irreversible.
        </p>
        <br />
        <p className={classes.text}>
          You further understand that the markets for these digital assets are highly volatile due to factors including
          (but not limited to) adoption, speculation, technology, security, and regulation. You acknowledge and accept
          that the cost and speed of transacting with cryptographic and blockchain-based systems such as Qi are variable
          and may increase dramatically at any time. You further acknowledge and accept the risk that your digital
          assets may lose some or all of their value while they are supplied to the Protocol through the Interface, you
          may suffer loss due to the fluctuation of prices of tokens in a trading pair or liquidity pool, and,
          especially in expert modes, experience significant price slippage and cost. You understand that anyone can
          create a token, including fake versions of existing tokens and tokens that falsely claim to represent
          projects, and acknowledge and accept the risk that you may mistakenly trade those or other tokens. You further
          acknowledge that we are not responsible for any of these variables or risks, do not own or control the
          Protocol, and cannot be held liable for any resulting losses that you experience while accessing or using the
          Interface. Accordingly, you understand and agree to assume full responsibility for all of the risks of
          accessing and using the Interface to interact with the Protocol.
        </p>
        <br />
        <h3 className={classes.heading}>Third-Party Resources and Promotions</h3>
        <br />
        <p className={classes.text}>
          The Interface may contain references or links to third-party resources, including (but not limited to)
          information, materials, products, or services, that we do not own or control. In addition, third parties may
          offer promotions related to your access and use of the Interface. We do not endorse or assume any
          responsibility for any such resources or promotions. If you access any such resources or participate in any
          such promotions, you do so at your own risk, and you understand that this Agreement does not apply to your
          dealings or relationships with any third parties. You expressly relieve us of any and all liability arising
          from your use of any such resources or participation in any such promotions.
        </p>
        <br />
        <h3 className={classes.heading}>Release of Claims</h3>
        <br />
        <p className={classes.text}>
          You expressly agree that you assume all risks in connection with your access and use of the Interface and your
          interaction with the Protocol. You further expressly waive and release us from any and all liability, claims,
          causes of action, or damages arising from or in any way relating to your use of the Interface and your
          interaction with the Protocol. If you are a California resident, you waive the benefits and protections of
          California Civil Code § 1542, which provides: &quot;[a] general release does not extend to claims that the
          creditor or releasing party does not know or suspect to exist in his or her favour at the time of executing
          the release and that, if known by him or her, would have materially affected his or her settlement with the
          debtor or released party.&quot;
        </p>
        <br />
        <h3 className={classes.heading}>Indemnity</h3>
        <br />
        <p className={classes.text}>
          You agree to hold harmless, release, defend, and indemnify us and our officers, directors, employees,
          contractors, agents, affiliates, and subsidiaries from and against all claims, damages, obligations, losses,
          liabilities, costs, and expenses arising from:
        </p>
        <br />
        <p className={classes.text}>- your access and use of the Interface;</p>
        <p className={classes.text}>
          - your violation of any term or condition of this Agreement, the right of any third party, or any other
          applicable law, rule, or regulation;
        </p>
        <p className={classes.text}>
          - any other party&apos;s access and use of the Interface with your assistance or using any device or account
          that you own or control.
        </p>
        <br />
        <h3 className={classes.heading}>Limitation of Liability</h3>
        <br />
        <p className={classes.text}>
          Under no circumstances shall we or any of our officers, directors, employees, contractors, agents, affiliates,
          or subsidiaries be liable to you for any indirect, punitive, incidental, special, consequential, or exemplary
          damages, including (but not limited to) damages for loss of profits, goodwill, use, data, or other intangible
          property, arising out of or relating to any access or use of the Interface, nor will we be responsible for any
          damage, loss, or injury resulting from hacking, tampering, or other unauthorized access or use of the
          Interface or the information contained within it. We assume no liability or responsibility for any:
        </p>
        <br />
        <p className={classes.text}>- errors, mistakes, or inaccuracies of content;</p>
        <p className={classes.text}>
          - personal injury or property damage, of any nature whatsoever, resulting from any access or use of the
          Interface;
        </p>
        <p className={classes.text}>
          - unauthorized access or use of any secure server or database in our control, or the use of any information or
          data stored therein;
        </p>
        <p className={classes.text}>- interruption or cessation of function related to the Interface;</p>
        <p className={classes.text}>
          - bugs, viruses, trojan horses, or the like that may be transmitted to or through the Interface;
        </p>
        <p className={classes.text}>
          - errors or omissions in, or loss or damage incurred as a result of the use of, any content made available
          through the Interface;
        </p>
        <p className={classes.text}>
          - the defamatory, offensive, or illegal conduct of any third party. Under no circumstances shall we or any of
          our officers, directors, employees, contractors, agents, affiliates, or subsidiaries be liable to you for any
          claims, proceedings, liabilities, obligations, damages, losses, or costs in an amount exceeding the amount you
          paid to us in exchange for access to and use of the Interface, or USD$100.00, whichever is greater. This
          limitation of liability applies regardless of whether the alleged liability is based on contract, tort,
          negligence, strict liability, or any other basis, and even if we have been advised of the possibility of such
          liability. Some jurisdictions do not allow the exclusion of certain warranties or the limitation or exclusion
          of certain liabilities and damages. Accordingly, some of the disclaimers and limitations set forth in this
          Agreement may not apply to you. This limitation of liability shall apply to the fullest extent permitted by
          law.
        </p>
        <br />
        <h3 className={classes.heading}>Dispute Resolution</h3>
        <br />
        <p className={classes.text}>
          We will use our best efforts to resolve any potential disputes through informal, good faith negotiations. If a
          potential dispute arises, you must contact us by sending an email to{' '}
          <a className={classes.link} href="mailto:info@qiblockchain.online">
            info@qiblockchain.online
          </a>{' '}
          so that we can attempt to resolve it without resorting to formal dispute resolution. If we aren&apos;t able to
          reach an informal resolution within sixty days of your email, then you and we both agree to resolve the
          potential dispute according to the process set forth below.
        </p>
        <br />
        <p className={classes.text}>
          Any claim or controversy arising out of or relating to the Interface, this Agreement, or any other acts or
          omissions for which you may contend that we are liable, including (but not limited to) any claim or
          controversy as to arbitrability (&quot;Dispute&quot;), shall be finally and exclusively settled by arbitration
          under the JAMS Optional Expedited Arbitration Procedures. You understand that you are required to resolve all
          Disputes by binding arbitration. The arbitration shall be held on a confidential basis before a single
          arbitrator, who shall be selected pursuant to JAMS rules. The arbitration will be held in Pretoria, South
          Africa unless you and we both agree to hold it elsewhere. Unless we agree otherwise, the arbitrator may not
          consolidate your claims with those of any other party. Any judgment on the award rendered by the arbitrator
          may be entered in any court of competent jurisdiction.
        </p>
        <br />
        <h3 className={classes.heading}>Class Action and Jury Trial Waiver</h3>
        <br />
        <p className={classes.text}>
          You must bring any and all Disputes against us in your individual capacity and not as a plaintiff in or member
          of any purported class action, collective action, private attorney general action, or other representative
          proceeding. This provision applies to class arbitration. You and we both agree to waive the right to demand a
          trial by jury.
        </p>
        <br />
        <h3 className={classes.heading}>Governing Law</h3>
        <br />
        <p className={classes.text}>
          You agree that the laws of South Africa, without regard to principles of conflict of laws, govern this
          Agreement and any Dispute between you and us. You further agree that the Interface shall be deemed to be based
          solely in South Africa, and that although the Interface may be available in other jurisdictions, its
          availability does not give rise to general or specific personal jurisdiction in any forum outside the South
          Africa. Any arbitration conducted pursuant to this Agreement shall be governed by the Arbitration Act 42 of
          1965. You agree that the federal and state courts of South Africa are the proper forum for any appeals of an
          arbitration award or for court proceedings in the event that this Agreement&apos;s binding arbitration clause
          is found to be unenforceable.
        </p>
        <br />
        <h3 className={classes.heading}>Entire Agreement</h3>
        <br />
        <p className={classes.text}>
          These terms constitute the entire agreement between you and us with respect to the subject matter hereof. This
          Agreement supersedes any and all prior or contemporaneous written and oral agreements, communications and
          other understandings (if any) relating to the subject matter of the terms.
        </p>
      </div>
    </div>
  );
};

export default Content;
