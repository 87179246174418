import { JsonRpcSigner } from '@ethersproject/providers';
import { ChainId } from '@pancakeswap-libs/sdk';
import { Provider } from '@ethersproject/abstract-provider';
// eslint-disable-next-line import/no-unresolved
import { providers } from 'ethers';
import { useAccount, useChainId } from 'wagmi';
import { useEthersProvider, useEthersSigner } from './v2/ethers-adapters';

type UseActiveWeb3ReactType = {
  chainId?: ChainId;
  account?: string;
  signer?: JsonRpcSigner;
  library?: providers.JsonRpcProvider | providers.FallbackProvider;
  connector?: Provider; // todo: think about new type from wagmi/viem
};

export function useActiveWeb3React(): UseActiveWeb3ReactType {
  const { address, connector } = useAccount();
  const chainId = useChainId();
  const signer = useEthersSigner();
  const library = useEthersProvider();

  return { account: address, chainId, signer, library, connector: (connector as unknown) as Provider };
}
