// @ts-nocheck

import { ContractFactory } from '@ethersproject/contracts';
import { ReactComponent as CoinIcon } from 'assets/images/coin-horizontal-icon.svg';
import BtnGradient from 'components/btnGradient';
import InputHeadingWrapper from 'components/inputHeadingWrapper';
import InputSubHeadingWrapper from 'components/inputSubHeadingWrapper';
import InputWithControls from 'components/inputWithControls';
import ModalWrapper from 'components/modalWrapper';
import InputNumWithHeading from 'components/ui/input/inputNumWithHeading';
import BabyTokenABI from 'constants/abis//BabyToken/BabyTokenABI.json';
import BabayTokenData from 'constants/abis/BabyToken/BabyTokenData';
import UniswapFactoryABI from 'constants/abis/UniswapFactoryABI.json';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSwitchNetwork } from 'wagmi';
import Web3 from 'web3';
import {
  ACTIVE_NETWORK,
  AdminOwner,
  ammFactory,
  deadAddress,
  deadAddressDead,
  DividentTokenAddress,
  explorerURL,
  pancakeRouter,
  wbnbAddress,
} from '../../../../../../constants';
import { useActiveWeb3React } from '../../../../../../hooks';
import { useProvider } from '../../../../../../hooks/v2/ethers-adapters';
import { getContract } from '../../../../../../utils';
import convertToInternationalCurrencySystem from '../../../../../../utils/convertToInternationalCurrencySystem';
import {
  addressValidatorCreator,
  balanceValidatorCreator,
  nameValidatorCreator,
  percentValidatorCreator,
  supplyValidatorCreator,
  symbolValidatorCreator,
} from '../../../../../../utils/validator';
import InputTextWithHeading from '../../inputTextWithHeading';
import TotalNum from '../../totalNum';
import classes from './styles.module.scss';

const BabyContent = () => {
  const tockenCreatorFee = 0.00001;
  const history = useHistory();
  const { account, chainId, library } = useActiveWeb3React();
  const { switchNetwork } = useSwitchNetwork();
  const [isSubmit, setIsSubmit] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [notListed, setNotListed] = useState(true);
  const [formData, setFormData] = useState({
    TokenType: 'Reflection',
    types: 'Baby Token',
    name: '',
    symbol: '',
    decimals: 18,
    totalSupply: '',
    taxFee: '',
    liquidityFee: '',
    burnFee: '',
    marketingFee: '',
    maxTransfer: '',
    numberOfTokens: '',
    autoAddLiquidity: '',
    rewardTokens: '',
    marketingWallet: '',
    balanceForDividends: '',
    rewardFee: '',
    investmentToken: 'babyToken',
  });
  const [modalState, setModalState] = useState(false);
  const [contractAddress, setContractAddress] = useState('');
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    trigger,
  } = useForm({
    mode: 'onChange',
  });
  const provider = useProvider();

  const _onInputChange = (e) => {
    const temp = { ...formData, [e.target.name]: e.target.value };
    setFormData(temp);
  };

  const _onInputChange1 = (e) => {
    const cursor = e.target?.selectionStart;
    const parsedValue = e.target.value.replace(/,/g, '');

    if (/^\d*\.?\d*$/.test(parsedValue)) {
      const temp = { ...formData, [e.target.name]: parsedValue };
      setFormData(temp);
    }

    e.target.selectionStart = cursor;
  };

  const handleInputControlClick = (action, dataName, step) => {
    const temp = {
      ...formData,
      [dataName]: action === 'increase' ? Number(formData[dataName]) + step : Number(formData[dataName]) - step,
    };
    setValue(dataName, action === 'increase' ? Number(formData[dataName]) + step : Number(formData[dataName]) - step);
    trigger(dataName);
    setFormData(temp);
  };

  const closeModal = () => {
    setFormData({
      TokenType: 'Reflection',
      types: 'Baby Token',
      name: '',
      symbol: '',
      decimals: 18,
      totalSupply: '',
      taxFee: '',
      liquidityFee: '',
      burnFee: '',
      marketingFee: '',
      maxTransfer: '',
      numberOfTokens: '',
      autoAddLiquidity: '',
      rewardTokens: '',
      marketingWallet: '',
      balanceForDividends: '',
      investmentToken: 'babyToken',
    });
    setModalState(false);
  };

  const uploadContractHandlerBabyToken = async (
    accountValue,
    formDataValue,
    setIsLoaderValue,
    tockenCreatorFeeValue,
    cbValue
  ) => {
    try {
      console.log('`123456789');
      const web3 = (window.web3 = new Web3(provider));
      const nfttokenContract = new ContractFactory(BabyTokenABI, BabayTokenData, provider.getSigner(account));
      console.log(nfttokenContract);
      const tockenCreatorFeeS = web3.utils.toWei(tockenCreatorFeeValue.toString());
      console.log(tockenCreatorFeeS);
      console.log(web3.utils.toWei(formDataValue.totalSupply.toString()));

      const contract = await nfttokenContract.deploy(
        formDataValue.name,
        formDataValue.symbol,
        web3.utils.toWei(formDataValue.totalSupply.toString()),
        [formDataValue.rewardTokens, pancakeRouter, formDataValue.marketingWallet, DividentTokenAddress],
        [formDataValue.rewardFee, formDataValue.autoAddLiquidity, formDataValue.marketingFee],
        web3.utils.toWei(formDataValue.balanceForDividends.toString()),
        AdminOwner,
        tockenCreatorFeeS,
        {
          gasLimit: web3.utils.toHex('6540692'),
          value: web3.utils.toHex(tockenCreatorFeeS),
        }
      );

      await contract.deployTransaction.wait();

      if (contract.address) {
        cbValue({
          status: true,
          address: contract.address,
        });
      }
    } catch (error) {
      console.error(error);
      toast.error(error.message);
      setIsLoaderValue(false);
      cbValue({
        status: false,
        address: null,
      });
    }
  };

  const submitHandler = async () => {
    if (ACTIVE_NETWORK == chainId) {
      setIsSubmit(true);
      try {
        const web3 = (window.web3 = new Web3(provider));
        const isAddress = web3.utils.isAddress(formData.marketingWallet);
        const isContract = web3.utils.isAddress(formData.rewardTokens);

        setrewardTokenserr(isContract);
        setmarketingWalletErr(isAddress);
        if (formData.investmentToken == 'babyToken') {
          if (
            formData.name !== '' &&
            formData.symbol !== '' &&
            formData.totalSupply !== '' &&
            Number(formData.totalSupply) > 0 &&
            // formData.decimals !== "" &&
            // Number(formData.decimals) > 1 &&
            // Number(formData.decimals) < 19 &&
            formData.balanceForDividends !== '' &&
            Number(formData.balanceForDividends) > 0 &&
            formData.autoAddLiquidity !== '' &&
            formData.rewardFee !== '' &&
            formData.marketingFee !== '' &&
            formData.marketingWallet !== '' &&
            formData.marketingWallet != account &&
            formData.marketingWallet != deadAddress &&
            formData.marketingWallet != account &&
            formData.rewardTokens !== ''
          ) {
            setIsUpdating(true);
            await uploadContractHandlerBabyToken(account, formData, setIsUpdating, tockenCreatorFee, (result) => {
              if (result.status && result.address) {
                console.log(`${explorerURL}/address/${result.address}`);
                // window.open(`${explorerURL}/address/${result.address}`);
                // setIsLoader(false);
                // history.push({
                //     pathname: "/app/view-token",
                //     search: result.address,
                //     state: formData,
                // });
                // toast.success("Token created successfully");
                setContractAddress(result.address);
                setModalState(true);
                toast.success('Token created successfully');
              } else {
              }
            });
            // setIsLoader(false);
          } else {
            // setIsLoader(false);
          }
        }

        setIsUpdating(false);
      } catch (error) {
        setIsUpdating(false);
        console.log('ERROR', error);
      }
    } else {
      switchNetwork?.(ACTIVE_NETWORK);
    }
  };
  const [marketingWalletErr, setmarketingWalletErr] = useState(true);
  const [rewardTokenserr, setrewardTokenserr] = useState(true);
  useEffect(() => {
    if (formData.marketingWallet !== '' && formData.marketingWallet.length >= 20 && account != '') {
      const web3 = (window.web3 = new Web3(provider));
      const isAddress = web3.utils.isAddress(formData.marketingWallet);
      console.log(isAddress);
      setmarketingWalletErr(isAddress);
    }
  }, [formData.marketingWallet, account]);

  useEffect(() => {
    if (formData.rewardTokens !== '' && formData.rewardTokens?.length >= 20 && account != '') {
      const web3 = (window.web3 = new Web3(provider));
      const isContract = web3.utils.isAddress(formData.rewardTokens);
      console.log('isContract____+++++', isContract);
      RewardTokenCheck();
      console.log(isContract);

      setrewardTokenserr(isContract);
    }
  }, [account]);

  const RewardTokenCheck = async () => {
    const contractObjForPlaced = getContract(ammFactory, UniswapFactoryABI, library, account);
    const getPair = await contractObjForPlaced.getPair(formData.rewardTokens, wbnbAddress);
    console.log(getPair);
    if (getPair != deadAddress) {
      setNotListed(true);
    } else {
      setNotListed(false);
    }
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.gridWrapper}>
        <InputTextWithHeading
          heading="Name"
          placeholder="enter a name"
          name="name"
          value={formData.name}
          errors={errors?.name?.message}
          register={{
            ...register('name', {
              validate: nameValidatorCreator,
              onChange: (e) => {
                setValue('name', e.target.value);
                _onInputChange(e);
              },
            }),
          }}
        />
        <InputTextWithHeading
          heading="Symbol"
          placeholder="enter a Symbol"
          name="symbol"
          value={formData.symbol}
          errors={errors?.symbol?.message}
          register={{
            ...register('symbol', {
              validate: symbolValidatorCreator,
              onChange: (e) => {
                setValue('symbol', e.target.value);
                _onInputChange(e);
              },
            }),
          }}
        />
        <div className={classes.input}>
          <InputSubHeadingWrapper name="">
            <InputNumWithHeading
              heading="Decimals"
              name="decimals"
              value={formData.decimals}
              disabled
              setValue={(e) => _onInputChange1(e)}
            />
          </InputSubHeadingWrapper>
        </div>
        <div className={classes.input}>
          <InputHeadingWrapper name="Total supply">
            <InputWithControls
              value={formData.totalSupply}
              name="totalSupply"
              controlHandler={handleInputControlClick}
              minValue={0}
              step={1000}
              errors={errors?.totalSupply?.message}
              register={{
                ...register('totalSupply', {
                  validate: supplyValidatorCreator,
                  onChange: (e) => {
                    setValue('totalSupply', e.target.value);
                    _onInputChange1(e);
                  },
                }),
              }}
            />
          </InputHeadingWrapper>
        </div>
        <div className={classes.input}>
          <InputSubHeadingWrapper name={errors?.rewardTokens?.message ? '' : 'Reward token must be listed on QIDEX'}>
            <InputTextWithHeading
              heading="Reward token"
              placeholder="0x..."
              value={formData.rewardTokens}
              errors={errors?.rewardTokens?.message}
              register={{
                ...register('rewardTokens', {
                  validate: addressValidatorCreator,
                  onChange: (e) => {
                    setValue('rewardTokens', e.target.value);
                    _onInputChange(e);
                  },
                }),
              }}
            />
          </InputSubHeadingWrapper>
        </div>
        <div className={classes.input}>
          <InputHeadingWrapper name="Minimum token balance for dividends">
            <InputWithControls
              name="balanceForDividends"
              value={formData.balanceForDividends}
              controlHandler={handleInputControlClick}
              minValue={0}
              step={1}
              errors={errors?.balanceForDividends?.message}
              register={{
                ...register('balanceForDividends', {
                  validate: balanceValidatorCreator(formData.balanceForDividends, formData.totalSupply),
                  onChange: (e) => {
                    setValue('balanceForDividends', e.target.value);
                    _onInputChange(e);
                  },
                }),
              }}
            />
          </InputHeadingWrapper>
        </div>
        <div className={classes.input}>
          <InputHeadingWrapper name="Token reward fee (%)">
            <InputWithControls
              name="rewardFee"
              value={formData.rewardFee}
              controlHandler={handleInputControlClick}
              minValue={0}
              step={1}
              errors={errors?.rewardFee?.message}
              register={{
                ...register('rewardFee', {
                  validate: percentValidatorCreator,
                  onChange: (e) => {
                    setValue('rewardFee', e.target.value);
                    _onInputChange1(e);
                  },
                }),
              }}
            />
          </InputHeadingWrapper>
        </div>
        <div className={classes.input}>
          <InputHeadingWrapper name="Auto add liquidity (%)">
            <InputWithControls
              name="autoAddLiquidity"
              value={formData.autoAddLiquidity}
              controlHandler={handleInputControlClick}
              minValue={0}
              step={1}
              errors={errors?.autoAddLiquidity?.message}
              register={{
                ...register('autoAddLiquidity', {
                  validate: percentValidatorCreator,
                  onChange: (e) => {
                    setValue('autoAddLiquidity', e.target.value);
                    _onInputChange1(e);
                  },
                }),
              }}
            />
          </InputHeadingWrapper>
        </div>
        <div className={classes.input}>
          <InputHeadingWrapper name="Marketing fee (%)">
            <InputWithControls
              name="marketingFee"
              value={formData.marketingFee}
              controlHandler={handleInputControlClick}
              minValue={0}
              step={1}
              errors={errors?.marketingFee?.message}
              register={{
                ...register('marketingFee', {
                  validate: percentValidatorCreator,
                  onChange: (e) => {
                    setValue('marketingFee', e.target.value);
                    _onInputChange1(e);
                  },
                }),
              }}
            />
          </InputHeadingWrapper>
        </div>
        <div className={classes.input}>
          <InputTextWithHeading
            heading="Marketing wallet"
            placeholder="0x..."
            name="marketingWallet"
            value={formData.marketingWallet}
            errors={errors?.marketingWallet?.message}
            register={{
              ...register('marketingWallet', {
                validate: addressValidatorCreator,
                onChange: (e) => {
                  setValue('marketingWallet', e.target.value);
                  _onInputChange(e);
                },
              }),
            }}
          />
        </div>
      </div>
      <div className={classes.checkoutBlock}>
        <div className={classes.totalWrapper}>
          <TotalNum label="Token creation cost" value={tockenCreatorFee} measure="QIE" />
        </div>
        <div className={classes.btnWrapper}>
          <BtnGradient
            text={isUpdating ? 'Creating...' : 'Create Token'}
            Icon={CoinIcon}
            disabled={
              formData.marketingWallet == deadAddressDead ||
              formData.marketingWallet == deadAddress ||
              isUpdating ||
              !marketingWalletErr ||
              !rewardTokenserr ||
              !notListed ||
              Number(formData.rewardFee) + Number(formData.autoAddLiquidity) + Number(formData.marketingFee) > 26 ||
              Number(formData.totalSupply) / 1000 + 1 <= Number(formData.balanceForDividends) ||
              Number(formData.totalSupply) >= 1000000000000000000000 ||
              formData.symbol.length > 32 ||
              formData.name.length > 32
            }
            func={submitHandler}
          />
        </div>
      </div>
      <ModalWrapper state={modalState} onClose={() => closeModal()} heading={`Token ${formData?.name}`}>
        <div className={classes.modalWrapper}>
          <div>
            <h3 className={classes.subheading}>
              {formData?.types} {formData?.TokenType}
            </h3>
          </div>
          <div className={classes.valueGrid}>
            <div className={classes.valueWrapper}>
              <h4 className={classes.valueHeading}>Name</h4>
              <p className={classes.value}>{formData?.name}</p>
            </div>
            <div className={classes.valueWrapper}>
              <h4 className={classes.valueHeading}>Symbol</h4>
              <p className={classes.value}>{formData?.symbol}</p>
            </div>
            <div className={classes.valueWrapper}>
              <h4 className={classes.valueHeading}>Decimals</h4>
              <p className={classes.value}>{formData?.decimals}</p>
            </div>
            <div className={classes.valueWrapper}>
              <h4 className={classes.valueHeading}>Total Supply</h4>
              <p className={classes.value}>{convertToInternationalCurrencySystem(formData?.totalSupply)}</p>
            </div>
            <div className={classes.valueWrapper}>
              <h4 className={classes.valueHeading}>Reward Token</h4>
              <p className={classes.value}>{formData.rewardTokens}</p>
            </div>
            <div className={classes.valueWrapper}>
              <h4 className={classes.valueHeading}>Token reward fee</h4>
              <p className={classes.value}>{formData.rewardFee} %</p>
            </div>
            <div className={classes.valueWrapper}>
              <h4 className={classes.valueHeading}>Auto add liquidity</h4>
              <p className={classes.value}>{formData.autoAddLiquidity} %</p>
            </div>
            <div className={classes.valueWrapper}>
              <h4 className={classes.valueHeading}>Marketing fee</h4>
              <p className={classes.value}>{formData.marketingFee} %</p>
            </div>
          </div>
          <div className={classes.valueContractWrapper}>
            <h4 className={classes.valueHeading}>Minimum token balance for dividends</h4>
            <p className={classes.value}>{formData.balanceForDividends}</p>
          </div>
          <div className={classes.valueContractWrapper}>
            <h4 className={classes.valueHeading}>Marketing wallet address</h4>
            <p className={classes.valueContract}>{formData.marketingWallet}</p>
          </div>
          <div className={classes.valueContractWrapper}>
            <h4 className={classes.valueHeading}>Contract Address</h4>
            <p className={classes.valueContract}>{contractAddress}</p>
          </div>
        </div>
      </ModalWrapper>
    </div>
  );
};

export default BabyContent;
