import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Currency, Token } from '@pancakeswap-libs/sdk';
import InputNum from 'components/ui/input/inputNum';
import ModalWrapper from 'components/modalWrapper';
import { ReactComponent as ArrowIcon } from 'assets/images/arrow-down.svg';
import { ReactComponent as BTCIcon } from 'assets/images/btc-square-icon.svg';
import { ReactComponent as QIicon } from 'assets/images/logo-input.svg';
import Modal from './modal';
import { useDerivedSwapInfo, useSwapActionHandlers } from '../../../../../state/swap/hooks';
import { useAllTokens, useToken } from '../../../../../hooks/Tokens';
// import useTokenComparator from "../../../../../components/SearchModal/sorting";
import { isAddress } from '../../../../../utils';
import classes from './styles.module.scss';
import { Field } from '../../../../../state/swap/actions';
import CurrencyLogo from '../../../../../components/CurrencyLogo';

type StateType = {
  exchange: {
    isCurrencyChosen: boolean;
  };
};

type Props = {
  inputRole?: string;
  onCurrencySelect?: (currency: Currency) => void;
  children?: any;
  value?: any;
  setInputValue?: any;
  selectValue?: any;
  error?: any;
};

const InputCurrency = ({ inputRole, onCurrencySelect, value, children, setInputValue, selectValue, error }: Props) => {
  const [isModalActive, setIsModalActive] = useState(false);

  return (
    <>
      <div className={classes.wrapper}>
        <InputNum value={value} setValue={setInputValue} error={error}>
          <button
            type="button"
            className={classes.button}
            onClick={() => {
              setIsModalActive(!isModalActive);
            }}
          >
            {selectValue?.name ? (
              selectValue.name === 'QIE' ? (
                <>
                  <QIicon />
                  <span className={classes.text}>{selectValue.name}</span>
                </>
              ) : (
                <>
                  <CurrencyLogo currency={selectValue} size="24px" />
                  <span className={classes.text}>{selectValue.symbol}</span>
                </>
              )
            ) : selectValue?.tokenAmounts[0]?.currency?.symbol === 'WQIE' ? (
              <span
                className={classes.text}
              >{`${selectValue.tokenAmounts[0].currency.symbol}/${selectValue.tokenAmounts[1].currency.symbol}`}</span>
            ) : (
              <>
                <div className={classes.label}>Select a currency</div>
                <div className={classes.icon}>
                  <ArrowIcon />
                </div>
              </>
            )}
          </button>
        </InputNum>
        {children}
      </div>
      <ModalWrapper state={isModalActive} onClose={() => setIsModalActive(false)} heading="Select a token">
        <Modal setModalState={setIsModalActive} onCurrencySelect={onCurrencySelect} />
      </ModalWrapper>
    </>
  );
};

export default InputCurrency;
