import React from 'react';
import classes from './styles.module.scss';

const Content = () => {
  return (
    <div className={classes.wrapper}>
      <div className={classes.contentWrapper}>
        <h3 className={classes.heading}>What information do we collect?</h3>
        <br />
        <p className={classes.text}>
          We collect information from you when you register on our site and gather data when you participate in the
          forum by reading, writing, and evaluating the content shared here.
        </p>
        <p className={classes.text}>
          When registering on our site, you may be asked to enter your name and e-mail address.
        </p>
        <p className={classes.text}>
          You may, however, visit our site without registering. Your e-mail address will be verified by an email
          containing a unique link. If that link is visited, we know that you control the e-mail address. When
          registered and posting, we record the IP address that the post originated from. We also may retain server logs
          which include the IP address of every request to our server.
        </p>
        <br />
        <h3 className={classes.heading}>How do we protect your information?</h3>
        <br />
        <p className={classes.text}>
          We implement a variety of security measures to maintain the safety of your personal information when you
          enter, submit, or access your personal information.
        </p>
        <br />
        <h3 className={classes.heading}>What do we use your information for?</h3>
        <br />
        <p className={classes.text}>
          Any of the information we collect from you may be used in one of the following ways:
        </p>
        <p className={classes.text}>
          - to personalize your experience — your information helps us to better respond to your individual needs.
        </p>
        <p className={classes.text}>
          - to send periodic emails — The email address you provide may be used to send you information, notifications
          that you request about changes to topics or in response to your user name, respond to inquiries, and/or other
          requests or questions.
        </p>
        <br />
        <h3 className={classes.heading}>What is your data retention policy?</h3>
        <br />
        <p className={classes.text}>We will make a good faith effort to:</p>
        <p className={classes.text}>
          - retain server logs containing the IP address of all requests to this server no more than 90 days.
        </p>
        <p className={classes.text}>
          - retain the IP addresses associated with registered users and their posts no more than 5 years.
        </p>
        <br />
        <h3 className={classes.heading}>Do we use cookies?</h3>
        <br />
        <p className={classes.text}>
          Yes. Cookies are small files that a site or its service provider transfers to your computer’s hard drive
          through your Web browser (if you allow). These cookies enable the site to recognize your browser and, if you
          have a registered account, associate it with your registered account.
        </p>
        <br />
        <h3 className={classes.heading}>Do we disclose any information to outside parties?</h3>
        <br />
        <p className={classes.text}>
          We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information. This
          does not include trusted third parties who assist us in operating our site, conducting our business, or
          servicing you, so long as those parties agree to keep this information confidential. We may also release your
          information when we believe release is appropriate to comply with the law, enforce our site policies, or
          protect ours or others rights, property, or safety. However, non-personally identifiable visitor information
          may be provided to other parties for marketing, advertising, or other uses.
        </p>
        <br />
        <h3 className={classes.heading}>Third party links</h3>
        <br />
        <p className={classes.text}>
          Occasionally, at our discretion, we may include or offer third party products or services on our site. These
          third-party sites have separate and independent privacy policies. We therefore have no responsibility or
          liability for the content and activities of these linked sites. Nonetheless, we seek to protect the integrity
          of our site and welcome any feedback about these sites.
        </p>
        <br />
        <h3 className={classes.heading}>Children’s Online Privacy Protection Act Compliance</h3>
        <br />
        <p className={classes.text}>
          Our site, products and services are all directed to people who are at least 13 years old or older. If this
          server is in the USA, and you are under the age of 13, per the requirements of COPPA (Children’s Online
          Privacy Protection Act), do not use this site.
        </p>
        <br />
        <h3 className={classes.heading}>Online Privacy Policy Only</h3>
        <br />
        <p className={classes.text}>
          This online privacy policy applies only to information collected through our site and not to information
          collected offline.
        </p>
        <br />
        <h3 className={classes.heading}>Your Consent</h3>
        <br />
        <p className={classes.text}>By using our site, you consent to our web site privacy policy.</p>
        <br />
        <h3 className={classes.heading}>Changes to our Privacy Policy</h3>
        <br />
        <p className={classes.text}>
          If we decide to change our privacy policy, we will post those changes on this page. This document was last
          updated August 20th, 2022.
        </p>
      </div>
    </div>
  );
};

export default Content;
