import React, { useEffect, useState } from 'react';
import ChartHeader from './chartHeader';
import ChartItem from './chartItem';
import TokenSwapInfo from './tokenSwapInfo';
import swapInfoApi from '../../../../../../services/endpoints';
import classes from './styles.module.scss';

const Chart = ({
  setPriceInverted,
  priceInverted,
  priceValue,
  trade,
  pairAddress,
  inputValue,
  outputValue,
  selectInputValue,
  tokenInverted,
  inputTokenInfo,
  outputTokenInfo,
}) => {
  const [chartDataHeader, setChartDataHeader] = useState([]);
  const [ticks, setTicks] = useState([]);

  return (
    <div className={classes.chartBlock}>
      <ChartHeader
        inputValue={inputValue}
        outputValue={outputValue}
        data={chartDataHeader}
        setPriceInverted={setPriceInverted}
        priceInverted={priceInverted}
        priceValue={priceValue}
        ticks={ticks}
        tradeValue={trade}
        pairAddress={pairAddress}
        tokenInverted={tokenInverted}
      />
      <ChartItem
        tickData={ticks}
        setTickData={setTicks}
        setPriceInverted={setPriceInverted}
        priceInverted={priceInverted}
        setChartDataHeader={setChartDataHeader}
        pairAddress={pairAddress}
        selectInputValue={selectInputValue}
        tokenInverted={tokenInverted}
      />
      <TokenSwapInfo inputTokenInfo={inputTokenInfo} outputTokenInfo={outputTokenInfo} />
    </div>
  );
};

export default Chart;
