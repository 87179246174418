import React from 'react';
import BlockWrapper from '../blockWrapper';
import InputStake from './inputStake';
import classes from './styles.module.scss';

const StakeBlock = ({
  stakeAvailable,
  unstakeAvailable,
  stakeValue,
  setStakeValue,
  unstakeValue,
  setUnstakeValue,
  stakeMaxFunc,
  unstakeMaxFunc,
  disabledStake,
  disabledUnstake,
  stakeSubmit,
  unstakeSubmit,
  stakeError,
  unstakeError,
}) => {
  return (
    <BlockWrapper heading="Stake QIE">
      <div className={classes.wrapper}>
        <InputStake
          heading="Stake"
          valueAvailable={stakeAvailable}
          value={stakeValue}
          setValue={setStakeValue}
          maxFunc={stakeMaxFunc}
          disabled={disabledStake}
          btnFunc={stakeSubmit}
          stakeError={stakeError}
        />
        <InputStake
          heading="Unstake"
          valueAvailable={unstakeAvailable}
          value={unstakeValue}
          setValue={setUnstakeValue}
          maxFunc={unstakeMaxFunc}
          disabled={disabledUnstake}
          btnFunc={unstakeSubmit}
          stakeError={unstakeError}
        />
      </div>
    </BlockWrapper>
  );
};

export default StakeBlock;
