import React from 'react';
import config from './config';
import classes from './styles.module.scss';

const CardList = ({ daily, dateMeasure, monthly, yearly }) => {
  return (
    <div>
      <div className={classes.headingWrapper}>
        <h6 className={classes.heading}>Profit (+Staked Amount)</h6>
      </div>
      <div className={classes.wrapper}>
        <div className={classes.cardWrapper}>
          <h6 className={classes.heading}>Daily</h6>
          <div className={classes.textWrapper}>
            <p className={classes.textWhite}>{daily}</p>
            <p className={classes.measure}>{dateMeasure}</p>
          </div>
        </div>
        <div className={classes.cardWrapper}>
          <h6 className={classes.heading}>Monthly</h6>
          <div className={classes.textWrapper}>
            <p className={classes.textWhite}>{monthly}</p>
            <p className={classes.measure}>{dateMeasure}</p>
          </div>
        </div>
        <div className={classes.cardWrapper}>
          <h6 className={classes.heading}>Yearly</h6>
          <div className={classes.textWrapper}>
            <p className={classes.textWhite}>{yearly}</p>
            <p className={classes.measure}>{dateMeasure}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardList;
