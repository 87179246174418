import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { ReactComponent as SwitchIcon } from 'assets/images/arrow-switch.svg';
import InputCurrency from './inputCurrency';
// @ts-ignore
import classes from './styles.module.scss';
import BalanceWithButton from '../balanceWithButton';
import { useCurrencyBalance } from '../../../../state/wallet/hooks';
import { Field } from '../../../../state/mint/actions';

type Props = {
  inputValue?: any;
  outputValue?: any;
  inputHandler?: any;
  outputHandler?: any;
  inputSelectHandler?: any;
  outputSelectHandler?: any;
  children?: any;
  selectInputValue?: any;
  selectOutputValue?: any;
  switchToken?: any;
  balanceInput?: any;
  balanceOutput?: any;
  handleMaxInput?: any;
  handleMaxOutput?: any;
  errorInput?: any;
  errorOutput?: any;
};

const InputBlock = ({
  inputValue,
  outputValue,
  inputHandler,
  outputHandler,
  inputSelectHandler,
  outputSelectHandler,
  selectInputValue,
  selectOutputValue,
  children,
  switchToken,
  balanceInput,
  balanceOutput,
  handleMaxInput,
  handleMaxOutput,
  errorInput,
  errorOutput,
}: Props) => {
  const [isAsc, setIsAsc] = useState(true);
  const inputWrapper = () =>
    classnames({
      [classes.inputWrapper]: true,
      [classes.inputWrapperReverse]: !isAsc,
    });

  return (
    <div className={inputWrapper()}>
      <InputCurrency
        inputRole="INPUT"
        onCurrencySelect={inputSelectHandler}
        value={inputValue}
        setInputValue={inputHandler}
        selectValue={selectInputValue}
        error={errorInput}
      >
        {errorInput ? (
          <p className={classes.error}>{errorInput}</p>
        ) : (
          <BalanceWithButton balance={balanceInput} onMax={handleMaxInput} />
        )}
      </InputCurrency>
      <button type="button" className={classes.button} onClick={switchToken}>
        <SwitchIcon />
      </button>
      <InputCurrency
        inputRole="OUTPUT"
        onCurrencySelect={outputSelectHandler}
        value={outputValue}
        setInputValue={outputHandler}
        selectValue={selectOutputValue}
        error={errorOutput}
      >
        {errorOutput ? (
          <p className={classes.error}>{errorOutput}</p>
        ) : (
          <BalanceWithButton balance={balanceOutput} onMax={handleMaxOutput} />
        )}
      </InputCurrency>
    </div>
  );
};

export default InputBlock;
