import React from 'react';
import { ReactComponent as SwitchIcon } from 'assets/images/exchange-icon.svg';
import classes from './styles.module.scss';

const CoinSwitcher = ({ setPriceInverted }) => {
  return (
    <div className={classes.wrapper}>
      <button
        className={classes.switchIcon}
        type="button"
        onClick={() => {
          setPriceInverted();
        }}
      >
        <SwitchIcon />
      </button>
    </div>
  );
};

export default CoinSwitcher;
