import React from 'react';
import classes from './styles.module.scss';
import classnames from 'classnames';

type Props = {
  heading: string;
  value?: any;
  children?: any;
  setValue?: any;
  placeholder: string;
  name?: string;
  register?: any;
  errors?: any;
  isReadOnly?: boolean;
};

const InputTextWithHeading = ({ heading, placeholder, value, setValue, name, register, errors, isReadOnly }: Props) => {
  const inputClass = () =>
    classnames({
      [classes.input]: true,
      [classes.inputError]: errors,
    });

  return (
    <div className={classes.wrapper}>
      <h6 className={classes.heading}>{heading}</h6>
      <div className={classes.inputWrapper}>
        <input
          className={inputClass()}
          type="text"
          name={name}
          placeholder={placeholder}
          value={value}
          readOnly={isReadOnly}
          onChange={setValue}
          {...register}
        />
        {errors && <p className={classes.error}>{errors}</p>}
      </div>
    </div>
  );
};

export default InputTextWithHeading;
