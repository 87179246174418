import React from 'react';
import classes from './styles.module.scss';

const SpecItem = ({ Icon, text }) => {
  return (
    <div className={classes.wrapper}>
      <div className={classes.icon}>{!!Icon && <Icon />}</div>
      <h6 className={classes.text}>{text}</h6>
    </div>
  );
};

export default SpecItem;
