import React from 'react';
import { Link } from 'react-router-dom';
import ROUTES from 'constants/routes';
import { ReactComponent as ArrowIcon } from 'assets/images/arrow-left-icon.svg';
import classes from './styles.module.scss';

const Heading = () => {
  return (
    <div className={classes.wrapper}>
      <Link to={ROUTES.TRADE} className={classes.button}>
        <ArrowIcon />
      </Link>
      <h2 className={classes.heading}>Remove Liquidity</h2>
    </div>
  );
};

export default Heading;
