import React from 'react';
import InputNum from 'components/ui/input/inputNum';
import { ReactComponent as QIicon } from 'assets/images/logo-input.svg';
import classes from './styles.module.scss';

const InputQI = ({ value, setValue, children, error }) => {
  return (
    <div className={classes.container}>
      <InputNum value={value} setValue={setValue} error={error}>
        <div className={classes.wrapper}>
          <div className={classes.icon}>
            <QIicon />
          </div>
          <div className={classes.text}>QIE</div>
        </div>
      </InputNum>
      {children}
    </div>
  );
};

export default InputQI;
