import React from 'react';
// eslint-disable-next-line import/no-named-as-default
import config from 'routes/config';
import NavItem from './navItem';
import classes from './styles.module.scss';

const NavBlock = ({ isFull }) => {
  return (
    <div className={classes.wrapper}>
      {config.map((navItem) => (
        <NavItem
          key={navItem.id}
          label={navItem.label}
          Icon={navItem.icon}
          link={navItem.href}
          isFull={isFull}
          extlink={navItem?.extlink}
        />
      ))}
    </div>
  );
};

export default NavBlock;
