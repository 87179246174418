// @ts-nocheck

import { ContractFactory } from '@ethersproject/contracts';
import { ReactComponent as CoinIcon } from 'assets/images/coin-horizontal-icon.svg';
import BtnGradient from 'components/btnGradient';
import InputHeadingWrapper from 'components/inputHeadingWrapper';
import InputSubHeadingWrapper from 'components/inputSubHeadingWrapper';
import InputWithControls from 'components/inputWithControls';
import ModalWrapper from 'components/modalWrapper';
import InputNumWithHeading from 'components/ui/input/inputNumWithHeading';
import BuyBackBabyTokenABI from 'constants/abis/BuyBackBabyToken/BuyBackBabyTokenABI.json';
import BuyBackBabyTokenData from 'constants/abis/BuyBackBabyToken/BuyBackBabyTokenData';
import UniswapFactoryABI from 'constants/abis/UniswapFactoryABI.json';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getContract } from 'utils';
import convertToInternationalCurrencySystem from 'utils/convertToInternationalCurrencySystem';
import { useSwitchNetwork } from 'wagmi';
import Web3 from 'web3';
import {
  ACTIVE_NETWORK,
  AdminOwner,
  ammFactory,
  deadAddress,
  pancakeRouter,
  wbnbAddress,
} from '../../../../../../constants';
import { useActiveWeb3React } from '../../../../../../hooks';
import { useProvider } from '../../../../../../hooks/v2/ethers-adapters';
import {
  addressValidatorCreator,
  nameValidatorCreator,
  percentValidatorCreator,
  supplyValidatorCreator,
  symbolValidatorCreator,
} from '../../../../../../utils/validator';
import InputTextWithHeading from '../../inputTextWithHeading';
import TotalNum from '../../totalNum';
import classes from './styles.module.scss';

const BaybackContent = () => {
  const tockenCreatorFees = 0.000001;
  const history = useHistory();
  const { account, chainId, library } = useActiveWeb3React();
  const { switchNetwork } = useSwitchNetwork();
  const [isSubmit, setIsSubmit] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [rewardTokenserr, setrewardTokenserr] = useState(true);
  const [notListed, setNotListed] = useState(true);
  const [formData, setFormData] = useState({
    TokenType: 'Reflection',
    types: 'BuyBack Baby Token',
    name: '',
    symbol: '',
    decimals: '9',
    totalSupply: '',
    liquidityFee: '',
    buyBackFee: '',
    marketingFee: '',
    reflectionFee: '',
    rewardTokens: '',
    investmentToken: 'babyToken',
    feeDenominator: 100,
  });
  const [modalState, setModalState] = useState(false);
  const [contractAddress, setContractAddress] = useState('');
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    trigger,
  } = useForm({
    mode: 'onChange',
  });
  const provider = useProvider();

  const _onInputChange = (e) => {
    const temp = { ...formData, [e.target.name]: e.target.value };
    setFormData(temp);
  };

  console.log(Number(formData.totalSupply) * 1000000000);

  const _onInputChange1 = (e) => {
    const cursor = e.target?.selectionStart;
    const parsedValue = e.target.value.replace(/,/g, '');

    if (/^\d*\.?\d*$/.test(parsedValue)) {
      const temp = { ...formData, [e.target.name]: parsedValue };
      setFormData(temp);
    }

    e.target.selectionStart = cursor;
  };

  const handleInputControlClick = (action, dataName, step) => {
    const temp = {
      ...formData,
      [dataName]: action === 'increase' ? Number(formData[dataName]) + step : Number(formData[dataName]) - step,
    };
    setValue(dataName, action === 'increase' ? Number(formData[dataName]) + step : Number(formData[dataName]) - step);
    trigger(dataName);
    setFormData(temp);
  };

  const closeModal = () => {
    setFormData({
      TokenType: 'Reflection',
      types: 'BuyBack Baby Token',
      name: '',
      symbol: '',
      decimals: '9',
      totalSupply: '',
      liquidityFee: '',
      buyBackFee: '',
      marketingFee: '',
      reflectionFee: '',
      rewardTokens: '',
      investmentToken: 'babyToken',
      feeDenominator: 100,
    });
    setModalState(false);
  };

  const uploadContractHandlerBabyToken = async (
    accountValue,
    formDataValue,
    setIsLoaderValue,
    tockenCreatorFeeValue,
    cbValue
  ) => {
    try {
      const web3 = (window.web3 = new Web3(provider));
      const nfttokenContract = new ContractFactory(
        BuyBackBabyTokenABI,
        BuyBackBabyTokenData,
        provider.getSigner(account)
      );
      const tokenCreaterFeesS = web3.utils.toWei(tockenCreatorFeeValue.toString());

      console.log(tokenCreaterFeesS);
      console.log(web3.utils.toWei(formDataValue.totalSupply.toString()));
      console.log(formDataValue.totalSupply.toString());
      const liquidityFees = formDataValue.liquidityFee * 100;
      const buyBackFees = formDataValue.buyBackFee * 100;
      const reflectionFees = formDataValue.reflectionFee * 100;
      const marketingFees = formDataValue.marketingFee * 100;
      const feeDenominators = formDataValue.feeDenominator * 100;
      const TotalSupply = Number(formDataValue.totalSupply) * 1000000000;
      console.log(liquidityFees);
      console.log(buyBackFees);
      console.log(reflectionFees);
      console.log(marketingFees);
      console.log(TotalSupply);

      const contract = await nfttokenContract.deploy(
        formDataValue.name,
        formDataValue.symbol,
        TotalSupply.toString(),
        formDataValue.rewardTokens,
        pancakeRouter,
        [liquidityFees, buyBackFees, reflectionFees, marketingFees, feeDenominators],
        AdminOwner,
        tokenCreaterFeesS,
        {
          gasLimit: web3.utils.toHex('6540692'),
          value: web3.utils.toHex(tokenCreaterFeesS),
        }
      );

      await contract.deployTransaction.wait();

      if (contract.address) {
        cbValue({
          status: true,
          address: contract.address,
        });
      }
    } catch (error) {
      console.error(error);
      toast.error(error.message);
      setIsLoaderValue(false);
      cbValue({
        status: false,
        address: null,
      });
    }
  };

  const submitHandler = async () => {
    if (ACTIVE_NETWORK == chainId) {
      setIsSubmit(true);
      try {
        const web3 = (window.web3 = new Web3(provider));
        // const isAddress = web3.utils.isAddress(formData.marketingWallet);
        const isContract = web3.utils.isAddress(formData.rewardTokens);
        setrewardTokenserr(isContract);
        if (formData.investmentToken == 'babyToken') {
          if (
            formData.name !== '' &&
            formData.symbol !== '' &&
            formData.totalSupply !== '' &&
            Number(formData.totalSupply) > 0 &&
            formData.buyBackFee !== '' &&
            formData.reflectionFee !== '' &&
            Number(formData.buyBackFee) >= 0 &&
            Number(formData.reflectionFee) >= 0 &&
            Number(formData.marketingFee) > 0 &&
            formData.marketingFee !== ''
          ) {
            setIsUpdating(true);
            await uploadContractHandlerBabyToken(account, formData, setIsUpdating, tockenCreatorFees, (result) => {
              if (result.status && result.address) {
                console.log(`${explorerURL}/address/${result.address}`);
                // window.open(`${explorerURL}/address/${result.address}`);
                // setIsLoader(false);
                // history.push({
                //     pathname: "/app/view-token",
                //     search: result.address,
                //     state: formData,
                // });
                // toast.success("Token created successfully");
                setContractAddress(result.address);
                setModalState(true);
                toast.success('Token created successfully');
              } else {
              }
            });
          } else {
          }
        }

        setIsUpdating(false);
      } catch (error) {
        setIsUpdating(false);
        console.log('ERROR', error);
      }
    } else {
      switchNetwork?.(ACTIVE_NETWORK);
    }
  };

  useEffect(() => {
    if (formData.rewardTokens !== '' && formData.rewardTokens.length >= 20 && account != '') {
      const web3 = (window.web3 = new Web3(provider));
      const isContract = web3.utils.isAddress(formData.rewardTokens);
      RewardTokenCheck();
      console.log(isContract);
      setrewardTokenserr(isContract);
    }
  }, [account]);
  // }, [formData.rewardTokens, account]);

  const RewardTokenCheck = async () => {
    const contractObjForPlaced = getContract(ammFactory, UniswapFactoryABI, library, account);
    const getPair = await contractObjForPlaced.getPair(formData.rewardTokens, wbnbAddress);
    console.log(getPair);
    if (getPair != deadAddress) {
      setNotListed(true);
    } else {
      setNotListed(false);
    }
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.gridWrapper}>
        <InputTextWithHeading
          heading="Name"
          placeholder="enter a name"
          name="name"
          value={formData.name}
          errors={errors?.name?.message}
          register={{
            ...register('name', {
              validate: nameValidatorCreator,
              onChange: (e) => {
                setValue('name', e.target.value);
                _onInputChange(e);
              },
            }),
          }}
        />
        <InputTextWithHeading
          heading="Symbol"
          placeholder="enter a Symbol"
          name="symbol"
          value={formData.symbol}
          errors={errors?.symbol?.message}
          register={{
            ...register('symbol', {
              validate: symbolValidatorCreator,
              onChange: (e) => {
                setValue('symbol', e.target.value);
                _onInputChange(e);
              },
            }),
          }}
        />
        <div className={classes.input}>
          <InputSubHeadingWrapper name="">
            <InputNumWithHeading
              heading="Decimals"
              name="decimals"
              disabled
              value={formData.decimals}
              setValue={(e) => _onInputChange1(e)}
            />
          </InputSubHeadingWrapper>
        </div>
        <div className={classes.input}>
          <InputHeadingWrapper name="Total supply">
            <InputWithControls
              value={formData.totalSupply}
              name="totalSupply"
              controlHandler={handleInputControlClick}
              minValue={0}
              step={1000}
              errors={errors?.totalSupply?.message}
              register={{
                ...register('totalSupply', {
                  validate: supplyValidatorCreator,
                  onChange: (e) => {
                    setValue('totalSupply', e.target.value);
                    _onInputChange1(e);
                  },
                }),
              }}
            />
          </InputHeadingWrapper>
        </div>
        <div className={classes.input}>
          <InputSubHeadingWrapper name={errors?.rewardTokens?.message ? '' : 'Reward token must be listed on QIDEX'}>
            <InputTextWithHeading
              heading="Reward token"
              placeholder="0x.."
              name="rewardTokens"
              value={formData.rewardTokens}
              errors={errors?.rewardTokens?.message}
              register={{
                ...register('rewardTokens', {
                  validate: addressValidatorCreator,
                  onChange: (e) => {
                    setValue('rewardTokens', e.target.value);
                    _onInputChange(e);
                  },
                }),
              }}
            />
          </InputSubHeadingWrapper>
        </div>
        <div className={classes.input}>
          <InputHeadingWrapper name="Liquidity fee">
            <InputWithControls
              value={formData.liquidityFee}
              name="liquidityFee"
              controlHandler={handleInputControlClick}
              minValue={0}
              step={1}
              errors={errors?.liquidityFee?.message}
              register={{
                ...register('liquidityFee', {
                  validate: percentValidatorCreator,
                  onChange: (e) => {
                    setValue('liquidityFee', e.target.value);
                    _onInputChange1(e);
                  },
                }),
              }}
            />
          </InputHeadingWrapper>
        </div>
        <div className={classes.input}>
          <InputHeadingWrapper name="Buyback fee (%)">
            <InputWithControls
              value={formData.buyBackFee}
              name="buyBackFee"
              controlHandler={handleInputControlClick}
              minValue={0}
              step={1}
              errors={errors?.buyBackFee?.message}
              register={{
                ...register('buyBackFee', {
                  validate: percentValidatorCreator,
                  onChange: (e) => {
                    setValue('buyBackFee', e.target.value);
                    _onInputChange1(e);
                  },
                }),
              }}
            />
          </InputHeadingWrapper>
        </div>
        <div className={classes.input}>
          <InputHeadingWrapper name="Reflection fee (%)">
            <InputWithControls
              value={formData.reflectionFee}
              name="reflectionFee"
              controlHandler={handleInputControlClick}
              minValue={0}
              step={1}
              errors={errors?.reflectionFee?.message}
              register={{
                ...register('reflectionFee', {
                  validate: percentValidatorCreator,
                  onChange: (e) => {
                    setValue('reflectionFee', e.target.value);
                    _onInputChange1(e);
                  },
                }),
              }}
            />
          </InputHeadingWrapper>
        </div>
        <div className={classes.input}>
          <InputHeadingWrapper name="Marketing fee (%)">
            <InputWithControls
              value={formData.marketingFee}
              name="marketingFee"
              controlHandler={handleInputControlClick}
              minValue={0}
              step={1}
              errors={errors?.marketingFee?.message}
              register={{
                ...register('marketingFee', {
                  validate: percentValidatorCreator,
                  onChange: (e) => {
                    setValue('marketingFee', e.target.value);
                    _onInputChange1(e);
                  },
                }),
              }}
            />
          </InputHeadingWrapper>
        </div>
      </div>
      <div className={classes.checkoutBlock}>
        <div className={classes.totalWrapper}>
          <TotalNum label="Token creation cost" value={tockenCreatorFees} measure="QIE" />
        </div>
        <div className={classes.btnWrapper}>
          <BtnGradient
            text={isUpdating ? 'Creating...' : 'Create Token'}
            Icon={CoinIcon}
            disabled={
              formData.totalSupply.toString().length > 21 ||
              isUpdating ||
              !rewardTokenserr ||
              Number(formData.buyBackFee) +
                Number(formData.reflectionFee) +
                Number(formData.liquidityFee) +
                Number(formData.marketingFee) >
                25 ||
              !notListed ||
              Number(formData.totalSupply) >= 10000000000000000000000 ||
              formData.symbol.length > 32 ||
              formData.name.length > 32
            }
            func={submitHandler}
          />
        </div>
      </div>
      <ModalWrapper state={modalState} onClose={() => closeModal()} heading={`Token ${formData?.name}`}>
        <div className={classes.modalWrapper}>
          <div>
            <h3 className={classes.subheading}>
              {formData?.types} {formData?.TokenType}
            </h3>
          </div>
          <div className={classes.valueGrid}>
            <div className={classes.valueWrapper}>
              <h4 className={classes.valueHeading}>Name</h4>
              <p className={classes.value}>{formData?.name}</p>
            </div>
            <div className={classes.valueWrapper}>
              <h4 className={classes.valueHeading}>Symbol</h4>
              <p className={classes.value}>{formData?.symbol}</p>
            </div>
            <div className={classes.valueWrapper}>
              <h4 className={classes.valueHeading}>Decimals</h4>
              <p className={classes.value}>{formData?.decimals}</p>
            </div>
            <div className={classes.valueWrapper}>
              <h4 className={classes.valueHeading}>Total Supply</h4>
              <p className={classes.value}>{convertToInternationalCurrencySystem(formData?.totalSupply)}</p>
            </div>
            <div className={classes.valueWrapper}>
              <h4 className={classes.valueHeading}>Liquidity fee</h4>
              <p className={classes.value}>{formData.liquidityFee} %</p>
            </div>
            <div className={classes.valueWrapper}>
              <h4 className={classes.valueHeading}>Buyback fee</h4>
              <p className={classes.value}>{formData.buyBackFee} %</p>
            </div>
            <div className={classes.valueWrapper}>
              <h4 className={classes.valueHeading}>Reflection fee</h4>
              <p className={classes.value}>{formData.reflectionFee} %</p>
            </div>
            <div className={classes.valueWrapper}>
              <h4 className={classes.valueHeading}>Marketing fee</h4>
              <p className={classes.value}>{formData.marketingFee} %</p>
            </div>
          </div>
          <div className={classes.valueContractWrapper}>
            <h4 className={classes.valueHeading}>Reward Token</h4>
            <p className={classes.valueContract}>{formData.rewardTokens}</p>
          </div>
          <div className={classes.valueContractWrapper}>
            <h4 className={classes.valueHeading}>Contract Address</h4>
            <p className={classes.valueContract}>{contractAddress}</p>
          </div>
        </div>
      </ModalWrapper>
    </div>
  );
};

export default BaybackContent;
