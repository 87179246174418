import React from 'react';
import classes from './styles.module.scss';

const InputRadio = ({ name, label, id, value, state, setState }) => {
  return (
    <label htmlFor={id} className={classes.wrapper}>
      <input
        id={id}
        type="radio"
        name={name}
        className={classes.input}
        value={value}
        checked={state === value}
        onChange={() => setState(value)}
      />
      <span className={classes.radio} />
      <span className={classes.label}>{label}</span>
    </label>
  );
};

export default InputRadio;
