import React, { useMemo } from 'react';
import { Pair } from '@pancakeswap-libs/sdk';
import ContentHeading from 'components/contentHeading';
import ButtonBorderGradient from 'components/ui/button/buttonBorderGradient';
import ErrorField from 'components/errorField';
import { toV2LiquidityToken, useTrackedTokenPairs } from 'state/user/hooks';
import { useTokenBalancesWithLoadingIndicator } from 'state/wallet/hooks';
import { usePairs } from 'data/Reserves';
import { useUserHasLiquidityInAllTokens } from 'data/V1';
import { ReactComponent as PlusIcon } from 'assets/images/plus-icon.svg';
import LiquidityStats from './liquidityStats';
import { useActiveWeb3React } from '../../../../../hooks';
import ContentCenterWrapper from '../../contentCenterWrapper';
import Subheading from '../subheading';
import classes from './styles.module.scss';

const ContentLoggedOut = ({ setIsActive }) => {
  const { account } = useActiveWeb3React();

  // fetch the user's balances of all tracked V2 LP tokens
  const trackedTokenPairs = useTrackedTokenPairs();
  const tokenPairsWithLiquidityTokens = useMemo(
    () => trackedTokenPairs.map((tokens) => ({ liquidityToken: toV2LiquidityToken(tokens), tokens })),
    [trackedTokenPairs]
  );
  const liquidityTokens = useMemo(() => tokenPairsWithLiquidityTokens.map((tpwlt) => tpwlt.liquidityToken), [
    tokenPairsWithLiquidityTokens,
  ]);
  const [v2PairsBalances, fetchingV2PairBalances] = useTokenBalancesWithLoadingIndicator(
    account ?? undefined,
    liquidityTokens
  );

  // fetch the reserves for all V2 pools in which the user has a balance
  const liquidityTokensWithBalances = useMemo(
    () =>
      tokenPairsWithLiquidityTokens.filter(({ liquidityToken }) =>
        v2PairsBalances[liquidityToken.address]?.greaterThan('0')
      ),
    [tokenPairsWithLiquidityTokens, v2PairsBalances]
  );

  const v2Pairs = usePairs(liquidityTokensWithBalances.map(({ tokens }) => tokens));
  const v2IsLoading =
    fetchingV2PairBalances ||
    v2Pairs?.length < liquidityTokensWithBalances.length ||
    v2Pairs?.some((V2Pair) => !V2Pair);

  const allV2PairsWithLiquidity = v2Pairs.map(([, pair]) => pair).filter((v2Pair): v2Pair is Pair => Boolean(v2Pair));

  const hasV1Liquidity = useUserHasLiquidityInAllTokens();

  return (
    <ContentCenterWrapper>
      <div className={classes.headingWrapper}>
        <ContentHeading text="Add liquidity to receive LP tokens" />
      </div>
      <div className={classes.wrapper}>
        <ButtonBorderGradient Icon={PlusIcon} text="Add Liquidity" setState={setIsActive} />
        <Subheading />
        {!account ? (
          <ErrorField text={'Connect to a wallet to\nview your liquidity'} />
        ) : allV2PairsWithLiquidity?.length > 0 ? (
          allV2PairsWithLiquidity.map((v2Pair) => (
            <LiquidityStats key={v2Pair.liquidityToken.address} pair={v2Pair} setState={setIsActive} />
          ))
        ) : (
          <ErrorField text="No liquidity found" />
        )}
        <p className={classes.text}>Or, if you staked your LP tokens in a farm, unstake them to see them here.</p>
      </div>
    </ContentCenterWrapper>
  );
};

export default ContentLoggedOut;
