import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import config from './config';
import TimeFrameOption from './timeFrameOption';
import { setIsActive } from '../../../../../../../../state/chartTimeSwitcher/slice';
import classes from './styles.module.scss';

const TimeFrameSwitcher = () => {
  const [optionActive, setOptionActive] = useState('1D');
  const dispatch = useDispatch();

  return (
    <div className={classes.wrapper}>
      {config.map((elem) => (
        <TimeFrameOption
          key={elem.id}
          id="timeframeSwitch"
          value={elem.dateOption}
          setValue={(value) => {
            dispatch(setIsActive(value));
            setOptionActive(value);
          }}
          label={elem.dateOption}
          checked={optionActive}
        />
      ))}
    </div>
  );
};

export default TimeFrameSwitcher;
