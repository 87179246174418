// @ts-nocheck

// eslint-disable-next-line consistent-return
const required = (value: string) => {
  const errorText = 'The field is required';

  if (!value) {
    return errorText;
  }
};

// eslint-disable-next-line consistent-return
const correctName = (value: string) => {
  const regexp = /^(?=.*?[A-Za-z])[A-Za-z+]+$/;
  const errorText = 'Please enter correct name';

  if (!value.match(regexp)) {
    return errorText;
  }
};

// eslint-disable-next-line consistent-return
const correctNameLength = (value: string) => {
  const errorText = 'Please enter correct name';

  if (value.length > 32) {
    return errorText;
  }
};

// eslint-disable-next-line consistent-return
const correctSymbol = (value: string) => {
  const regexp = /^(?=.*?[A-Z])[A-Z+]+$/;
  const errorText = 'Please enter correct symbol';

  if (!value.match(regexp)) {
    return errorText;
  }
};

// eslint-disable-next-line consistent-return
const correctSymbolLength = (value: string) => {
  const errorText = 'Please enter correct symbol';

  if (value.length > 32) {
    return errorText;
  }
};

// eslint-disable-next-line consistent-return
const correctSupply = (value: string) => {
  const regex = /^-?[0-9]+$/;
  const errorText = 'Please enter correct token quantity';

  if (value <= 0) {
    return errorText;
  }

  if (value >= 1000000000000000000000) {
    return errorText;
  }

  if (!regex.test(value)) {
    return errorText;
  }
};

// eslint-disable-next-line consistent-return
const correctDecimals = (value: string) => {
  const regex = /^-?[0-9]+$/;
  const errorText = 'Decimals must be less than or equal to 18';

  if (value <= 0) {
    return errorText;
  }

  if (value > 18) {
    return errorText;
  }

  if (!regex.test(value)) {
    return errorText;
  }
};

// eslint-disable-next-line consistent-return
const correctPercent = (value: string) => {
  const errorText = 'Percent fee must be less than or equal to 25';

  if (value <= 0) {
    return errorText;
  }

  if (value > 25) {
    return errorText;
  }
};

// eslint-disable-next-line consistent-return
const correctAddress = (value: string) => {
  const regexp = /^0x[a-fA-F0-9]{40}$/g;
  const errorText = 'Please enter correct address';

  if (!value.match(regexp)) {
    return errorText;
  }
};

// eslint-disable-next-line consistent-return
const correctBalance = (value: string, balance) => {
  const errorText = 'Please enter correct balance';

  if (balance === '') {
    return errorText;
  }

  if (value > balance) {
    return errorText;
  }
};

const nameValidatorCreator = {
  required: (value: string) => required(value),
  correctName: (value: string) => correctName(value),
  correctNameLength: (value: string) => correctNameLength(value),
};

const symbolValidatorCreator = {
  required: (value: string) => required(value),
  correctSymbol: (value: string) => correctSymbol(value),
  correctSymbolLength: (value: string) => correctSymbolLength(value),
};

const supplyValidatorCreator = {
  required: (value: string) => required(value),
  correctSupply: (value: string) => correctSupply(value),
};

const decimalsValidatorCreator = {
  required: (value: string) => required(value),
  correctDecimals: (value: string) => correctDecimals(value),
};

const percentValidatorCreator = {
  required: (value: string) => required(value),
  correctPercent: (value: string) => correctPercent(value),
};

const addressValidatorCreator = {
  required: (value: string) => required(value),
  correctAddress: (value: string) => correctAddress(value),
};

// eslint-disable-next-line consistent-return
const balanceValidatorCreator = (balance) => (value) => {
  const regex = /^-?[0-9]+$/;

  if (balance === '') {
    return 'Please enter correct balance';
  }

  if (value / 1000 > balance) {
    return 'Please enter correct balance';
  }

  if (!regex.test(value)) {
    return errorText;
  }
};

export {
  nameValidatorCreator,
  symbolValidatorCreator,
  supplyValidatorCreator,
  decimalsValidatorCreator,
  percentValidatorCreator,
  addressValidatorCreator,
  balanceValidatorCreator,
};
