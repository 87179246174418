import React from 'react';
import InputNumWithHeading from 'components/ui/input/inputNumWithHeading';
import BlockWrapper from '../blockWrapper';
import RangeSlider from './rangeSlider';
import CardList from './cardList';
import classes from './styles.module.scss';
import formatValue from '../../../../utils/formatNumber';

const CalculatorBlock = ({
  daily,
  dateMeasure,
  monthly,
  yearly,
  calculatorValue,
  setCalculatorValue,
  rangeSliderValue,
  setRangeSliderValue,
  errorCalculator,
}) => {
  return (
    <BlockWrapper heading="Staking Calculator">
      <div className={classes.inputWrapper}>
        <InputNumWithHeading
          heading="Your QIE Balance"
          value={calculatorValue}
          setValue={setCalculatorValue}
          error={errorCalculator}
        />
      </div>
      <RangeSlider rangeSliderValue={rangeSliderValue} setRangeSliderValue={setRangeSliderValue} />
      <CardList
        daily={formatValue(daily) ? formatValue(daily) : '0'}
        dateMeasure="QIDEX"
        monthly={formatValue(monthly) ? formatValue(monthly) : '0'}
        yearly={formatValue(yearly) ? formatValue(yearly) : '0'}
      />
    </BlockWrapper>
  );
};

export default CalculatorBlock;
