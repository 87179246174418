import React from 'react';
import classes from './styles.module.scss';

const InputOption = ({ value, setValue }) => {
  return (
    <button type="button" className={classes.button} onClick={() => setValue(value)}>
      {value}%
    </button>
  );
};

export default InputOption;
