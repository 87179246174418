import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setIsSettingsModalActive } from 'state/exchange/slice';
import { useUserDeadline, useUserSlippageTolerance } from 'state/user/hooks';
import InputWithBuiltInValues from './inputWithBuiltInValues';
import InputWithControls from '../../../inputWithControls';
import BtnGradient from '../../../btnGradient';
import ModalSlideWrapper from '../../../modalSlideWrapper';
import HeadingWithTooltip from '../../../headingWithTooltip';
import classes from './styles.module.scss';
import { Field } from '../../../../state/swap/actions';

type StateType = {
  exchange: {
    isSettingsModalActive: boolean;
  };
};

const MAX_SLIPPAGE = 5000;

const SettingsModal = () => {
  const [userSlippageTolerance, setUserslippageTolerance] = useUserSlippageTolerance();
  const [slippageValue, setSlippageValue] = useState(userSlippageTolerance / 100);
  const [slippageInputValue, setSlippageInputValue] = useState(slippageValue);
  const [deadline, setDeadline] = useUserDeadline();
  const [transactionValue, setTransactionValue] = useState(deadline / 60); // deadline in minutes
  const [transactionInputValue, setTransactionInputValue] = useState(transactionValue);
  const [errorSlippage, setErrorSlippage] = useState<string | null>(null);
  const [errorTransaction, setErrorTransaction] = useState<string | null>(null);

  const isActive = useSelector((state: StateType) => state.exchange.isSettingsModalActive);
  const dispatch = useDispatch();

  const handleSlippageChange = (e) => {
    const cursor = e.target?.selectionStart;
    const parsedValue = e.target.value.replace(/,/g, '');

    if (/^\d*\.?\d*$/.test(parsedValue)) {
      setSlippageInputValue(parsedValue);
    }

    if (parsedValue > 50) {
      setErrorSlippage('Enter a valid slippage percentage');
    } else {
      setErrorSlippage('');
    }

    e.target.selectionStart = cursor;
  };

  const handleTransactionChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { value: inputValue } = evt.target;

    if (/^\d*\.?\d*$/.test(inputValue)) {
      if (!Number.isNaN(inputValue)) {
        setTransactionInputValue(parseInt(inputValue, 10));

        if (parseInt(inputValue, 10) <= 0) {
          setErrorTransaction('Enter a valid deadline');
        } else {
          setErrorTransaction('');
        }
      }
    }
  };

  // Updates local storage if value is valid
  const applySlippageValue = () => {
    try {
      const rawValue = slippageInputValue * 100;
      if (!Number.isNaN(rawValue) && rawValue > 0 && rawValue < MAX_SLIPPAGE) {
        setUserslippageTolerance(rawValue);
        setErrorSlippage(null);
      } else {
        setErrorSlippage('Enter a valid slippage percentage');
      }
    } catch {
      setErrorSlippage('Enter a valid slippage percentage');
    }
  };

  // Updates local storage if value is valid
  const applyTransactionValue = () => {
    try {
      const rawValue = transactionInputValue * 60;
      if (!Number.isNaN(rawValue) && rawValue > 0) {
        setDeadline(rawValue);
        setErrorTransaction(null);
      } else {
        setErrorTransaction('Enter a valid deadline');
      }
    } catch {
      setErrorTransaction('Enter a valid deadline');
    }
  };

  const clearInput = () => {
    setSlippageInputValue(slippageValue);
    setTransactionInputValue(transactionValue);
  };

  const closeModal = () => {
    dispatch(setIsSettingsModalActive(!isActive));
  };

  const handleCloseModal = () => {
    closeModal();
    clearInput();
  };

  const applySettings = () => {
    applyTransactionValue();
    applySlippageValue();
    closeModal();
  };

  return (
    <ModalSlideWrapper heading="Settings" state={isActive} setState={handleCloseModal}>
      <div className={classes.contentWrapper}>
        <div className={classes.inputListWrapper}>
          <div className={classes.wrapper}>
            <HeadingWithTooltip
              heading="Slippage tolerance"
              tooltipText="Your transaction will revert if the price changes unfavorably by more than this percentage."
            />
            <InputWithBuiltInValues
              value={slippageInputValue}
              setValue={setSlippageInputValue}
              handler={handleSlippageChange}
              minValue={0.1}
              error={errorSlippage}
            />
          </div>
          <div className={classes.wrapper}>
            <HeadingWithTooltip
              heading="Transaction deadline, minute"
              tooltipText="Your transaction will revert if it is pending for more than this long."
            />
            <InputWithControls
              value={transactionInputValue}
              setValue={setTransactionInputValue}
              handler={handleTransactionChange}
              minValue={1}
              step={1}
              errors={errorTransaction}
            />
          </div>
        </div>
        <div className={classes.btnWrapper}>
          <BtnGradient text="Apply" func={applySettings} />
        </div>
      </div>
    </ModalSlideWrapper>
  );
};

export default SettingsModal;
