import React from 'react';
import PageNavItem from './pageNavItem';
import classes from './styles.module.scss';

const CardNavNew = ({ tabs, activeIndex, setState, route }) => {
  return (
    <div className={classes.navWrapper}>
      <PageNavItem text={tabs[0]} index={activeIndex} indexItem={0} setActiveTab={setState} route={route} />
      <PageNavItem text={tabs[1]} index={activeIndex} indexItem={1} setActiveTab={setState} route={route} />
    </div>
  );
};

export default CardNavNew;
