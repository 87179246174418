import React from 'react';
import { ReactComponent as SwitchIcon } from 'assets/images/exchange-icon.svg';
import classes from './styles.module.scss';

type Props = {
  heading: string;
  value: string;
  func?: () => void;
};

const CheckoutBlockLg = ({ heading, value, func }: Props) => {
  return (
    <div className={classes.wrapper}>
      <h6 className={classes.heading}>{heading}</h6>
      <div className={classes.valueWrapper}>
        <p className={classes.value}>{value}</p>
        {func && (
          <button type="button" className={classes.button} onClick={func}>
            <SwitchIcon />
          </button>
        )}
      </div>
    </div>
  );
};

export default CheckoutBlockLg;
